import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setActiveItem } from '../../store/actions/authActions';
import ReactTooltip from 'react-tooltip';
import Loader from '../common/Loader';

class JinglesList extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.checkActive, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkActive = () => {
    if (this._isMounted) {
      if (this.props.media.id) {
        let target = document.getElementById('mid-' + this.props.media.id);
        if (target) {
          target.classList.add('media-active');
        }
      }
    }
  };

  loadMedia = e => {
    if (this._isMounted) {
      // Remove active item if present
      if (this.props.media.id) {
        let target = document.getElementById('mid-' + this.props.media.id);

        if (target) {
          target.classList.remove('media-active');
        }
      }

      // Create obj containing media data
      let obj = {
        id: e.target.getAttribute('data-id'),
        title: e.target.getAttribute('data-title'),
        artist: e.target.getAttribute('data-artist'),
        duration: e.target.getAttribute('data-duration')
      };

      // Add active class to this media item
      //e.target.parentNode.parentNode.classList.add('media-active');

      // Set the new active media item state
      this.props.setActiveItem(obj);
    }
  };

  render() {
    if (this.props.data && this.props.search !== '') {
      let filtered = this.props.data.filter(result => {
        return (
          result.artist.match(new RegExp(`${this.props.search}`, 'gi')) ||
          result.title.match(new RegExp(`${this.props.search}`, 'gi'))
        );
      });

      let jinglesList = filtered.map(item => (
        <li key={item.id} className="playlist-item" id={`mid-${item.id}`}>
          <div className="track-play">
            <i
              className="fas fa-play"
              onClick={this.loadMedia.bind(this)}
              data-id={item.id}
              data-title={item.title}
              data-artist={item.artist}
              data-duration={item.duration}
            />
          </div>

          <div className="meta">
            <div className="meta-top">
              <span className="m-title">
                <Link to={`/media/${item.id}`}>{item.title}</Link>
              </span>
              <span className="artist">{item.artist}</span>
            </div>
            <div className="meta-bottom">
              <span className="duration">
                <i className="far fa-clock" /> {item.duration}
              </span>
              <span className="filename">
                <i className="far fa-save" /> {item.filename}
              </span>
              <span className="age">
                <i className="fas fa-upload" /> {item.time_ago}
              </span>
              {item.active ? (
                <span
                  className="activated"
                  data-tip="Activated and ready to be added to a playlist!"
                >
                  <i className="fas fa-check-circle" />
                </span>
              ) : (
                <span
                  className="deactivated"
                  data-tip="Deactivated! If you uploaded this track you can reactivate it by clicking the title and editing it."
                >
                  <i className="fas fa-check-circle" />
                </span>
              )}
              {item.in_playlist ? (
                <span className="activated" data-tip="This file is playlisted!">
                  <i className="fas fa-bars" />
                </span>
              ) : (
                <span
                  className="deactivated"
                  data-tip="File isn't in any playlists."
                >
                  <i className="fas fa-bars" />
                </span>
              )}
              <ReactTooltip />
            </div>
          </div>
        </li>
      ));
      return (
        <div className="many-list">
          <ul className="media-list">{jinglesList}</ul>
        </div>
      );
    }

    if (this.props.data && this.props.sort) {
      let jingles = this.props.data.slice(0);

      if (this.props.sort === 'ACTIVE_ASC') {
        jingles.sort((a, b) =>
          a.active.toString().localeCompare(b.active.toString())
        );
      }

      if (this.props.sort === 'ACTIVE_DESC') {
        jingles
          .sort((a, b) =>
            a.active.toString().localeCompare(b.active.toString())
          )
          .reverse();
      }

      if (this.props.sort === 'TITLE_ASC') {
        jingles.sort((a, b) => a.title.localeCompare(b.title));
      }

      if (this.props.sort === 'TITLE_DESC') {
        jingles.sort((a, b) => a.title.localeCompare(b.title)).reverse();
      }

      if (this.props.sort === 'ARTIST_ASC') {
        jingles.sort((a, b) => a.artist.localeCompare(b.artist));
      }

      if (this.props.sort === 'ARTIST_DESC') {
        jingles.sort((a, b) => a.artist.localeCompare(b.artist)).reverse();
      }

      if (this.props.sort === 'DATE_ASC') {
        jingles.sort((a, b) => a.date_added.localeCompare(b.date_added));
      }

      if (this.props.sort === 'DATE_DESC') {
        jingles
          .sort((a, b) => a.date_added.localeCompare(b.date_added))
          .reverse();
      }

      if (this.props.sort === 'MODIFIED_ASC') {
        jingles.sort((a, b) => a.date_modified.localeCompare(b.date_modified));
      }

      if (this.props.sort === 'MODIFIED_DESC') {
        jingles
          .sort((a, b) => a.date_modified.localeCompare(b.date_modified))
          .reverse();
      }

      if (this.props.sort === 'DURATION_SHORTEST') {
        jingles.sort((a, b) => a.duration.localeCompare(b.duration));
      }

      if (this.props.sort === 'DURATION_LONGEST') {
        jingles.sort((a, b) => a.duration.localeCompare(b.duration)).reverse();
      }

      let jinglesList = jingles.map(item => (
        <li key={item.id} className="playlist-item" id={`mid-${item.id}`}>
          <div className="track-play">
            <i
              className="fas fa-play"
              onClick={this.loadMedia.bind(this)}
              data-id={item.id}
              data-title={item.title}
              data-artist={item.artist}
              data-duration={item.duration}
            />
          </div>

          <div className="meta">
            <div className="meta-top">
              <span className="m-title">
                <Link to={`/media/${item.id}`}>{item.title}</Link>
              </span>
              <span className="artist">{item.artist}</span>
            </div>
            <div className="meta-bottom">
              <span className="duration">
                <i className="far fa-clock" /> {item.duration}
              </span>
              <span className="filename">
                <i className="far fa-save" /> {item.filename}
              </span>
              <span className="age">
                <i className="fas fa-upload" /> {item.time_ago}
              </span>
              {item.active ? (
                <span
                  className="activated"
                  data-tip="Activated and ready to be added to a playlist!"
                >
                  <i className="fas fa-check-circle" />
                </span>
              ) : (
                <span
                  className="deactivated"
                  data-tip="Deactivated! If you uploaded this track you can reactivate it by clicking the title and editing it."
                >
                  <i className="fas fa-check-circle" />
                </span>
              )}
              {item.in_playlist ? (
                <span className="activated" data-tip="This file is playlisted!">
                  <i className="fas fa-bars" />
                </span>
              ) : (
                <span
                  className="deactivated"
                  data-tip="File isn't in any playlists."
                >
                  <i className="fas fa-bars" />
                </span>
              )}
              <ReactTooltip />
            </div>
          </div>
        </li>
      ));

      return (
        <div className="many-list">
          <ul className="media-list">{jinglesList}</ul>
        </div>
      );
    }
    return <Loader />;
  }
}

JinglesList.propTypes = {
  data: PropTypes.array.isRequired,
  sort: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  media: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  media: state.media
});

export default connect(
  mapStateToProps,
  { setActiveItem }
)(JinglesList);
