import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from './components/header/HeaderIndex';
import Player from './components/player/PlayerIndex';
import Nav from './components/nav/NavIndex';
import Upload from './components/upload/UploadIndex';
import ChatBox from './components/chatbox/ChatBoxIndex';
import Login from './components/login/LoginIndex';
import Home from './components/home/HomeIndex';
import CurrentPlaylist from './components/currentPlaylist/CurrentPlaylist';
import Tracks from './components/tracks/TracksIndex';
import Jingles from './components/jingles/JinglesIndex';
import Shows from './components/shows/ShowsIndex';
import AutoCreatePlaylist from './components/playlists/AutoCreatePlaylist';
import CreatePlaylist from './components/playlists/CreatePlaylist';
import Playlists from './components/playlists/PlaylistsAll';
import History from './components/history/HistoryIndex';
import SingleMediaItem from './components/singleMediaItem/SingleMediaItemIndex';
import SinglePlaylistItem from './components/singlePlaylistItem/SinglePlaylistItemIndex';
import MyPlaylists from './components/myPlaylists/MyPlaylistsIndex';
import MyAccount from './components/myAccount/MyAccountIndex';
import Schedule from './components/schedule/ScheduleIndex';
import UserProfile from './components/userProfile/UserProfileIndex';
import Scheduling from './components/admin/scheduling/SchedulingIndex';
import ManageDjs from './components/admin/manageDjs/ManageDjsIndex';
import ManageUsers from './components/admin/manageUsers/ManageUsersIndex';
import ManageMedia from './components/admin/manageMedia/ManageMediaIndex';
import IcecastConfig from './components/admin/icecastConfig/IcecastConfigIndex';
import Settings from './components/admin/settings/SettingsIndex';
import ServiceControl from './components/admin/ServiceControl';
import SystemReport from './components/admin/systemReport/SystemReportIndex';
import MyMedia from './components/myMedia/MyMediaIndex';
import Error404 from './components/common/Error404';
import Maintenance from './components/Maintenance';

import { autoLogin } from './store/actions/authActions';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: this.props.auth.isAuthenticated,
      maintenance: this.props.auth.maintenance,
      error: ''
    };
  }

  componentDidMount() {
    // Try to authenticate user by stored token
    if (!this.props.auth.maintenance) {
      if (localStorage.getItem('token')) {
        this.props.autoLogin(localStorage.getItem('token'));
      }
    }
  }

  render() {
    if (this.props.auth.maintenance) {
      return <Maintenance />;
    }
    if (!this.props.auth.isAuthenticated) {
      return <Login />;
    }
    if (this.props.auth.isAuthenticated && !this.props.auth.maintenance) {
      return (
        <BrowserRouter>
          <div className="App">
            <Header />
            <Player />
            <Nav />
            <Upload />

            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/current_playlist"
                component={CurrentPlaylist}
              />
              <Route exact path="/admin/scheduling" component={Scheduling} />
              <Route exact path="/admin/djs" component={ManageDjs} />
              <Route exact path="/admin/users" component={ManageUsers} />
              <Route exact path="/admin/media" component={ManageMedia} />
              <Route exact path="/admin/settings" component={Settings} />
              <Route exact path="/admin/icecast" component={IcecastConfig} />
              <Route exact path="/admin/services" component={ServiceControl} />
              <Route exact path="/admin/system" component={SystemReport} />
              <Route exact path="/schedule" component={Schedule} />
              <Route exact path="/tracks" component={Tracks} />
              <Route exact path="/jingles" component={Jingles} />
              <Route exact path="/shows" component={Shows} />
              <Route exact path="/my_account" component={MyAccount} />
              <Route exact path="/my_playlists" component={MyPlaylists} />
              <Route exact path="/my_media" component={MyMedia} />
              <Route
                exact
                path="/playlist/auto_create"
                component={AutoCreatePlaylist}
              />
              <Route exact path="/playlist/create" component={CreatePlaylist} />
              <Route exact path="/playlists" component={Playlists} />
              <Route exact path="/history" component={History} />
              <Route exact path="/media/([0-9]+)" component={SingleMediaItem} />
              <Route exact path="/user/([a-z0-9]+)" component={UserProfile} />
              <Route
                exact
                path="/playlist/([0-9]+)"
                component={SinglePlaylistItem}
              />
              <Route component={Error404} />
            </Switch>

            <ChatBox />
          </div>
        </BrowserRouter>
      );
    }
    return null;
  }
}

App.propTypes = {
  autoLogin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(
  mapStateToProps,
  { autoLogin }
)(App);
