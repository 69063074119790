import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSys } from '../../store/actions/authActions';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import CurrentPlaylistDisplay from './CurrentPlaylistDisplay';
import NowPlaying from './NowPlaying';
import Loader from '../common/Loader';
import Butters from '../common/Butters';

class CurrentPlaylist extends Component {
  _isMounted = false;
  nav = null;
  state = {
    data: {},
    current_cue: false,
    cue_found: false,
    isShow: false,
    schedule: {},
    init: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.nav = document.getElementById(
      'nav-' + this.props.location.pathname.slice(1)
    );
    this.nav.classList.add('nav-active');
    this.loadData();
    this.props.getSys();
    setTimeout(this.setInitialState, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.nav.classList.remove('nav-active');
  }

  // componentDidUpdate(nextProps) {
  //   if (nextProps.sys.now) {
  //     if (nextProps.sys.now !== this.state.schedule && this.state.init) {
  //       // schedule changed, reload playlist data
  //       //this.loadData();
  //     }
  //   }
  // }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/playlist/current')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data,
              isShow: res.data.is_show
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  setInitialState = () => {
    if (this._isMounted) {
      this.setState({
        current_cue: parseInt(this.props.sys.cue.playlist_index - 1)
      });
    }
  };

  setCue = () => {
    if (this._isMounted) {
      let currentCue = parseInt(this.props.sys.cue.playlist_index - 1);

      // For the initial load make sure we ignore the default index state
      if (!this.state.current_cue) {
        // do nothing
      } else if (
        this.state.current_cue &&
        currentCue &&
        this.state.current_cue !== currentCue &&
        currentCue !== undefined
      ) {
        // store the latest cue from redux props
        this.setState({
          current_cue: currentCue,
          cue_found: true
        });
      }
    }
  };

  handleActiveCue = () => {
    let activated = Array.from(
      document.querySelectorAll('.playlist-item.media-active')
    );
    activated.forEach(node => {
      node.classList.remove('media-active');
    });

    let currentCueDiv = document.getElementById(
      'cue-' + this.state.current_cue
    );
    if (currentCueDiv) {
      currentCueDiv.classList.add('media-active');
    }
  };

  render() {
    if (this.state.data.playlist && this.props.sys.cue) {
      return (
        <div className="content">
          <NowPlaying
            percent={this.props.sys.cue.percent_played}
            duration={this.props.sys.cue.duration_seconds}
          />
          <CurrentPlaylistDisplay
            data={this.state.data}
            reload={this.loadData}
          />
        </div>
      );
    }
    if (!this.props.sys.cue.playlist_index && !this.state.cue_found) {
      return <Butters />;
    }
    return <Loader />;
  }
}

CurrentPlaylist.propTypes = {
  media: PropTypes.object.isRequired,
  sys: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  media: state.media,
  sys: state.sys
});

export default connect(
  mapStateToProps,
  { getSys }
)(CurrentPlaylist);
