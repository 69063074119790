import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import IcecastSettings from './IcecastSettings';
import alertify from 'alertifyjs';
import Error404 from '../../common/Error404';
import Loader from '../../common/Loader';

class IcecastConfigIndex extends Component {
  _isMounted = false;
  state = {
    data: {},
    error: ''
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.loadData, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/settings/icecast')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.status });
          });
      }
    }
  };

  updateSettings = obj => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        let data = new FormData();
        data.append('accesslog', obj.accesslog);
        data.append('admin', obj.admin);
        data.append('admin-password', obj.adminPassword);
        data.append('admin-user', obj.adminUser);
        data.append('alias-destination', obj.aliasDestination);
        data.append('basedir', obj.basedir);
        data.append('burst-size', obj.burstSize);
        data.append('chroot', obj.chroot);
        data.append('errorlog', obj.errorlog);
        data.append('fallback-override', obj.fallbackOverride);
        data.append('fileserve', obj.fileserve);
        data.append('hidden-m1', obj.hiddenM1);
        data.append('hidden-m2', obj.hiddenM2);
        data.append('hidden-m3', obj.hiddenM3);
        data.append('hidden-m4', obj.hiddenM4);
        data.append('hostname', obj.hostname);
        data.append('location', obj.location);
        data.append('logdir', obj.logdir);
        data.append('loglevel', obj.loglevel);
        data.append('logsize', obj.logsize);
        data.append('max-listeners-m1', obj.maxListenersM1);
        data.append('max-listeners-m2', obj.maxListenersM2);
        data.append('max-listeners-m3', obj.maxListenersM3);
        data.append('max-listeners-m4', obj.maxListenersM4);
        data.append('mount-name-m1', obj.mountNameM1);
        data.append('mount-name-m2', obj.mountNameM2);
        data.append('mount-name-m3', obj.mountNameM3);
        data.append('mount-name-m4', obj.mountNameM4);
        data.append('on-connect', obj.onConnect);
        data.append('on-disconnect', obj.onDisconnect);
        data.append('password-m1', obj.passwordM1);
        data.append('password-m2', obj.passwordM2);
        data.append('password-m3', obj.passwordM3);
        data.append('password-m4', obj.passwordM4);
        data.append('port', obj.port);
        data.append('public-m1', obj.publicM1);
        data.append('public-m2', obj.publicM2);
        data.append('public-m3', obj.publicM3);
        data.append('public-m4', obj.publicM4);
        data.append('relay-password', obj.relayPassword);
        data.append('source-password', obj.sourcePassword);
        data.append('username-m1', obj.usernameM1);
        data.append('username-m2', obj.usernameM2);
        data.append('username-m3', obj.usernameM3);
        data.append('username-m4', obj.usernameM4);
        data.append('webroot', obj.webroot);

        axios
          .post(API_URI + '/settings/icecast/update', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
            alertify.success(
              '<i class="far fa-check-circle"></i> Icecast config was updated!'
            );
          })
          .catch(err => {
            this.setState({ error: err.response.status });
          });
      }
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.state.data.icecast
    ) {
      return (
        <div className="content">
          <h3>Icecast Config</h3>
          <div className="icecast-settings-info">
            <i className="fas fa-info-circle" /> Changes made below aren't
            registered until the config is next reloaded by the scheduler.
          </div>
          <IcecastSettings
            icecast={this.state.data.icecast}
            update={this.updateSettings}
          />
        </div>
      );
    }
    if (!this.props.auth.isAuthenticated || !this.props.auth.user.can_admin) {
      return <Error404 />;
    }
    return <Loader />;
  }
}

IcecastConfigIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(IcecastConfigIndex);
