import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

class NotificationsList extends Component {
  render() {
    let notifications = this.props.notifications;

    if (notifications === undefined || notifications.length === 0) {
      return (
        <div className="notification-list">
          <p>Nothing to see at the moment!</p>
        </div>
      );
    }

    let notificationList = notifications.map(item => (
      <li key={item.id}>
        {item.media_id === 0 ? (
          <div>
            <i className="far fa-comment" />{' '}
            {item.seen === 0 ? <b>{item.msg}</b> : item.msg}
          </div>
        ) : (
          <Link to={`/media/${item.media_id}`}>
            {item.seen === 0 ? <b>{item.msg}</b> : item.msg}
          </Link>
        )}
        <div className="list-time">{item.time_ago}</div>
      </li>
    ));

    return (
      <div className="notification-list">
        <Scrollbars style={{ width: 500, height: 300 }}>
          <ul>{notificationList}</ul>
        </Scrollbars>
      </div>
    );
  }
}

NotificationsList.propTypes = {
  notifications: PropTypes.array
};

export default NotificationsList;
