import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import Error404 from '../../common/Error404';
import DjsList from './DjsList';
import AddDj from './AddDj';
import Loader from '../../common/Loader';

class ManageDjsIndex extends Component {
  _isMounted = false;
  state = {
    data: [],
    users: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
    this.loadUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/scheduling/djs/get')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  loadUsers = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/users/get')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              users: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.state.data &&
      this.state.users.length > 0
    ) {
      return (
        <div className="content">
          <div className="djm-wrap">
            <h2>Manage DJs</h2>
            <AddDj reload={this.loadData} users={this.state.users} />
            <DjsList
              djs={this.state.data}
              reload={this.loadData}
              users={this.state.users}
            />
          </div>
        </div>
      );
    }
    if (this.props.auth.isAuthenticated && !this.props.auth.user.can_admin) {
      return <Error404 />;
    }
    return <Loader />;
  }
}

ManageDjsIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(ManageDjsIndex);
