export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const SET_RESET_REQUEST = 'SET_RESET_REQUEST';
export const KILL_RESET_REQUEST = 'KILL_RESET_REQUEST';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';

export const MAINTENANCE_MODE = 'MAINTENANCE_MODE';

export const SET_STATS = 'SET_STATS';
export const SET_SYS = 'SET_SYS';

export const SET_ACTIVE_MEDIA_ITEM = 'SET_ACTIVE_MEDIA_ITEM';

export const SET_CURRENT_CUE_DATA = 'SET_CURRENT_CUE_DATA';
