import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import AddDjModal from './AddDjModal';
import alertify from 'alertifyjs';

class AddDj extends Component {
  _isMounted = false;
  state = {
    modal: false,
    users: this.props.users
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  saveDj = obj => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        let data = new FormData();
        data.append('user_id', obj.user_id);
        data.append('name', obj.name);
        data.append('show_title', obj.show_title);
        data.append('start_hour', obj.start_hour);
        data.append('end_hour', obj.end_hour);
        data.append('day_no', obj.day_no);
        data.append('password', obj.password);
        data.append('s1', obj.s1);
        data.append('s2', obj.s2);
        data.append('s3', obj.s3);
        data.append('s4', obj.s4);
        data.append('plays_live', obj.plays_live);
        data.append('active', obj.active);
        data.append('multi', obj.multi);

        setAuthHeader(token);
        axios
          .post(API_URI + '/djs/add', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              modal: false
            });
            this.props.reload();
          })
          .catch(err => {
            this.setState({
              modal: false
            });
            alertify
              .alert(
                '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                  err.response.data
              )
              .setHeader(
                '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
              );
          });
      }
    }
  };

  openModal = () => {
    if (this._isMounted) {
      this.setState({
        modal: true
      });
    }
  };

  closeModal = () => {
    if (this._isMounted) {
      this.setState({
        modal: false
      });
    }
  };

  render() {
    if (this.state.users && this.state.users.length > 0) {
      return (
        <div className="add-dj">
          <button className="btn" onClick={this.openModal}>
            <i className="far fa-calendar-alt" /> ADD A NEW DJ TO THE SCHEDULE
          </button>
          {this.state.modal ? (
            <AddDjModal
              users={this.state.users}
              save={this.saveDj}
              close={this.closeModal}
            />
          ) : null}
        </div>
      );
    }
    return null;
  }
}

AddDj.propTypes = {
  reload: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};

export default AddDj;
