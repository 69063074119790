import React, { Component } from 'react';
import axios from 'axios';
import { API_URI, AVATAR_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import Error404 from '../common/Error404';
import moment from 'moment';
import UserProfileShows from './UserProfileShows';
import UserProfileTracks from './UserProfileTracks';
import UserProfileJingles from './UserProfileJingles';
import UserProfilePlaylists from './UserProfilePlaylists';
import Cartman from '../common/Cartman';
import Stan from '../common/Stan';
import Kenny from '../common/Kenny';
import Ghost from '../common/Ghost';

class UserProfileIndex extends Component {
  _isMounted = false;
  state = {
    ready: false,
    path: this.props.location.pathname,
    user: {},
    show: {},
    playlists: {},
    media: {},
    selected: 'shows'
  };

  componentDidMount() {
    this._isMounted = true;
    if (getLS('opt.ups')) {
      this.setState({
        selected: getLS('opt.ups')
      });
    }
    this.refresh();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isMounted) {
      if (prevState.path !== this.state.path) {
        this.refresh();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.path) {
      return {
        path: nextProps.location.pathname
      };
    } else return null;
  }

  refresh = props => {
    if (this._isMounted) {
      props = props || this.props;

      this.setState({
        ready: false,
        path: this.props.location.pathname,
        user: {},
        show: {},
        playlists: {},
        media: {},
        error: ''
      });

      let token = getLS('token');

      if (token) {
        setAuthHeader(getLS('token'));

        let data = new FormData();
        data.append('username', this.state.path.substring(6));

        axios
          .post(API_URI + '/user/profile', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            this.setState({
              ready: true,
              user: res.data.profile.user,
              show: res.data.profile.show,
              playlists: res.data.profile.playlists,
              media: res.data.profile.media,
              error: ''
            });
          })
          .catch(err => {
            this.setState({
              ready: true,
              user: {},
              show: {},
              playlists: {},
              media: {},
              error: err.response.status
            });
          });
      }
    }
  };

  handleClick = e => {
    this.setPrefs(e.target.name);
  };

  setPrefs = x => {
    setLS('opt.ups', x);
    this.setState({
      selected: x
    });
  };

  render() {
    const url = AVATAR_URI + '/';
    if (this.state.ready && !this.state.error) {
      const user = this.state.user;
      const show = this.state.show;
      return (
        <div className="content">
          <div className="profile">
            <div className="avatar">
              <img src={url + user.avatar} alt={user.display_name} />
            </div>
            <div className="profile-meta">
              <h1>{user.display_name}</h1>
              <h2>@{user.username}</h2>

              {user.last_seen ? (
                <h3>
                  Last active: <b>{user.last_seen}</b>
                </h3>
              ) : null}

              {show.title ? <h4>{show.title}</h4> : null}

              {show.scheduled ? (
                <h5>
                  <i className="far fa-calendar-alt" /> <b>{show.scheduled}</b>{' '}
                  ({show.frequency})
                </h5>
              ) : null}

              {show.date ? (
                <h5>
                  Next airs on: <b>{moment(show.date).format('LLL')}</b>
                </h5>
              ) : null}
            </div>
            <div className="buttons">
              <button
                className={`btn btn-mm ${
                  this.state.selected === 'shows' ? 'mm-active' : ''
                }`}
                name="shows"
                onClick={this.handleClick}
              >
                SHOWS
              </button>
              <button
                className={`btn btn-mm ${
                  this.state.selected === 'tracks' ? 'mm-active' : ''
                }`}
                name="tracks"
                onClick={this.handleClick}
              >
                TRACKS
              </button>
              <button
                className={`btn btn-mm ${
                  this.state.selected === 'jingles' ? 'mm-active' : ''
                }`}
                name="jingles"
                onClick={this.handleClick}
              >
                JINGLES
              </button>
              <button
                className={`btn btn-mm ${
                  this.state.selected === 'playlists' ? 'mm-active' : ''
                }`}
                name="playlists"
                onClick={this.handleClick}
              >
                PLAYLISTS
              </button>
            </div>
          </div>

          {this.state.selected === 'shows' ? (
            this.state.media.shows ? (
              <UserProfileShows data={this.state.media.shows} />
            ) : (
              <div className="no-content">
                <Cartman />
                <div>No shows currently uploaded</div>
              </div>
            )
          ) : null}

          {this.state.selected === 'tracks' ? (
            this.state.media.tracks ? (
              <UserProfileTracks data={this.state.media.tracks} />
            ) : (
              <div className="no-content">
                <Stan />
                <div>No tracks currently uploaded</div>
              </div>
            )
          ) : null}

          {this.state.selected === 'jingles' ? (
            this.state.media.jingles ? (
              <UserProfileJingles data={this.state.media.jingles} />
            ) : (
              <div className="no-content">
                <Kenny />
                <div>No jingles currently uploaded</div>
              </div>
            )
          ) : null}

          {this.state.selected === 'playlists' ? (
            this.state.playlists ? (
              <UserProfilePlaylists data={this.state.playlists} />
            ) : (
              <div className="no-content">
                <Ghost />
                <div>No playlists created</div>
              </div>
            )
          ) : null}
        </div>
      );
    }
    if (this.state.ready && this.state.error === 404) {
      return <Error404 />;
    }
    return null;
  }
}

export default UserProfileIndex;
