export function storageAvailable() {
  try {
    var storage = window['localStorage'],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    );
  }
}

export function updateLS(array) {
  if (storageAvailable()) {
    for (let [key, value] of Object.entries(array)) {
      localStorage.setItem(key, value);
    }
  }
}

export function getLS(key) {
  if (storageAvailable()) {
    if (localStorage.getItem(key)) {
      return localStorage.getItem(key);
    }
  }
}

export function setLS(key, value) {
  if (storageAvailable()) {
    if (localStorage.setItem(key, value)) {
    }
  }
}

// Remove all account based LS items
export function logoutLS() {
  if (storageAvailable()) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
}
