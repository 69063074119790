import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setActiveItem } from '../../store/actions/authActions';

class Playlist extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.checkActive, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkActive = () => {
    if (this._isMounted) {
      if (this.props.media.id) {
        let target = document.getElementById('mid-' + this.props.media.id);
        if (target) {
          target.classList.add('media-active');
        }
      }
    }
  };

  loadMedia = e => {
    if (this._isMounted) {
      // Remove active item if present
      if (this.props.media.id) {
        let target = document.getElementById('mid-' + this.props.media.id);

        if (target) {
          target.classList.remove('media-active');
        }
      }

      // Create obj containing media data
      let obj = {
        id: e.target.getAttribute('data-id'),
        title: e.target.getAttribute('data-title'),
        artist: e.target.getAttribute('data-artist'),
        duration: e.target.getAttribute('data-duration')
      };

      // Set the new active media item state
      this.props.setActiveItem(obj);
    }
  };

  render() {
    let items = this.props.data.playlist;

    let playList = items.map((item, index) => (
      <li key={index} className="playlist-item" id={`mid-${item.id}`}>
        <div className={`${item.type}-play`}>
          <i
            className={
              item.type === 'track' ? 'fas fa-music' : 'fas fa-microphone-alt'
            }
            onClick={this.loadMedia.bind(this)}
            data-id={item.id}
            data-title={item.title}
            data-artist={item.artist}
            data-duration={item.duration}
          />
        </div>

        <div className="meta">
          <div className="meta-top">
            <span className="m-title">
              <Link to={`/media/${item.id}`}>{item.title}</Link>
            </span>
            <span className="artist">{item.artist}</span>
          </div>
          <div className="meta-bottom">
            <span className="duration">
              <i className="far fa-clock" /> {item.duration}
            </span>
            <span className="filename">
              <i className="far fa-save" /> {item.filename}
            </span>
            <span className="age">
              <i className="fas fa-upload" /> {item.time_ago}
            </span>
          </div>
        </div>
      </li>
    ));

    return (
      <div className="playlist">
        <h3>
          Contains {this.props.data.playlist.length} items. Total duration:{' '}
          {this.props.data.duration}{' '}
        </h3>
        <ul className="media-list">{playList}</ul>
      </div>
    );
  }
}

Playlist.propTypes = {
  data: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  media: state.media
});

export default connect(
  mapStateToProps,
  { setActiveItem }
)(Playlist);
