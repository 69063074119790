import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AVATAR_URI } from '../../globals.js';
import alertify from 'alertifyjs';

class MyAccountProfile extends Component {
  state = {
    edit: false,
    email: this.props.profile.email
  };

  onChange = e => {
    this.setState({
      email: e.target.value
    });
  };

  edit = () => {
    this.setState({
      edit: !this.state.edit
    });
  };

  updateEmail = () => {
    if (this.state.email && this.validateEmail(this.state.email)) {
      this.props.update(this.state.email);
      this.setState({
        edit: false
      });
    } else {
      alertify
        .alert(
          '<i class="fas fa-exclamation-triangle"></i> Error: <b>Please supply a valid email address!'
        )
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    }
  };

  validateEmail = email => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  render() {
    if (this.props.profile) {
      const user = this.props.profile;
      const avatar = AVATAR_URI + '/' + user.avatar;
      return (
        <div className="ac-profile-wrap">
          <h2>My Account</h2>
          <div className="layout">
            <div className="avatar">
              <img src={avatar} alt={user.display_name} />
            </div>
            <div className="meta">
              <div>
                DJ Name: <b>{user.display_name}</b>
              </div>
              <div>
                Username: <b>{user.username}</b>
              </div>
              <div className="acc-email">
                {this.state.edit ? (
                  <div>
                    <input
                      type="text"
                      name="email"
                      className="emailInput"
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                    <button className="btn" onClick={this.updateEmail}>
                      <i className="far fa-save" /> SAVE
                    </button>
                  </div>
                ) : (
                  <div>
                    Email: <b>{user.email}</b>{' '}
                    <button className="btn" onClick={this.edit}>
                      <i className="far fa-edit" /> EDIT
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

MyAccountProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired
};

export default MyAccountProfile;
