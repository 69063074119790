import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UploadMeta from './UploadMeta';

class UploadDisplay extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if (nextProps.progress.loaded !== this.props.progress.loaded) {
      this.updateProgressBar(
        this.props.progress.loaded,
        this.props.progress.total
      );
    }
  }

  updateProgressBar = (loaded, total) => {
    let progress = document.getElementById('_progress');
    progress.style.width = Math.ceil((loaded / total) * 100) + '%';
  };

  render() {
    if (this.props.filename && this.props.options.shows) {
      return (
        <div id="upload-file-final-container">
          <div id="file-name">
            <span className="ul-icon">
              <i className="fas fa-file-upload" />
            </span>{' '}
            {this.props.filename}
          </div>

          {this.props.uploading === false ? (
            <div id="uploadInProgress">
              <h4>What are you uploading?</h4>

              <div className="uploadType">
                <div className="inputGroup">
                  <input
                    id="show"
                    name="type"
                    type="radio"
                    defaultChecked
                    onClick={this.props.changeType}
                  />
                  <label htmlFor="show">
                    <i className="fas fa-vr-cardboard" /> SHOW
                  </label>
                </div>
                <div className="inputGroup">
                  <input
                    id="track"
                    name="type"
                    type="radio"
                    onClick={this.props.changeType}
                  />
                  <label htmlFor="track">
                    <i className="fas fa-music" /> TRACK
                  </label>
                </div>
                <div className="inputGroup">
                  <input
                    id="jingle"
                    name="type"
                    type="radio"
                    onClick={this.props.changeType}
                  />
                  <label htmlFor="jingle">
                    <i className="fas fa-microphone-alt" /> JINGLE
                  </label>
                </div>
              </div>
              <UploadMeta
                onChange={this.props.onChange}
                type={this.props.type}
                options={this.props.options}
                onSelectShow={this.props.onSelectShow}
              />
            </div>
          ) : null}

          {this.props.uploading ? (
            <div id="progress_outer">
              <div id="timeRemaining">
                About <b>{this.props.timeRemaining}</b> seconds left
              </div>
              <div id="_progress" className="progress" />
            </div>
          ) : null}

          <div className="uploadBtns">
            {this.props.uploading ? null : (
              <button
                className="btn btn-upload-m"
                id="upload-button"
                onClick={this.props.upload}
              >
                UPLOAD
              </button>
            )}
            <button className="btn btn-cancel" onClick={this.props.cancel}>
              Cancel
            </button>
          </div>
        </div>
      );
    }
    return null;
  }
}

UploadDisplay.propTypes = {
  filename: PropTypes.string.isRequired,
  changeType: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  upload: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectShow: PropTypes.func.isRequired,
  progress: PropTypes.object.isRequired,
  timeRemaining: PropTypes.number.isRequired
};

export default UploadDisplay;
