import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import UserMenu from './UserMenu';
import Notifications from '../notifications/NotificationsIndex';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';

class User extends Component {
  _isMounted = false;
  timer = null;

  state = {
    notifications: {}
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.loadNotifications, 100);
    this.timer = setInterval(this.loadNotifications, 30000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timer);
  }

  loadNotifications = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/notifications')
          .then(res => {
            if (res.data.notifications === false) {
              this.setState({
                notifications: {}
              });
            } else {
              this.setState({
                notifications: res.data.notifications
              });
            }
          })
          .catch(err => {
            // console.log(err);
          });
      }
    }
  };

  reloadNotifications = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/notifications')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            if (res.data.notifications === false) {
              this.setState({
                notifications: {}
              });
            } else {
              this.setState({
                notifications: res.data.notifications
              });
            }
          })
          .catch(err => {
            // console.log(err);
          });
      }
    }
  };

  render() {
    if (this.props.auth.isAuthenticated && !this.props.auth.maintenance) {
      const user = this.props.auth.user;
      return (
        <div className="user-wrap">
          <UserMenu user={user} />
          <Notifications
            notifications={this.state.notifications}
            reload={this.reloadNotifications}
          />
        </div>
      );
    }
    return null;
  }
}

User.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(User);
