import React, { Component } from 'react';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import alertify from 'alertifyjs';
import SinglePlaylistItemList from './SinglePlaylistItemList';
import Error404 from '../common/Error404';
import EditSinglePlaylistItem from './EditSinglePlaylistItem';

class SinglePlaylistItemIndex extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      data: [],
      title: '',
      seconds: '',
      allMedia: [],
      path: this.props.location.pathname,
      editing: false,
      error: '',
      selected: [],
      redirect: false,
      currentUser: 0
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.refresh();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isMounted) {
      if (prevState.path !== this.state.path) {
        this.refresh();
      }
      if (this.state.redirect) {
        alertify.success(
          '<i class="fas fa-info-circle"></i> Playlist dusted...'
        );
        this.props.history.push('/my_playlists');
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.path) {
      return {
        path: nextProps.location.pathname
      };
    } else return null;
  }

  refresh = props => {
    if (this._isMounted) {
      props = props || this.props;

      this.setState({
        ready: false,
        path: this.props.location.pathname,
        data: [],
        seconds: '',
        allMedia: [],
        selected: [],
        editing: false,
        error: '',
        currentUser: getLS('user')
      });

      let token = getLS('token');

      if (token) {
        setAuthHeader(getLS('token'));

        let data = new FormData();
        data.append('pid', this.state.path.substring(10));

        axios
          .post(API_URI + '/playlists/get_single', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            this.setState({
              ready: true,
              data: res.data,
              title: res.data.title,
              seconds: res.data.seconds,
              allMedia: res.data.media,
              editing: false,
              error: '',
              selected: res.data.items
            });
          })
          .catch(err => {
            this.setState({
              ready: true,
              data: [],
              title: '',
              seconds: '',
              allMedia: [],
              selected: [],
              editing: false,
              error: err.response.status
            });
          });
      }
    }
  };

  restart = () => {
    if (this._isMounted) {
      let self = this;
      alertify
        .confirm(
          '<b>Are you sure you want to restart Ices now?</b>',
          function() {
            self.restartIces();
          },
          function() {
            alertify.error(
              '<i class="fas fa-info-circle"></i> Restart cancelled!'
            );
          }
        )
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    }
  };

  restartIces = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(getLS('token'));

        let data = new FormData();
        data.append('pid', this.state.data.pid);

        axios
          .post(API_URI + '/restart', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            alertify.success(
              '<i class="fas fa-info-circle"></i> Ices restarting...'
            );
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i>' + err.response.data
            );
          });
      }
    }
  };

  formatTime = n => {
    let hrs = Math.floor(n / 3600);
    let mins = Math.floor((n - hrs * 3600) / 60);
    let secs = n - (hrs * 3600 + mins * 60);
    if (hrs < 10) {
      hrs = '0' + hrs;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }
    if (secs < 10) {
      secs = '0' + secs;
    }
    return hrs + ':' + mins + ':' + secs;
  };

  editMedia = () => {
    this.setState({ editing: !this.state.editing });
  };

  saveEdit = newData => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(getLS('token'));

        let data = new FormData();
        data.append('pid', this.state.data.id);
        data.append('title', newData.title);
        data.append('array', newData.array);

        axios
          .post(API_URI + '/playlist/edit', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            alertify.success(
              '<i class="fas fa-info-circle"></i> Playist saved!'
            );
            this.setState({
              editing: false
            });
            this.refresh();
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i>' + err.response.data
            );
          });
      }
    } else {
      console.log('went tits up');
    }
  };

  delete = () => {
    let self = this;
    alertify
      .confirm(
        "<b>Are you sure you want to delete this playlist?</b><br/><br />This operation is irreversible, once it's gone - it's gone!",
        function() {
          self.deletePlaylist();
        },
        function() {
          alertify.error(
            '<i class="fas fa-info-circle"></i> Playlist lives to fight another day!'
          );
        }
      )
      .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
  };

  deletePlaylist = () => {
    if (this._isMounted) {
      let data = new FormData();
      data.append('pid', this.state.data.id);

      let token = getLS('token');

      if (token) {
        setAuthHeader(getLS('token'));

        let data = new FormData();
        data.append('pid', this.state.data.id);

        axios
          .post(API_URI + '/playlist/delete', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            this.setState({
              redirect: true
            });
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i>' + err.response.data
            );
          });
      }
    }
  };

  render() {
    if (this.state.ready && !this.state.error) {
      let items = this.state.selected;
      let playlist = this.state.data;

      if (this.state.editing && this.state.allMedia) {
        return (
          <div className="content">
            <div className="editing">
              <button
                className="btn cancel-btn"
                type="submit"
                name="edit-media"
                onClick={this.editMedia}
              >
                {this.state.editing ? 'CANCEL' : 'EDIT PLAYLIST'}
              </button>
            </div>

            <EditSinglePlaylistItem
              selected={items}
              media={this.state.allMedia}
              seconds={this.state.seconds}
              pid={this.state.data.id}
              title={this.state.title}
              save={this.saveEdit}
            />
          </div>
        );
      }
      return (
        <div className="content">
          <div className="single-media pl-single">
            <div className="pl-icon">
              <i className="fas fa-clipboard-list" />
            </div>

            <div className="meta">
              <div className="title">{this.state.title}</div>

              <div>
                Items Count:{' '}
                <span className="data">{playlist.items_count}</span>
              </div>

              <div>
                Duration:{' '}
                <span className="data">
                  {this.formatTime(playlist.seconds)}
                </span>
              </div>

              <div>
                Playlist ID: <span className="data">{playlist.id}</span>
              </div>

              <div>
                Last Aired: <span className="data">{playlist.last_aired}</span>
              </div>

              <div className="owner">
                Created by{' '}
                <strong>
                  {playlist.owner_id === this.state.currentUser.id
                    ? 'YOU'
                    : playlist.owner}
                </strong>{' '}
                {playlist.time_ago}
              </div>

              {playlist.can_edit && !this.state.data.locked ? (
                <div className="buttons">
                  <button
                    className="btn blue-btn mrt20"
                    type="submit"
                    name="edit"
                    onClick={this.editMedia}
                  >
                    <i className="far fa-edit" /> EDIT
                  </button>

                  <button
                    className="btn red-btn"
                    type="submit"
                    name="delete"
                    onClick={this.delete}
                  >
                    <i className="far fa-trash-alt" /> DELETE
                  </button>
                </div>
              ) : null}

              {this.state.data.locked ? (
                <div className="locked">
                  <i className="fas fa-compact-disc" /> Playlist is currently
                  running!
                </div>
              ) : null}

              {!this.state.editing && playlist.can_admin ? (
                <div className="restart">
                  <button
                    className="btn green-btn"
                    type="submit"
                    name="restart-ices"
                    onClick={this.restart}
                  >
                    RESTART ICES WITH THIS PLAYLIST
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <SinglePlaylistItemList items={items} />
        </div>
      );
    }
    if (this.state.ready && this.state.error === 404) {
      return <Error404 />;
    }
    return null;
  }
}

export default SinglePlaylistItemIndex;
