import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SettingsStatic extends Component {
  render() {
    if (this.props.data) {
      const settings = this.props.data.settings;

      return (
        <div className="ice-settings">
          <div className="ice-heading">LIVE SYSTEM STATUS</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">CURRENT DJ / SHOW</div>
              <input type="text" value={settings.current_dj} readOnly />
            </div>

            <div className="cell">
              <div className="title">CURRENT DJ / SHOW ID</div>
              <input type="text" value={settings.current_dj_id} readOnly />
            </div>

            <div className="cell">
              <div className="title">CURRENT PLAYLIST</div>
              <input type="text" value={settings.current_playlist} readOnly />
            </div>

            <div className="cell">
              <div className="title">CURRENTLY PROCESSING</div>
              <input
                type="text"
                value={settings.currently_processing}
                readOnly
              />
            </div>

            <div className="cell">
              <div className="title">USERS ONLINE</div>
              <input
                type="text"
                value={settings.current_users.length}
                readOnly
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

SettingsStatic.propTypes = {
  data: PropTypes.object.isRequired
};

export default SettingsStatic;
