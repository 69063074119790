import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setActiveItem } from '../../store/actions/authActions';
import ReactTooltip from 'react-tooltip';

class UserProfileJingles extends Component {
  _isMounted = false;
  state = {
    data: this.props.data
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.checkActive, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  checkActive = () => {
    if (this._isMounted) {
      if (this.props.media.id) {
        let target = document.getElementById('mid-' + this.props.media.id);
        if (target) {
          target.classList.add('media-active');
        }
      }
    }
  };

  loadMedia = e => {
    if (this._isMounted) {
      // Remove active item if present
      if (this.props.media.id) {
        let target = document.getElementById('mid-' + this.props.media.id);

        if (target) {
          target.classList.remove('media-active');
        }
      }

      // Create obj containing media data
      let obj = {
        id: e.target.getAttribute('data-id'),
        title: e.target.getAttribute('data-title'),
        artist: e.target.getAttribute('data-artist'),
        duration: e.target.getAttribute('data-duration')
      };

      // Set the new active media item state
      this.props.setActiveItem(obj);
    }
  };

  render() {
    if (this.props.data) {
      let jinglesList = this.props.data.map(item => (
        <li key={item.id} className="playlist-item" id={`mid-${item.id}`}>
          {item.warn ? (
            <div className="show-warn">
              <i
                className="fas fa-exclamation-triangle"
                data-tip={`Media file is ${
                  item.warn
                } seconds shorter than this show's allocated timeslot! It will be padded with extra content when it airs.`}
              />
            </div>
          ) : null}

          <div className="track-play">
            <i
              className="fas fa-play"
              onClick={this.loadMedia.bind(this)}
              data-id={item.id}
              data-title={item.title}
              data-artist={item.artist}
              data-duration={item.duration}
            />
          </div>

          <div className="meta">
            <div className="meta-top">
              <span className="m-title">
                <Link to={`/media/${item.id}`}>{item.title}</Link>
              </span>
              <span className="artist">{item.artist}</span>
            </div>
            <div className="meta-bottom">
              <span className="duration">
                <i className="far fa-clock" /> {item.duration}
              </span>
              <span className="filename">
                <i className="fas fa-play" /> {item.plays}
              </span>
              <span className="age">
                <i className="fas fa-upload" /> {item.time_ago}
              </span>
              {item.active ? (
                <span className="activated" data-tip="Activated!">
                  <i className="fas fa-check-circle" />
                </span>
              ) : (
                <span className="deactivated" data-tip="Deactivated!">
                  <i className="fas fa-check-circle" />
                </span>
              )}

              {item.in_playlist ? (
                <span className="activated" data-tip="This file is playlisted!">
                  <i className="fas fa-bars" />
                </span>
              ) : (
                <span
                  className="deactivated"
                  data-tip="File isn't in any playlists."
                >
                  <i className="fas fa-bars" />
                </span>
              )}

              <ReactTooltip />
            </div>
          </div>
        </li>
      ));
      return (
        <div className="many-list">
          <ul className="media-list">{jinglesList}</ul>
        </div>
      );
    }
    return null;
  }
}

UserProfileJingles.propTypes = {
  data: PropTypes.array.isRequired,
  media: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  media: state.media
});

export default connect(
  mapStateToProps,
  { setActiveItem }
)(UserProfileJingles);
