import axios from 'axios';
import { setLS, logoutLS, getLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import alertify from 'alertifyjs';

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  CLEAR_ERRORS,
  CLEAR_CURRENT_USER,
  SET_SYS,
  SET_ACTIVE_MEDIA_ITEM,
  SET_RESET_REQUEST,
  KILL_RESET_REQUEST,
  SET_NEW_PASSWORD,
  SET_CURRENT_CUE_DATA,
  MAINTENANCE_MODE
} from '../actionTypes';

import { API_URI } from '../../globals';
import jwtDecode from 'jwt-decode';

export const loginUser = data => dispatch => {
  axios
    .post(API_URI + '/login/go', data)
    .then(res => {
      if (res.headers['set-authorization']) {
        setLS('token', res.headers['set-authorization']);
        setLS('user', JSON.stringify(res.data.user));
      }
      // Set current user
      dispatch(setCurrentUser(res.data.user));
      dispatch(clearErrors(true));
      // openWebsocket();
    })
    .catch(err => {
      if (err.response.status && err.response.status === 401) {
        logoutLS();
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        });
        dispatch({
          type: CLEAR_CURRENT_USER,
          payload: true
        });
      } else if (err.response.status && err.response.status === 503) {
        logoutLS();
        dispatch({
          type: MAINTENANCE_MODE,
          payload: true
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.errors
        });
      }
    });
};

// Open websocket
// export const openWebsocket = () => {
//   let conn = new WebSocket('ws://amps.d3ep.com:8080');
//   conn.onopen = function(e) {
//     console.log('Connection established!');
//   };

//   conn.onmessage = function(e) {
//     console.log(e.data);
//   };
// };

export const autoLogin = token => dispatch => {
  if (!token) {
    return;
  }

  setAuthHeader(token);

  axios
    .post(API_URI + '/login/auto')
    .then(res => {
      if (res.headers['set-authorization']) {
        setLS('token', res.headers['set-authorization']);
        setLS('user', JSON.stringify(res.data.user));
        setLS('opt.rqp', false);
        dispatch(clearErrors(true));
      }

      // Set current user
      dispatch(setCurrentUser(res.data.user));
    })
    .catch(err => {
      if (err.response.status && err.response.status === 401) {
        logoutLS();
        dispatch({
          type: CLEAR_CURRENT_USER,
          payload: true
        });
      } else if (err.response.status && err.response.status === 503) {
        logoutLS();
        dispatch({
          type: MAINTENANCE_MODE,
          payload: true
        });
      } else {
        logoutLS();
      }
      logoutLS();
    });
};

// Request password reset
export const resetRequest = data => dispatch => {
  axios
    .post(API_URI + '/login/reset', data)
    .then(res => {
      // Set current user
      dispatch(setResetRequest(res.data));
      dispatch(clearErrors(true));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Set requested password reset
export const setResetRequest = payload => {
  return {
    type: SET_RESET_REQUEST,
    payload: payload
  };
};

// Kill requested password reset
export const killResetRequest = payload => {
  return {
    type: KILL_RESET_REQUEST,
    payload: payload
  };
};

// Submit new password
export const submitNewPassword = data => dispatch => {
  axios
    .post(API_URI + '/login/set', data)
    .then(res => {
      // Set current user
      dispatch(setNewPassword(res.data));
      dispatch(clearErrors(true));
      alertify.success(
        '<i class="far fa-check-circle"></i> Password updated! You can now login with your new password.'
      );
    })
    .catch(err => {
      dispatch(killResetRequest(false));
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Set new password
export const setNewPassword = payload => {
  return {
    type: SET_NEW_PASSWORD,
    payload: payload
  };
};

// Return new updated user object
export const refreshUser = () => dispatch => {
  setAuthHeader(getLS('token'));

  axios
    .post(API_URI + '/users/authenticate')
    .then(res => {
      if (res.headers['set-authorization']) {
        setLS('token', res.headers['set-authorization']);
      }
      const user = res.data.user;
      // Set current user
      dispatch(setCurrentUser(user));
      dispatch(clearErrors(true));
    })
    .catch(err => {
      if (err.response.status && err.response.status === 401) {
        dispatch({
          type: CLEAR_CURRENT_USER,
          payload: true
        });
        logoutLS();
      } else if (err.response.status && err.response.status === 503) {
        dispatch({
          type: MAINTENANCE_MODE,
          payload: true
        });
        logoutLS();
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data.errors
        });
      }
    });
};

// Set logged in user
export const setCurrentUser = user => {
  return {
    type: SET_CURRENT_USER,
    payload: user
  };
};

// Clear errors
export const clearErrors = payload => {
  return {
    type: CLEAR_ERRORS,
    payload: payload
  };
};

// Get Sys
export const getSys = () => dispatch => {
  let token = verifyLocalToken();

  if (token) {
    axios
      .post(API_URI + '/sys')
      .then(res => {
        // if (res.headers['set-authorization']) {
        //   setLS('token', res.headers['set-authorization']);
        // }
        // Set stats
        dispatch(setSys(res.data));
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status && err.response.status === 401) {
            dispatch({
              type: CLEAR_CURRENT_USER
            });
            logoutLS();
          }
          if (err.response.status && err.response.status === 503) {
            dispatch({
              type: MAINTENANCE_MODE
            });
            logoutLS();
          }
        }
      });
  } else {
    // No token
    dispatch({
      type: CLEAR_CURRENT_USER
    });
    logoutLS();
  }
};

// Set sys
export const setSys = sys => {
  return {
    type: SET_SYS,
    payload: sys
  };
};

// Get Cue
export const getCue = () => dispatch => {
  axios
    .post(API_URI + '/cue')
    .then(res => {
      // Set stats
      dispatch(setCue(res.data));
      // dispatch(clearErrors);
    })
    .catch(err => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
      //console.log(err);
    });
};

// Set Cue
export const setCue = cue => {
  return {
    type: SET_CURRENT_CUE_DATA,
    payload: cue
  };
};

//////////////////////////////////////////
// API actions not routed through redux //
//////////////////////////////////////////

// empty

///////////////////////////////////////////////////
//// Local actions that don't contact the API  ////
///////////////////////////////////////////////////

export const localAuth = (token = null) => dispatch => {
  let token = getLS('token');

  if (token) {
    let decoded = jwtDecode(token);
    if (decoded) {
      if (Math.round(Date.now() / 1000) < decoded.exp) {
        // All good, token hasn't expired
        return true;
      } else {
        // Token expired, clear user and LS
        dispatch({
          type: CLEAR_CURRENT_USER
        });
        logoutLS();
        return false;
      }
    } else {
      // Token couldn't be decoded, clear user and LS
      dispatch({
        type: CLEAR_CURRENT_USER
      });
      logoutLS();
      return false;
    }
  } else {
    // Token wasn't found, clear user and LS
    dispatch({
      type: CLEAR_CURRENT_USER
    });
    logoutLS();
    return false;
  }
};

// Validate local token (Bool)
// Sets Header and returns true or returns false
export const verifyLocalToken = () => {
  let token = getLS('token');

  if (token) {
    let decoded = jwtDecode(token);
    if (decoded) {
      if (Math.round(Date.now() / 1000) < decoded.exp) {
        setAuthHeader(token);
        return true;
      } else {
        // Token expired
        return false;
      }
    } else {
      // Token couldn't be decoded
      return false;
    }
  } else {
    // Token wasn't found
    return false;
  }
};

export const setActiveItem = item => dispatch => {
  dispatch(setActiveMediaItem(item));
};

export const setActiveMediaItem = item => {
  return {
    type: SET_ACTIVE_MEDIA_ITEM,
    payload: item
  };
};
