import React, { Component } from 'react';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import alertify from 'alertifyjs';
import Loader from '../common/Loader';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 2;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 5,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'rgb(50, 50, 50)' : 'rgb(27, 27, 27)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '' : '',
  minHeight: '49vh'
});

class CreatePlaylist extends Component {
  _isMounted = false;
  state = {
    items: [],
    selected: [],
    title: ''
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/media/all')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              items: res.data.media
            });
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                err.response.data
            );
          });
      }
    }
  };

  id2List = {
    medialist: 'items',
    playlist: 'selected'
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'playlist') {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.medialist,
        selected: result.playlist
      });

      //this.calculateDuration();
    }
  };

  componentDidUpdate() {
    this.calculateDuration();
  }

  calculateDuration = () => {
    let total = '';
    if (this.state.selected[0]) {
      let arr = this.state.selected;
      let arrayLength = this.state.selected.length;
      for (var i = 0; i < arrayLength; i++) {
        total = parseInt(total + arr[i].seconds);
      }
    }
    let duration = document.getElementById('t-duration');
    duration.innerText = this.formatTime(total);
  };

  formatTime = n => {
    let hrs = Math.floor(n / 3600);
    let mins = Math.floor((n - hrs * 3600) / 60);
    let secs = n - (hrs * 3600 + mins * 60);
    if (hrs < 10) {
      hrs = '0' + hrs;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }
    if (secs < 10) {
      secs = '0' + secs;
    }
    return hrs + ':' + mins + ':' + secs;
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  savePlaylist = () => {
    if (this._isMounted) {
      if (!this.state.selected.length) {
        alertify.error(
          '<i class="fas fa-exclamation-triangle"></i> Error: Nothing to save!'
        );
        return;
      }

      let ids = this.state.selected.map(a => a.id);
      let data = new FormData();
      data.append('title', this.state.title.trim());
      data.append('array', ids);

      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/playlist/save/created', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              selected: [],
              title: ''
            });
            alertify.alert(
              '<i class="fas fa-thumbs-up"></i> <b>AMPS</b>',
              '<b>Your playlist was successfully saved!</b><br><br>You can find it in listed under My Playlists.'
            );
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                err.response.data
            );
          });
      }
    }
  };

  render() {
    if (this.state.items.length > 0) {
      return (
        <div className="content">
          <h2 className="cpl">Create a playlist</h2>
          <div className="cpl-header">
            <div className="left">
              <p>
                <span className="icon">
                  <i className="fas fa-arrow-right" />
                </span>
                Drag and drop items from the left box to the right to assemble
                your playlist.
              </p>
              <p>
                <span className="icon">
                  <i className="fas fa-arrows-alt-v" />
                </span>
                Change the order of the items in your playlist by moving them up
                and down.
              </p>
              <p>
                <span className="icon">
                  <i className="fas fa-arrow-left" />
                </span>
                Remove items from the playlist by dragging them back over to the
                left box.
              </p>
              <p>
                <span className="icon">
                  <i className="far fa-save" />
                </span>
                When you're finished name and save your playlist on the right.
              </p>
            </div>
            <div className="right">
              <div className="save">
                <input
                  name="title"
                  value={this.state.title}
                  onChange={e => this.onChange(e)}
                  placeholder="name your playlist"
                />
                <button className="btn-save" onClick={this.savePlaylist}>
                  SAVE
                </button>
              </div>
              <div className="total-duration">
                <i className="far fa-clock" /> Playlist Duration:{' '}
                <b>
                  <span id="t-duration" />
                </b>
              </div>
            </div>
          </div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="create-pl-wrap">
              <div className="create-medialist">
                <Droppable droppableId="medialist">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="list-item">
                                <div className="type">
                                  {item.type === 'track' ? (
                                    <i className="fas fa-music" />
                                  ) : (
                                    <i className="fas fa-microphone-alt" />
                                  )}
                                </div>
                                <div className="meta">
                                  <div className="title">{item.title}</div>
                                  <div className="artist">{item.artist}</div>
                                </div>
                                <div className="stamps">
                                  <div className="duration">
                                    {item.duration}
                                  </div>
                                  <div className="time">{item.time_ago}</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>

              <div className="create-playlist">
                <Droppable droppableId="playlist">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.selected.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="list-item">
                                <div className="position">
                                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                                </div>
                                <div className="type">
                                  {item.type === 'track' ? (
                                    <i className="fas fa-music" />
                                  ) : (
                                    <i className="fas fa-microphone-alt" />
                                  )}
                                </div>
                                <div className="meta">
                                  <div className="title">{item.title}</div>
                                  <div className="artist">{item.artist}</div>
                                </div>
                                <div className="stamps">
                                  <div className="duration">
                                    {item.duration}
                                  </div>
                                  <div className="time">{item.time_ago}</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        </div>
      );
    }
    return <Loader />;
  }
}

export default CreatePlaylist;
