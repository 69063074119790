import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI, AVATAR_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import UserModal from './UserModal';
import alertify from 'alertifyjs';

class UsersList extends Component {
  _isMounted = false;
  state = {
    editUser: {},
    modal: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  editUser = id => {
    for (var i = 0; i < this.props.users.length; i++) {
      if (this.props.users[i].id === id) {
        this.setState({
          editUser: this.props.users[i],
          modal: true
        });
      }
    }
  };

  closeModal = () => {
    this.setState({
      user: {},
      modal: false
    });
  };

  saveUser = obj => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        let data = new FormData();
        data.append('id', obj.id);
        data.append('username', obj.username);
        data.append('display_name', obj.display_name);
        data.append('email', obj.email);
        data.append('password', obj.password);
        data.append('can_admin', obj.can_admin);
        data.append('delete_user', obj.delete_user);
        data.append('delete_media', obj.delete_media);

        setAuthHeader(token);
        axios
          .post(API_URI + '/users/update', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              user: {},
              modal: false
            });
            if (obj.delete_user) {
              alertify.success(
                '<i class="far fa-check-circle"></i> User was deleted!'
              );
            } else {
              alertify.success(
                '<i class="far fa-check-circle"></i> User was updated!'
              );
            }

            this.props.reload();
          })
          .catch(err => {
            this.setState({
              user: {},
              modal: false
            });
            alertify
              .alert(
                '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                  err.response.data
              )
              .setHeader(
                '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
              );
          });
      }
    }
  };

  render() {
    const userList = this.props.users.map(user => (
      <tr
        key={user.id}
        className={`single-user ${user.can_admin ? 'admin' : ''}${
          user.ghost ? 'ghost' : ''
        }`}
        onClick={() => this.editUser(user.id)}
      >
        <td className="tb-avatar">
          <img
            src={AVATAR_URI + '/' + user.avatar}
            alt={user.name}
            width="25"
            height="25"
          />
        </td>
        <td>{user.display_name}</td>
        <td>{user.username}</td>
        <td>{user.last_upload}</td>
        <td>{user.last_login}</td>
        <td>{user.last_seen}</td>
        <td className="last">
          {user.ghost ? (
            <i className="fas fa-check-circle" />
          ) : (
            <i className="far fa-times-circle" />
          )}
        </td>
        <td className="last">
          {user.can_admin ? (
            <i className="fas fa-check-circle" />
          ) : (
            <i className="far fa-times-circle" />
          )}
        </td>
      </tr>
    ));
    return (
      <div>
        <div className="usersList">
          <table>
            <tbody>
              <tr>
                <th>AVATAR</th>
                <th>DISPLAY NAME</th>
                <th>USERNAME</th>
                <th>LATEST UPLOAD</th>
                <th>LAST LOGIN</th>
                <th>LAST SEEN</th>
                <th className="last">GHOST</th>
                <th className="last">ADMIN</th>
              </tr>
              {userList}
            </tbody>
          </table>
        </div>
        {this.state.modal ? (
          <UserModal
            user={this.state.editUser}
            close={this.closeModal}
            save={this.saveUser}
            reload={this.props.reload}
          />
        ) : null}
      </div>
    );
  }
}

UsersList.propTypes = {
  users: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired
};

export default UsersList;
