import React, { Component } from 'react';
import PropTypes from 'prop-types';

class UploadBtn extends Component {
  onClick = () => {
    document.querySelector('#upload-file').click();
  };

  render() {
    return (
      <div id="upload-choose-container">
        <input
          type="file"
          id="upload-file"
          name="file"
          accept="audio/*"
          onChange={this.props.changeFile}
        />
        <button
          className="btn btn-upload"
          id="choose-upload-button"
          onClick={this.onClick}
        >
          <i className="fas fa-upload" /> Upload a new media file
        </button>
      </div>
    );
  }
}

UploadBtn.propTypes = {
  changeFile: PropTypes.func.isRequired
};

export default UploadBtn;
