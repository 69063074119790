import React, { Component } from 'react';

class CurrentScheduleCueEmpty extends Component {
  render() {
    return (
      <div className="current-schedule-cue">
        <div className="c-top">
          <div className={`onair`}>NOW PLAYING</div>
          <div className="meta">
            <div className="top">
              <div>
                <b>NOTHING SCHEDULED</b>
              </div>
            </div>
            <div className="cd-progress-wrap">
              <span id="cd-progress" />
            </div>
          </div>
        </div>
        <div className="c-bottom">
          <div className="cl-block">
            <div className="clock">
              <i className="far fa-clock" />
            </div>
          </div>
          <div className="elapsed">
            <div className="heading">ELAPSED</div>
            <div id="e-countdown">00:00:00</div>
          </div>
          <div className="remaining">
            <div className="heading">REMAINING</div>
            <div id="countdown">00:00:00</div>
          </div>
          <div className="up-next">
            <div className="heading2">SCHEDULED NEXT</div>
            <div className="up-next-txt">
              <b>NOTHING SCHEDULED</b>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CurrentScheduleCueEmpty;
