import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getLS, logoutLS } from '../../functions/handleLocalStorage';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import setAuthHeader from '../../functions/setAuthHeader';

class UserMenu extends Component {
  _isMounted = false;
  constructor() {
    super();

    this.state = {
      showMenu: false,
      active: false
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showMenu(e) {
    e.preventDefault();

    this.setState({ showMenu: true, active: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu() {
    this.setState({ showMenu: false, active: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  userLogout = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/logout')
          .then(res => {
            this.logout();
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  logout = () => {
    logoutLS();
    window.location.href = '/';
  };

  render() {
    return (
      <div className="usermenu">
        <button
          className={this.state.active ? 'user-btn active' : 'user-btn'}
          onClick={this.showMenu}
        >
          {this.props.user.display_name} <i className="fas fa-caret-down" />
        </button>

        {this.state.showMenu ? (
          <div className="usermenu-content">
            <div className="usermenu-list-wrap">
              <div className="usermenu-list">
                <ul>
                  <Link to="/my_account">My Account</Link>
                  <Link to="/my_playlists">My Playlists</Link>
                  <Link to="/my_media">My Media</Link>
                  <li onClick={this.userLogout}>Logout</li>
                </ul>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

UserMenu.propTypes = {
  user: PropTypes.object.isRequired
};

export default UserMenu;
