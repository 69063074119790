import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AVATAR_URI } from '../../globals.js';
import Loader from '../common/Loader';

class Activity extends Component {
  render() {
    let url = AVATAR_URI + '/';
    let data = this.props.data;
    let activityList;
    let processingList;
    let userList;

    if (data.processing) {
      if (data.processing.length === 0) {
        processingList =
          'Queue is empty, nothing is being processed right now!';
      } else {
        processingList = data.processing.map((item, index) => (
          <li
            key={`p-${index}`}
            className={index === 0 ? 'process-item current' : 'process-item'}
          >
            <div className="icon-ul">
              <i
                className={
                  index === 0 ? 'fas fa-cog fa-spin' : 'fas fa-calendar-alt'
                }
              />
            </div>
            <div className="msg">
              {index === 0 ? 'Currently processing: ' : ''}
              {index === 1 ? 'Next: ' : ''}
              <span className="hl">{item.title}</span> uploaded by{' '}
              <span className="hl">
                <Link to={`/user/${item.username}`}>{item.user}</Link>
              </span>
              <span className="time_ago">
                <i className="far fa-clock" /> {item.time_ago}
              </span>
            </div>
          </li>
        ));
      }
    }

    if (this.props.data.activity) {
      activityList = data.activity.map((item, index) => (
        <li key={`a-${index}`} className="activity-item">
          <div className="icon-ul">
            {item.type === 'media' ? (
              <i className="fas fa-headphones-alt" />
            ) : (
              <i className="fas fa-clipboard-list" />
            )}
          </div>
          <div className="msg">
            <span className="hl">
              <Link to={`/user/${item.username}`}>{item.user}</Link>
            </span>{' '}
            {item.type === 'media' ? 'uploaded' : 'created'}{' '}
            {item.type === 'media' ? (
              <Link to={`/media/${item.mid}`}>{item.title}</Link>
            ) : (
              <Link to={`/playlist/${item.mid}`}>{item.title}</Link>
            )}
            <span className="time_ago">
              <i className="far fa-clock" /> {item.time_ago}
            </span>
          </div>
        </li>
      ));
    }

    if (this.props.data.online) {
      userList = data.online.map((item, index) => (
        <Link
          to={`/user/${item.username}`}
          key={`u-${index}`}
          className="user-item"
        >
          <img src={url + item.avatar} alt={item.name} width="80" height="80" />
          <div className="username">{item.name}</div>
        </Link>
      ));
    }

    if (this.props.data.activity) {
      return (
        <div className="home-wrap">
          <div className="h-left">
            <div className="home-processing">
              <div className="big-icon">
                <i className="fas fa-microchip" />
              </div>
              <h2>Media Processing Queue</h2>
              <ul>{processingList}</ul>
            </div>

            <div className="home-activity">
              <div className="big-icon">
                <i className="fas fa-sitemap" />
              </div>

              <h2>Recent Activity</h2>
              <ul>{activityList}</ul>
            </div>
          </div>

          <div className="h-right">
            <div className="home-users">
              <div className="big-icon">
                <i className="fas fa-users" />
              </div>

              <h2>Who's In The House?</h2>
              <div className="onlineUserList">{userList}</div>
            </div>
          </div>
        </div>
      );
    }
    return <Loader />;
  }
}

Activity.propTypes = {
  data: PropTypes.object.isRequired
};

export default Activity;
