import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import Loader from '../../components/common/Loader';
import Error404 from '../../components/common/Error404';
import alertify from 'alertifyjs';

class ServiceControl extends Component {
  _isMounted = false;
  state = {
    data: false,
    selectedPlaylist: '',
    action: '',
    error: ''
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/playlists/all')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.status });
          });
      }
    }
  };

  onSelectPlaylist = e => {
    this.setState({
      selectedPlaylist: e.target.value
    });
  };

  submitCheck = e => {
    let action = e.target.value;
    this.setState({
      action: e.target.value
    });

    let msg = '';

    if (action === 'all') {
      if (this.state.selectedPlaylist === '') {
        alertify
          .alert(
            '<i class="fas fa-exclamation-triangle"></i> Error: You need to specify a playlist to restart Ices with!'
          )
          .setHeader(
            '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
          );
        return;
      }
      msg =
        'You are about to reboot all audio systems! This will disconnect everybody from all Icecast mount points and restart the audio services with your selected playlist.';
    }
    if (action === 'startIcecast') {
      msg = 'Are you sure you want to start Icecast?';
    }
    if (action === 'stopIcecast') {
      msg = 'Are you sure you want to stop Icecast?';
    }
    if (action === 'restartIcecast') {
      msg = 'Are you sure you want to restart Icecast?';
    }
    if (action === 'reloadIcecast') {
      msg = 'Are you sure you want to reload the current Icecast config?';
    }
    if (action === 'forceReloadIcecast') {
      msg = 'Are you sure you want to force reload the current Icecast config?';
    }
    if (action === 'startLiquidsoap') {
      msg = 'Are you sure you want to start Liquidsoap?';
    }
    if (action === 'stopLiquidsoap') {
      msg = 'Are you sure you want to stop Liquidsoap?';
    }
    if (action === 'restartLiquidsoap') {
      msg = 'Are you sure you want to restart Liquidsoap?';
    }
    if (action === 'forceReloadLiquidsoap') {
      msg =
        'Are you sure you want to force reload the current Liquidsoap config?';
    }
    if (action === 'startIces') {
      if (this.state.selectedPlaylist === '') {
        alertify
          .alert(
            '<i class="fas fa-exclamation-triangle"></i> Error: You need to specify a playlist to restart Ices with!'
          )
          .setHeader(
            '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
          );
        return;
      }
      msg = 'Are you sure you want to start Ices?';
    }
    if (action === 'stopIces') {
      msg = 'Are you sure you want to stop Ices?';
    }
    if (action === 'restartIces') {
      if (this.state.selectedPlaylist === '') {
        alertify
          .alert(
            '<i class="fas fa-exclamation-triangle"></i> Error: You need to specify a playlist to restart Ices with!'
          )
          .setHeader(
            '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
          );
        return;
      }
      msg = 'Are you sure you want to restart Ices?';
    }

    let self = this;
    alertify
      .confirm(
        msg,
        function() {
          self.submit();
        },
        function() {
          alertify.error(
            '<i class="fas fa-info-circle"></i> Action cancelled!'
          );
        }
      )
      .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
  };

  submit = () => {
    if (this._isMounted) {
      let token = getLS('token');

      let data = new FormData();
      data.append('action', this.state.action);
      data.append('playlist', this.state.selectedPlaylist);

      if (token) {
        setAuthHeader(token);

        axios
          .post(API_URI + '/settings/services', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            alertify.success('<i class="far fa-check-circle"></i> ' + res.data);
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i>' + err.response.data
            );
          });
      }
    }
  };

  render() {
    if (this.props.auth.isAuthenticated && this.props.auth.user.can_admin) {
      if (this.state.data.playlists) {
        const playlists = this.state.data.playlists.map(playlist => {
          return (
            <option key={playlist.id} value={playlist.id}>
              {playlist.title} - {playlist.owner} ({playlist.duration})
            </option>
          );
        });
        return (
          <div className="content">
            <div className="djm-wrap">
              <h2>Service Control</h2>
              <div className="reboot-heading">REBOOT ALL AUDIO SERVICES</div>
              <div className="reboot-options">
                <select
                  className="sel-srt"
                  value={this.state.selectedPlaylist}
                  onChange={this.onSelectPlaylist}
                >
                  <option value="">Restart Ices with...</option>
                  <option value="current">The current playlist</option>
                  <option value="trimmedShow">The current show with trimmed audio</option>
                  <option value="scheduled">
                    The currently scheduled DJ / Slot
                  </option>
                  <option value="auto">A new auto generated playlist</option>
                  {playlists}
                </select>

                <button
                  className="btn blue-btn"
                  value="all"
                  onClick={this.submitCheck}
                >
                  Reboot All Audio Services
                </button>
              </div>

              <div className="reboot-heading">ICECAST</div>

              <div className="reboot-options">
                <button
                  className="btn blue-btn"
                  value="startIcecast"
                  onClick={this.submitCheck}
                >
                  Start Icecast
                </button>
                <button
                  className="btn blue-btn"
                  value="stopIcecast"
                  onClick={this.submitCheck}
                >
                  Stop Icecast
                </button>
                <button
                  className="btn blue-btn"
                  value="restartIcecast"
                  onClick={this.submitCheck}
                >
                  Restart Icecast
                </button>
                <button
                  className="btn blue-btn"
                  value="reloadIcecast"
                  onClick={this.submitCheck}
                >
                  Reload Config
                </button>
                <button
                  className="btn blue-btn"
                  value="forceReloadIcecast"
                  onClick={this.submitCheck}
                >
                  Force Reload Config
                </button>
              </div>

              <div className="reboot-heading">LIQUIDSOAP</div>

              <div className="reboot-options">
                <button
                  className="btn blue-btn"
                  value="startLiquidsoap"
                  onClick={this.submitCheck}
                >
                  Start Liquidsoap
                </button>
                <button
                  className="btn blue-btn"
                  value="stopLiquidsoap"
                  onClick={this.submitCheck}
                >
                  Stop Liquidsoap
                </button>
                <button
                  className="btn blue-btn"
                  value="restartLiquidsoap"
                  onClick={this.submitCheck}
                >
                  Restart Liquidsoap
                </button>
                <button
                  className="btn blue-btn"
                  value="forceReloadLiquidsoap"
                  onClick={this.submitCheck}
                >
                  Force Reload Config
                </button>
              </div>

              <div className="reboot-heading">ICES</div>

              <div className="reboot-options">
                <button
                  className="btn blue-btn"
                  value="startIces"
                  onClick={this.submitCheck}
                >
                  Start Ices
                </button>
                <button
                  className="btn blue-btn"
                  value="stopIces"
                  onClick={this.submitCheck}
                >
                  Stop Ices
                </button>
                <button
                  className="btn blue-btn"
                  value="restartIces"
                  onClick={this.submitCheck}
                >
                  Restart Ices
                </button>
              </div>
            </div>
          </div>
        );
      }
      return <Loader />;
    }
    return <Error404 />;
  }
}

ServiceControl.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(ServiceControl);
