import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import alertify from 'alertifyjs';
import { getLS } from '../../../functions/handleLocalStorage';

class LoginAsUser extends Component {
  state = {
    userId: '',
    username: ''
  };

  onSelectUser = e => {
    let index = e.target.selectedIndex;
    this.setState({
      userId: e.target.value,
      username: e.target[index].text
    });
  };

  submitCheck = () => {
    let self = this;
    let user = this.state.username;
    alertify
      .confirm(
        'Are you sure that you want to login as <b>' + user + '</b>?',
        function() {
          self.submit();
        },
        function() {
          alertify.error(
            '<i class="fas fa-info-circle"></i> Action cancelled!'
          );
        }
      )
      .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
  };

  submit = () => {
    this.props.login(this.state.userId);
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.props.users.length > 0
    ) {
      const userlist = this.props.users.map(user => {
        if (JSON.parse(getLS('user')).id === user.id) {
          return null;
        }
        return (
          <option key={user.id} value={user.id} id={user.display_name}>
            {user.display_name} {user.ghost ? '(Ghost Account)' : ''}
          </option>
        );
      });
      return (
        <div className="login-as">
          <select
            className="sel-srt"
            value={this.state.userId}
            onChange={this.onSelectUser}
          >
            <option value="">Login as...</option>
            {userlist}
          </select>
          <button className="btn blue-btn" onClick={this.submitCheck}>
            LOGIN AS THIS USER
          </button>
        </div>
      );
    }
    return null;
  }
}

LoginAsUser.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  login: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(LoginAsUser);
