import React, { Component } from 'react';
import alertify from 'alertifyjs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import {
  API_URI,
  MAX_UPLOAD_SIZE,
  ALLOWED_AUDIO_FORMATS
} from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import UploadBtn from './UploadBtn';
import UploadDisplay from './UploadDisplay';

axiosCancel(axios, {
  debug: false
});

class UploadIndex extends Component {
  _isMounted = false;
  source = null;
  timer = null;
  reqId = Math.floor(Math.random() * 200) + 1;
  state = {
    options: {},
    artist: '',
    title: '',
    type: 'show',
    init: true,
    display: false,
    uploading: false,
    progress: { loaded: 0, total: 0 },
    timeRemaining: 0
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.loadOptions, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timer);
  }

  loadOptions = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/upload/options')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              options: res.data,
              show_name: res.data.shows.title
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChangeFile = e => {
    let file = e.target.files[0];

    // let self = this;

    if (file) {
      // if (!file.type.match('audio.*')) {
      //   if (file.type === 'video/x-ms-wma') {
      //     // allow stupid microsoft format
      //   } else {
      //     alertify
      //       .alert(
      //         '<i class="fas fa-exclamation-triangle"></i> <b>Error</b> : Must be an audio file!'
      //       )
      //       .setHeader(
      //         '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
      //       );
      //     self.clearFileInput(document.getElementById('upload-file'));
      //     return;
      //   }
      // }

      let n = file.type.lastIndexOf('/');
      let ext = file.type.substring(n + 1);

      // console.log(ext)

      if (!ALLOWED_AUDIO_FORMATS.includes(ext)) {
        alertify
          .alert(
            '<i class="fas fa-exclamation-triangle"></i> <b>Error</b> : Audio format not supported!<br /><br />Supported formats : <b>' +
              ALLOWED_AUDIO_FORMATS +
              '</b>'
          )
          .setHeader(
            '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
          );
        this.clearFileInput(document.getElementById('upload-file'));
        return;
      }

      if (file.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
        alertify
          .alert(
            '<i class="fas fa-exclamation-triangle"></i> <b>Error</b> : Maximum upload file size is <b>' +
              MAX_UPLOAD_SIZE +
              'mb</b>!'
          )
          .setHeader(
            '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
          );
        this.clearFileInput(document.getElementById('upload-file'));
        return;
      }
      this.setState({
        artist: '',
        title: '',
        file: file,
        init: false,
        display: true,
        uploading: false
      });
    }
  };

  onChangeType = e => {
    this.setState({
      type: e.target.id
    });
  };

  onSelectShow = e => {
    this.setState({
      showName: e.target.options[e.target.selectedIndex].text.trim()
    });
  };

  cancel = () => {
    if (this.state.progress.loaded > 0) {
      let self = this;
      alertify
        .confirm(
          '<b>Are you sure you want to cancel this upload?</b>',
          function() {
            self.cancelUpload();
          }
        )
        .set({ labels: { ok: 'YES', cancel: 'NO' } })
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    } else {
      this.cancelUpload();
    }
  };

  cancelUpload = () => {
    axios.cancel(this.reqId);
    this.setState({
      artist: '',
      title: '',
      type: 'show',
      showName: '',
      file: '',
      init: true,
      display: false,
      uploading: false,
      uploadStarted: {}
    });
    if (document.getElementById('upload-file')) {
      this.clearFileInput(document.getElementById('upload-file'));
    }
  };

  clearFileInput = node => {
    try {
      node.value = null;
    } catch (ex) {}
    if (node.value) {
      node.parentNode.replaceChild(node.cloneNode(true), node);
    }
  };

  jingleConfirm = () => {
    if (
      this.state.type === 'jingle' &&
      this.state.file.size >= 2 * 1024 * 1024
    ) {
      let self = this;
      alertify
        .confirm(
          '<b>You are about to upload a Jingle!</b><br /><br />Please note that jingles cannot be longer than 1 minute in length. If the file you are uploading is longer it will be rejected. <br /><br />Click <b>OK</b> to continue or <b>SWITCH</b> to change the upload type.',
          function() {
            self.upload();
          }
        )
        .set('labels', { ok: 'OK', cancel: 'SWITCH' })
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    } else {
      this.upload();
    }
  };

  upload = () => {
    if (this._isMounted) {
      let token = getLS('token');
      if (token) {
        let data = new FormData();
        if (this.state.type === 'show') {
          data.append('title', this.state.showName);
        } else {
          data.append('title', this.state.title);
          data.append('artist', this.state.artist);
        }

        data.append('file', this.state.file);
        data.append('type', this.state.type);

        this.reqId = Math.floor(Math.random() * 200) + 1;

        const config = {
          onUploadProgress: progressEvent =>
            this.setState({
              progress: {
                loaded: progressEvent.loaded,
                total: progressEvent.total
              }
            }),
          requestId: this.reqId
        };

        this.setState({
          uploading: true,
          uploadStarted: new Date()
        });

        this.timer = setInterval(this.timeRemaining, 1000);

        setAuthHeader(token);

        let self = this;

        axios
          .post(API_URI + '/upload', data, config)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              artist: '',
              title: '',
              type: 'show',
              init: true,
              display: false,
              uploading: false,
              progress: { loaded: 0, total: 0 },
              timeRemaining: 0
            });
            clearInterval(this.timer);
            this.clearFileInput(document.getElementById('upload-file'));
            alertify.alert(
              '<i class="fas fa-check-circle"></i> <b>AMPS</b>',
              '<b>Your file was successfully uploaded!</b><br><br>It will now be added to the processing queue. Once completed you will receive a notification letting you know that the file has been successfully added to the system.'
            );
          })
          .catch(err => {
            clearInterval(this.timer);
            if (axios.isCancel(err)) {
              alertify.error(
                '<i class="far fa-trash-alt"></i> Upload cancelled!'
              );
              self.clearFileInput(document.getElementById('upload-file'));
              return;
            }
            if (err) {
              alertify.alert(
                '<i class="fas fa-times-circle" style="color:#d45959;"></i> <b>AMPS Error!</b>',
                '<div class="error-icon"><i class="fas fa-exclamation-triangle"></i></div><b><center>File upload failed!</b><br /><br />' +
                  err.response.data +
                  '</center>'
              );
              self.cancelUpload();
            }
          });
      }
    }
  };

  timeRemaining = () => {
    if (this._isMounted && this.state.uploadStarted && this.state.progress) {
      let started = this.state.uploadStarted;
      let elapsed = new Date() - started;
      let uploadSpeed = this.state.progress.loaded / (elapsed / 1000);
      this.setState({
        timeRemaining: Math.round(
          (this.state.progress.total - this.state.progress.loaded) / uploadSpeed
        )
      });
    }
  };

  render() {
    if (this.props.auth.isAuthenticated && !this.props.auth.maintenance) {
      return (
        <div id="container">
          <div id="upload-container">
            {this.state.init ? (
              <UploadBtn changeFile={this.onChangeFile} />
            ) : null}
            {this.state.display ? (
              <UploadDisplay
                filename={this.state.file.name}
                changeType={this.onChangeType}
                cancel={this.cancel}
                options={this.state.options}
                upload={this.jingleConfirm}
                uploading={this.state.uploading}
                type={this.state.type}
                onChange={this.onChange}
                onSelectShow={this.onSelectShow}
                progress={this.state.progress}
                timeRemaining={this.state.timeRemaining}
              />
            ) : null}
          </div>
        </div>
      );
    }
    return null;
  }
}

UploadIndex.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(UploadIndex);
