import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

class SignalSwitch extends Component {
  render() {
    if (this.props.sys.icecast.source) {
      const source = this.props.sys.icecast.source;

      return (
        <div className="switch-box">
          <div className="source-head">SOURCE</div>
          {source === 'ices' ? (
            <div
              className="source-box s-ices"
              data-tip="Scheduled content is now playing"
            >
              ICES
            </div>
          ) : (
            <div
              className="source-box"
              data-tip="Fallback file active in case on live connection drop outs"
            >
              ICES
            </div>
          )}

          {source === 'live' ? (
            <div
              className="source-box-2 s-live"
              data-tip="DJ is connected and broadcasting live"
            >
              LIVE
            </div>
          ) : (
            <div
              className="source-box-2"
              data-tip="No live connection currently"
            >
              LIVE
            </div>
          )}

          <ReactTooltip />
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  sys: state.sys
});

export default connect(mapStateToProps)(SignalSwitch);
