import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

class SettingsOptions extends Component {
  state = {
    settings: this.props.data.settings,
    bitrate: this.props.data.settings.encoder_bitrate,
    inject: this.props.data.settings.auto_playlist_inject,
    bpm: this.props.data.settings.bpm,
    activity: this.props.data.settings.activity_items,
    chat: this.props.data.settings.chat_items,
    shows: this.props.data.settings.max_shows,
    repeats: this.props.data.settings.limit_repeat_shows,
    tags: this.props.data.settings.id3_tags
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSelect = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value)
    });
  };

  updateBtn = () => {
    this.props.update(this.state);
  };

  render() {
    if (this.state.settings) {
      return (
        <div className="ice-settings">
          <div className="ice-heading">OPTIONS</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">
                MP3 BITRATE{' '}
                <i
                  className="fas fa-info-circle"
                  data-tip="The MP3 compression bitrate for the entire system"
                  data-effect="float"
                  data-type="light"
                />
              </div>
              <select
                name="bitrate"
                value={this.state.bitrate}
                onChange={this.onSelect}
              >
                <option key="1" value="24">
                  24k
                </option>
                <option key="2" value="32">
                  32k
                </option>
                <option key="3" value="32">
                  48k
                </option>
                <option key="4" value="64">
                  64k
                </option>
                <option key="5" value="96">
                  96k
                </option>
                <option key="6" value="128">
                  128k
                </option>
                <option key="7" value="160">
                  160k
                </option>
                <option key="8" value="192">
                  192k
                </option>
                <option key="9" value="224">
                  224k
                </option>
                <option key="10" value="256">
                  256k
                </option>
                <option key="11" value="320">
                  320k
                </option>
              </select>
            </div>
            <div className="cell">
              <div className="title">
                AUTO INJECT{' '}
                <i
                  className="fas fa-info-circle"
                  data-tip="Inject jingles into Auto DJ created playlists"
                  data-effect="float"
                  data-type="light"
                />
              </div>
              <select
                name="inject"
                value={this.state.inject}
                onChange={this.onSelect}
              >
                <option key="1" value="0">
                  NO
                </option>
                <option key="2" value="1">
                  YES
                </option>
              </select>
            </div>

            <div className="cell">
              <div className="title">
                ENABLE ID3 TAGS{' '}
                <i
                  className="fas fa-info-circle"
                  data-tip="Enable automatic ID3 Tag rewriting on all uploaded and updated files"
                  data-effect="float"
                  data-type="light"
                />
              </div>
              <select
                name="tags"
                value={this.state.tags}
                onChange={this.onSelect}
              >
                <option key="1" value="0">
                  NO
                </option>
                <option key="2" value="1">
                  YES
                </option>
              </select>
            </div>

            <div className="cell">
              <div className="title">
                BPM DETECTION{' '}
                <i
                  className="fas fa-info-circle"
                  data-tip="<b>Automatic BPM Detection on uploaded media</b><br /><br /> On, Off or choose between 3 presets. <br /><br />If a preset is selected, only files where the duration falls between the specified length values will be detected (e.g longer than 1 minute, less than 15 minutes). <br /><i>Note: this is only calculated during the upload process. It can't be retroactively applied to all files.</i>"
                  data-html={true}
                  data-effect="float"
                  data-type="light"
                />
              </div>
              <select
                name="bpm"
                value={this.state.bpm}
                onChange={this.onSelect}
              >
                <option key="1" value="0|0|0|0">
                  OFF
                </option>
                <option key="2" value="1|0|0|0">
                  ON
                </option>
                <option key="3" value="1|0|5|1">
                  7" (1min - 5mins)
                </option>
                <option key="4" value="1|0|15|1">
                  12" (1min - 15mins)
                </option>
                <option key="5" value="1|0|59|1">
                  EP (1min - 59mins)
                </option>
              </select>
            </div>
            <div className="cell">
              <div className="title">
                ACTIVITY ITEMS{' '}
                <i
                  className="fas fa-info-circle"
                  data-tip="How many recent activity items to display on the home page"
                  data-effect="float"
                  data-type="light"
                />
              </div>
              <select
                name="activity"
                value={this.state.activity}
                onChange={this.onSelect}
              >
                <option key="1" value="10">
                  10
                </option>
                <option key="2" value="20">
                  20
                </option>
                <option key="3" value="30">
                  30
                </option>
                <option key="4" value="40">
                  40
                </option>
                <option key="5" value="50">
                  50
                </option>
              </select>
            </div>

            <div className="cell">
              <div className="title">
                CHATBOX MESSAGES{' '}
                <i
                  className="fas fa-info-circle"
                  data-tip="How many recent messages display in the chatbox"
                  data-effect="float"
                  data-type="light"
                />
              </div>
              <select
                name="chat"
                value={this.state.chat}
                onChange={this.onSelect}
              >
                <option key="1" value="10">
                  10
                </option>
                <option key="2" value="20">
                  20
                </option>
                <option key="3" value="30">
                  30
                </option>
                <option key="4" value="40">
                  40
                </option>
                <option key="5" value="50">
                  50
                </option>
                <option key="6" value="60">
                  60
                </option>
                <option key="7" value="70">
                  70
                </option>
                <option key="8" value="80">
                  80
                </option>
                <option key="9" value="90">
                  90
                </option>
                <option key="10" value="100">
                  100
                </option>
                <option key="11" value="150">
                  150
                </option>
                <option key="12" value="200">
                  200
                </option>
              </select>
            </div>

            <div className="cell">
              <div className="title">
                SHOWS TO KEEP{' '}
                <i
                  className="fas fa-info-circle"
                  data-tip="<b>How many shows to keep from each user on the system at any one time</b><br /><br />To preserve storage space when a user uploads a new show file, any existing files beyond the limit you set here will be automatically be deleted. <br /><br />The system always retains the newest files, deleting the oldest ones."
                  data-effect="float"
                  data-type="light"
                  data-html={true}
                />
              </div>
              <select
                name="shows"
                value={this.state.shows}
                onChange={this.onSelect}
              >
                <option key="1" value="1">
                  1
                </option>
                <option key="2" value="2">
                  2
                </option>
                <option key="3" value="3">
                  3
                </option>
                <option key="4" value="4">
                  4
                </option>
                <option key="5" value="5">
                  5
                </option>
                <option key="6" value="6">
                  6
                </option>
                <option key="7" value="7">
                  7
                </option>
                <option key="8" value="8">
                  8
                </option>
                <option key="9" value="9">
                  9
                </option>
                <option key="10" value="10">
                  10
                </option>
                <option key="11" value="100">
                  100
                </option>
                <option key="12" value="200">
                  200
                </option>
              </select>
            </div>

            <div className="cell">
              <div className="title">
                LIMIT SHOW REPEATS{' '}
                <i
                  className="fas fa-info-circle"
                  data-tip="<b>How many times should a show file be allowed to repeat?</b><br /><br />Select the amount of times any show file can be aired by the system before back up playout measures are implemented.<br /><br />Once this limit is reached the show file will be replaced by any other <i>tracks</i> that the user has uploaded or any <i>tracks</i> uploaded by any other user on the system depending on available media."
                  data-effect="float"
                  data-type="light"
                  data-html={true}
                />
              </div>
              <select
                name="repeats"
                value={this.state.repeats}
                onChange={this.onSelect}
              >
                <option key="1" value="1">
                  1
                </option>
                <option key="2" value="2">
                  2
                </option>
                <option key="3" value="3">
                  3
                </option>
                <option key="4" value="4">
                  4
                </option>
                <option key="5" value="5">
                  5
                </option>
                <option key="6" value="6">
                  6
                </option>
                <option key="7" value="7">
                  7
                </option>
                <option key="8" value="8">
                  8
                </option>
                <option key="9" value="9">
                  9
                </option>
                <option key="10" value="10">
                  10
                </option>
                <option key="11" value="100">
                  100
                </option>
              </select>
            </div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <button className="btn blue-btn" onClick={this.updateBtn}>
              UPDATE SETTINGS
            </button>
          </div>

          <div className="ice-heading">FUNCTIONS</div>
          <div className="ice-flex">
            <button className="btn blue-btn" onClick={this.props.deleteChat}>
              DELETE ALL CHATBOX MESSAGES
            </button>
          </div>

          <ReactTooltip />
        </div>
      );
    }
    return null;
  }
}

SettingsOptions.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  deleteChat: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(SettingsOptions);
