import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MyAccountFiles extends Component {
  render() {
    if (this.props.data) {
      const data = this.props.data;
      return (
        <div className="ac-files-wrap">
          <h2>My Files</h2>
          <div>
            Latest Show Upload:{' '}
            {data.latest_show.title ? (
              <span>
                <b>{data.latest_show.title}</b> <i className="far fa-clock" />{' '}
                {data.latest_show.time_ago}{' '}
                {!data.latest_show.online ? '(Processing)' : null}
              </span>
            ) : (
              'No show file exists'
            )}
          </div>

          <div>
            Latest Media Upload:{' '}
            {data.latest_upload.title ? (
              <span>
                <b>{data.latest_upload.title}</b> <i className="far fa-clock" />{' '}
                {data.latest_upload.time_ago}{' '}
                {!data.latest_upload.online ? '(Processing)' : null}
              </span>
            ) : (
              'N/A'
            )}
          </div>
          <div>Shows: {data.counts.shows ? data.counts.shows : 0}</div>
          <div>Tracks: {data.counts.tracks ? data.counts.tracks : 0}</div>
          <div>Jingles: {data.counts.jingles ? data.counts.jingles : 0}</div>
          <div>
            Playlists: {data.counts.playlists ? data.counts.playlists : 0}
          </div>
        </div>
      );
    }
    return null;
  }
}

MyAccountFiles.propTypes = {
  data: PropTypes.object.isRequired
};

export default MyAccountFiles;
