import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser, resetRequest } from '../../store/actions/authActions';
import ResetPassword from './ResetPassword';

class LoginIndex extends Component {
  state = {
    isAuthenticated: false,
    email: '',
    password: '',
    error: '',
    forgot: false
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.state.email && this.state.password) {
      var data = new FormData();
      data.append('email', this.state.email);
      data.append('password', this.state.password);
      this.props.loginUser(data);
    } else {
      return;
    }
  };

  onSubmitForgot = e => {
    e.preventDefault();
    if (this.state.email) {
      var data = new FormData();
      data.append('email', this.state.email);
      this.props.resetRequest(data);
      this.setState({
        forgot: false
      });
      // console.log('requesting password reset');
    } else {
      return;
    }
  };

  handleForgot = () => {
    this.setState({
      forgot: true
    });
  };

  render() {
    if (this.props.auth.request) {
      return <ResetPassword />;
    }
    if (this.state.forgot) {
      return (
        <div className="login-wrap">
          <form id="login" className="login-form">
            <div className="login-logo">
              <img src="/assets/images/amps_full.png" alt="logo" />
            </div>

            <input
              type="text"
              name="email"
              placeholder="username or email"
              value={this.state.name}
              onChange={this.onChange}
            />
            <button onClick={this.onSubmitForgot} className="btn blue">
              REQUEST PASSWORD RESET
            </button>
          </form>
        </div>
      );
    }
    return (
      <div className="login-wrap">
        <form id="login" className="login-form">
          <div className="login-logo">
            <img src="/assets/images/amps_full.png" alt="logo" />
          </div>

          <input
            type="text"
            name="email"
            placeholder="username or email"
            value={this.state.name}
            onChange={e => this.onChange(e)}
          />
          <input
            type="password"
            name="password"
            placeholder="password"
            value={this.state.password}
            onChange={this.onChange}
          />
          <button onClick={this.onSubmit} className="btn blue">
            LOGIN
          </button>

          <div className="forgot" onClick={this.handleForgot}>
            I forgot my shizzle!
          </div>
          {this.props.error ? (
            <div className="login-error">
              <i className="fas fa-exclamation-triangle" /> {this.props.error}
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

LoginIndex.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(
  mapStateToProps,
  { loginUser, resetRequest }
)(LoginIndex);
