import React, { Component } from 'react';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import HistoryList from './HistoryList';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import alertify from 'alertifyjs';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../common/Loader';

class HistoryIndex extends Component {
  _isMounted = false;
  nav = null;

  state = {
    data: {},
    startUnix: '',
    endUnix: '',
    opt: false,
    error: ''
  };

  componentDidMount() {
    this._isMounted = true;
    this.nav = document.getElementById(
      'nav-' + this.props.location.pathname.slice(1)
    );
    this.nav.classList.add('nav-active');
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.nav.classList.remove('nav-active');
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        let data = new FormData();
        if (this.state.opt) {
          if (this.state.startUnix && this.state.endUnix) {
            if (this.state.endUnix < this.state.startUnix) {
              alertify
                .alert(
                  '<i class="fas fa-exclamation-triangle"></i> <b>From</b> must either be the same as or <u>before</u> the <b>To</b> date!<br><br>If you want to return the history for a specific day only - set both of the selectors to the same date.'
                )
                .setHeader(
                  '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
                );
              this.setState({
                endDate: new Date(),
                endUnix: moment(new Date()).unix(),
                opt: false
              });
              return;
            }
          }
          data.append('start', this.state.startUnix);
          data.append('end', this.state.endUnix);
        }
        axios
          .post(API_URI + '/history', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            alertify
              .alert(
                '<div class="alertChar"><img src="/assets/images/chars/benkenobi.png" /></div><center>' +
                  err.response.data +
                  '</center>'
              )
              .setHeader('<i class="fas fa-ban"></i> <b>AMPS</b> ');
          });
      }
    }
  };

  handleChangeStart = date => {
    this.setState({
      startDate: date,
      startUnix: date ? moment(date).unix() : '',
      opt: true
    });
  };

  handleChangeEnd = date => {
    this.setState({
      endDate: date,
      endUnix: date ? moment(date).unix() : '',
      opt: true
    });
  };

  render() {
    if (this.state.data.history) {
      let data = this.state.data.history;
      return (
        <div className="content">
          <div className="history">
            <div className="history-sort">
              <span>Select a date range: </span>
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={this.state.startDate}
                onChange={this.handleChangeStart}
                isClearable={true}
                maxDate={new Date()}
                placeholderText="From Date"
              />
              <DatePicker
                dateFormat="yyyy/MM/dd"
                selected={this.state.endDate}
                onChange={this.handleChangeEnd}
                isClearable={true}
                maxDate={new Date()}
                placeholderText="To Date"
              />
              <button className="btn btn-history" onClick={this.loadData}>
                Filter!
              </button>
            </div>
            <HistoryList history={data} />
          </div>
        </div>
      );
    }
    return <Loader />;
  }
}

export default HistoryIndex;
