import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StatusServer extends Component {
  render() {
    if (this.props.server) {
      const stats = this.props.server;
      return (
        <div className="ice-settings">
          <div className="ice-heading">SERVER</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">OPERATING SYSTEM</div>
              <div className="readOnly">{stats.os}</div>
            </div>

            <div className="cell">
              <div className="title">CPU CORES</div>
              <div className="readOnly">{stats.cpu_cores_available}</div>
            </div>

            <div className="cell">
              <div className="title">CPU LOAD</div>
              <div className="readOnly">{stats.cpu_percent_used}%</div>
            </div>

            <div className="cell">
              <div className="title">RAM TOTAL</div>
              <div className="readOnly">{stats.ram_available_gb} GB</div>
            </div>

            <div className="cell">
              <div className="title">RAM USED</div>
              <div className="readOnly">
                <span>
                  {stats.ram_used_gb} GB{' '}
                  <i className="fas fa-angle-double-right" />{' '}
                  {stats.ram_percent_used}%
                </span>
              </div>
            </div>

            <div className="cell">
              <div className="title">HDD TOTAL</div>
              <div className="readOnly">{stats.hdd_total_gb} GB</div>
            </div>

            <div className="cell">
              <div className="title">HDD AVAILABLE</div>
              <div className="readOnly">{stats.hdd_available_gb} GB</div>
            </div>

            <div className="cell">
              <div className="title">UPTIME</div>
              <div className="readOnly">{stats.system_uptime}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

StatusServer.propTypes = {
  server: PropTypes.object.isRequired
};

export default StatusServer;
