import React, { Component } from 'react';

export default class Ghost extends Component {
  render() {
    return (
      <div id="ghost">
        <div className="ghost_ghost ghost_blinky">
          <div className="ghost_body">
            <div className="ghost_eyes">
              <div className="ghost_eye ghost_left-eye">
                <div className="ghost_pupil" />
              </div>
              <div className="ghost_eye ghost_right-eye">
                <div className="ghost_pupil" />
              </div>
            </div>
            <div className="ghost_body-lower">
              <div className="ghost_spikes">
                <div className="ghost_spike" />
                <div className="ghost_spike" />
                <div className="ghost_spike" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
