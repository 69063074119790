import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import User from './User';

class NavIndex extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.props.auth.isAuthenticated) {
      return (
        <div className="nav">
          <div className="nav-spacer-left" />

          <Link id="nav-home" className="nav-btn" to="/">
            Home
          </Link>

          <Link
            id="nav-current_playlist"
            className="nav-btn"
            to="/current_playlist"
          >
            Current Playlist
          </Link>

          <Link id="nav-schedule" className="nav-btn" to="/schedule">
            Schedule
          </Link>

          <div className="dropdown">
            <div className="nav-drop-btn">
              Media <i className="fas fa-caret-down" />
            </div>
            <div className="dropdown-content">
              <Link to="/shows">List Shows</Link>
              <Link to="/tracks">List Tracks</Link>
              <Link to="/jingles">List Jingles</Link>
            </div>
          </div>

          <div className="dropdown">
            <div className="nav-drop-btn">
              Playlists <i className="fas fa-caret-down" />
            </div>
            <div className="dropdown-content">
              <Link className="open-modal" to="/playlist/auto_create">
                Auto Create
              </Link>
              <Link to="/playlist/create">Create</Link>
              <Link to="/playlists">Browse All</Link>
            </div>
          </div>

          <Link id="nav-history" className="nav-btn" to="/history">
            History
          </Link>

          {this.props.auth.user.can_admin ? (
            <div className="dropdown">
              <div className="nav-drop-btn">
                Admin <i className="fas fa-caret-down" />
              </div>
              <div className="dropdown-content">
                <Link to="/admin/djs">Manage DJs / Slots</Link>
                <Link to="/admin/users">Manage Users</Link>
                <Link to="/admin/media">Manage Media</Link>
                <Link to="/admin/scheduling">Scheduling</Link>
                <Link to="/admin/settings">Settings</Link>
                <Link to="/admin/icecast">Icecast Config</Link>
                <Link to="/admin/system">System Report</Link>
                <Link to="/admin/services">Service Control</Link>
              </div>
            </div>
          ) : null}

          <div className="nav-spacer-right" />
          <div className="nav-user">
            <User />
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(NavIndex);
