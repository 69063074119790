import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ICECAST_SERVER, D3EP_LIVE_STREAM } from '../../globals.js';
import { connect } from 'react-redux';
import { setActiveItem } from '../../store/actions/authActions';
import PropTypes from 'prop-types';
import { API_URI } from '../../globals.js';
import Clock from './Clock';
import IcecastStatus from './IcecastStatus';

let d = new Date();

class PlayerIndex extends Component {
  _isMounted = false;
  player = null;
  spectrumBars = null;
  playBtn = null;
  muteBtn = null;
  buffering = null;
  streamError = null;
  streamLocalButton = null;
  streamLiveButton = null;
  playingNowPanel = null;
  playingNowLocalPanel = null;
  nowPlaying = null;
  nowPlayingLS = null;
  nowPlayingLocalMeta = null;
  localMediaIsPlaying = false;
  updateTime = null;
  barSize = 400;
  localProgressBar = null;
  localProgress = null;
  duration = null;
  currentTime = null;

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      duration: false,
      mid: false,
      switch: false,
      playing: false,
      activeMedia: {},
      type: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    /* Create and init Player */
    let auEl = document.createElement('audio');
    auEl.id = 'stream';
    auEl.onloadeddata = this.streamLoaded;
    auEl.preload = 'none';
    auEl.src = D3EP_LIVE_STREAM;
    document.getElementById('player').appendChild(auEl);
    /* Set objects */
    this.player = document.getElementById('stream');
    this.spectrumBars = document.getElementById('spectrum-bars');
    this.streamLocalBtn = document.getElementById('stream-local');
    this.streamLiveBtn = document.getElementById('stream-live');
    this.playBtn = document.getElementById('play-btn');
    this.muteBtn = document.getElementById('mute-btn');
    this.buffering = document.getElementById('buffering');
    this.streamError = document.getElementById('stream-error');
    this.streamLocalBtn = document.getElementById('stream-local');
    this.streamLiveBtn = document.getElementById('stream-live');
    this.playingNowPanel = document.getElementById('playingNow');
    this.playingNowLocalPanel = document.getElementById('playingNowLocal');
    this.nowPlaying = document.getElementById('nowPlaying');
    this.nowPlayingLS = document.getElementById('nowPlayingLS');
    this.nowPlayingLocalMeta = document.getElementById('nowPlayingLocalMeta');
    this.localProgressBar = document.getElementById('localProgressBar');
    this.localProgress = document.getElementById('localProgress');
    this.duration = document.getElementById('currentDuration');
    this.currentTime = document.getElementById('currentTime');
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.clearInterval(this.updateTime);
    this.localProgressBar.removeEventListener(
      'click',
      this.clickedProgressBar,
      false
    );
  }

  componentDidUpdate(prevState) {
    if (this._isMounted && this.state.media.id !== prevState.media.id) {
      // remove old media target if it exists
      let target = document.getElementById('mid-' + prevState.media.id);

      if (target) {
        target.classList.remove('media-active');
      }

      if (this.state.media.id) {
        // add active class to the new target
        target = document.getElementById('mid-' + this.state.media.id);
        target.classList.add('media-active');
      }

      this.loadMedia();
    }
  }

  loadMedia = () => {
    clearInterval(this.updateTime);
    if (this._isMounted && this.state.media.id) {
      this.spectrumBars.src = '/assets/images/spectrum-off.svg';
      this.playBtn.classList.remove('pulsate-css');
      this.playBtn.innerHTML = '<i class="fas fa-play-circle"></i>';
      this.player.pause();
      this.player.src = API_URI + '/media?id=' + this.state.media.id;
      this.player.load();
      this.player.play();
      this.updateTime = setInterval(this.update, 500);
      this.localMediaIsPlaying = true;
      this.setLocalMediaMeta();
      this.playerPlaying();
      this.localProgressBar.addEventListener(
        'click',
        this.clickedProgressBar,
        false
      );
    }
  };

  setLocalMediaMeta = () => {
    this.duration.innerHTML = this.state.media.duration;
    this.nowPlayingLocalMeta.innerHTML =
      this.state.media.title + ' - ' + this.state.media.artist;
  };

  clickedProgressBar = e => {
    if (!this.player.ended) {
      let mouseX = e.pageX - this.localProgressBar.offsetLeft;
      let newTime = (mouseX * this.player.duration) / this.barSize;

      this.player.currentTime = newTime;
      this.localProgress.style.width = mouseX + 'px';
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.media !== state.media) {
      return {
        media: props.media
      };
    }
    return null;
  }

  streamLoaded = () => {
    if (this.player.src.slice(0, 10) === D3EP_LIVE_STREAM.slice(0, 10)) {
      if (this._isMounted && this.state.media.id) {
        let target = document.getElementById('mid-' + this.state.media.id);
        if (target) {
          target.classList.remove('media-active');
        }
        this.props.setActiveItem({});
        this.localMediaIsPlaying = false;
      }

      this.setState({
        title: 'D3EP Radio Network',
        switch: 'onair',
        duration: false,
        playing: true
      });
      this.playerPlaying();
      this.activateLiveStream();
    } else if (this.player.src.slice(0, 10) === ICECAST_SERVER.slice(0, 10)) {
      if (this._isMounted && this.state.media.id) {
        let target = document.getElementById('mid-' + this.state.media.id);
        if (target) {
          target.classList.remove('media-active');
        }
        this.props.setActiveItem({});
        this.localMediaIsPlaying = false;
      }

      this.setState({
        title: 'AMPS Local Stream',
        switch: 'local',
        duration: false,
        playing: true
      });

      this.playerPlaying();
      this.activateLocalStream();
    } else {
      this.deactivateAllStreams();
      this.buffering.style.display = 'none';
      this.streamError.style.display = 'none';
      this.playingNowPanel.style.display = 'none';
      this.playingNowLocalPanel.style.display = 'block';
      this.localMediaIsPlaying = true;
      // console.log('local media file');
    }
  };

  playerPlaying = () => {
    this.buffering.style.display = 'none';
    this.spectrumBars.src = '/assets/images/spectrum-on.svg';
    this.playBtn.classList.remove('pulsate-css');
    this.playBtn.innerHTML = '<i class="fas fa-pause-circle"></i>';
  };

  activateLocalStream = () => {
    this.nowPlaying.style.display = 'block';
    this.nowPlayingLS.style.display = 'none';
    this.streamLocalBtn.style.background = '#987432';
    this.streamLocalBtn.style.color = 'white';
    this.streamLiveBtn.style.background = '#383838';
    this.streamLiveBtn.style.color = 'black';
  };

  activateLiveStream = () => {
    this.nowPlaying.style.display = 'none';
    this.nowPlayingLS.style.display = 'block';
    this.streamLocalBtn.style.background = '#383838';
    this.streamLocalBtn.style.color = 'black';
    this.streamLiveBtn.style.background = '#4c7b4c';
    this.streamLiveBtn.style.color = 'white';
  };

  deactivateAllStreams = () => {
    this.nowPlaying.style.display = 'none';
    this.nowPlayingLS.style.display = 'none';
    this.streamLocalBtn.style.background = '#383838';
    this.streamLocalBtn.style.color = 'black';
    this.streamLiveBtn.style.background = '#383838';
    this.streamLiveBtn.style.color = 'black';
  };

  switchLocal = () => {
    if (this.localMediaIsPlaying) {
      window.clearInterval(this.updateTime);
      this.localProgressBar.removeEventListener(
        'click',
        this.clickedProgressBar,
        false
      );
    }

    this.streamError.style.display = 'none';
    this.nowPlaying.style.display = 'none';
    this.nowPlayingLS.style.display = 'none';
    this.playingNowLocalPanel.style.display = 'none';
    this.playingNowPanel.style.display = 'block';
    this.spectrumBars.src = '/assets/images/spectrum-off.svg';
    this.player.pause();
    this.player.src = ICECAST_SERVER + '?t=' + d.getTime();
    this.player.load();
    this.player.play();
    this.playBtn.innerHTML = '<i class="fas fa-play-circle"></i>';
    this.buffering.style.display = 'block';
    this.streamLocalBtn.style.background = '#987432';
    this.streamLocalBtn.style.color = 'white';
    this.streamLiveBtn.style.background = '#383838';
    this.streamLiveBtn.style.color = 'black';
    this.playBtn.classList.add('pulsate-css');
    this.localMediaIsPlaying = false;
  };

  switchLive = () => {
    if (this.localMediaIsPlaying) {
      window.clearInterval(this.updateTime);
      this.localProgressBar.removeEventListener(
        'click',
        this.clickedProgressBar,
        false
      );
    }

    this.streamError.style.display = 'none';
    this.nowPlaying.style.display = 'none';
    this.nowPlayingLS.style.display = 'none';
    this.playingNowLocalPanel.style.display = 'none';
    this.playingNowPanel.style.display = 'block';
    this.spectrumBars.src = '/assets/images/spectrum-off.svg';
    this.player.pause();
    this.player.src = D3EP_LIVE_STREAM + '?t=' + d.getTime();
    this.player.load();
    this.player.play();
    this.playBtn.innerHTML = '<i class="fas fa-play-circle"></i>';
    this.buffering.style.display = 'block';
    this.streamLocalBtn.style.background = '#383838';
    this.streamLocalBtn.style.color = 'black';
    this.streamLiveBtn.style.background = '#4c7b4c';
    this.streamLiveBtn.style.color = 'white';
    this.playBtn.classList.add('pulsate-css');
    this.localMediaIsPlaying = false;
  };

  playPause = e => {
    let d = new Date();
    if (this.localMediaIsPlaying) {
      window.clearInterval(this.updateTime);
    }

    if (this.player.paused) {
      if (this.player.src.slice(0, 10) === D3EP_LIVE_STREAM.slice(0, 10)) {
        this.player.src = D3EP_LIVE_STREAM + '?t=' + d.getTime();
        this.player.load();

        this.playBtn.classList.add('pulsate-css');
        this.playingNowLocalPanel.style.display = 'none';
        this.playingNowPanel.style.display = 'block';
        this.buffering.style.display = 'block';
      } else if (this.player.src.slice(0, 10) === ICECAST_SERVER.slice(0, 10)) {
        this.player.src = ICECAST_SERVER + '?t=' + d.getTime();
        this.player.load();
        this.playBtn.classList.add('pulsate-css');
        this.playingNowLocalPanel.style.display = 'none';
        this.playingNowPanel.style.display = 'block';
        this.buffering.style.display = 'block';
      } else {
        this.deactivateAllStreams();
        // Local media file
        this.playingNowPanel.style.display = 'none';
        this.nowPlaying.style.display = 'none';
        this.nowPlayingLS.style.display = 'none';
        this.playingNowLocalPanel.style.display = 'block';
        this.playBtn.innerHTML = '<i class="fas fa-pause-circle"></i>';
        this.spectrumBars.src = '/assets/images/spectrum-on.svg';
        this.updateTime = setInterval(this.update, 500);
      }
      //updateTime = setInterval(update, 500);
      this.player.play();
    } else {
      window.clearInterval(this.updateTime);
      this.player.pause();
      this.playBtn.innerHTML = '<i class="fas fa-play-circle"></i>';
      this.spectrumBars.src = '/assets/images/spectrum-off.svg';
    }
  };

  update = () => {
    if (!this.player.ended) {
      this.currentTime.innerHTML = this.formatTime(this.player.currentTime);
      let size = parseInt(
        (this.player.currentTime * this.barSize) / this.player.duration
      );
      this.localProgress.style.width = size + 'px';
    } else {
      this.currentTime.innerHTML = '00:00:00';
      this.playBtn.innerHTML = '<i class="fas fa-play-circle"></i>';
      this.spectrumBars.src = '/assets/images/spectrum-off.svg';
      this.localProgress.style.width = '0px';
      window.clearInterval(this.updateTime);
    }

    // console.log(player.currentTime);
  };

  formatTime = n => {
    let hrs = parseInt(n / 3600);
    let mins = parseInt(n / 60);
    let secs = parseInt(n % 60);
    if (hrs < 10) {
      hrs = '0' + hrs;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }
    if (secs < 10) {
      secs = '0' + secs;
    }
    return hrs + ':' + mins + ':' + secs;
  };

  mute = () => {
    this.player.muted = !this.player.muted;

    if (this.player.muted) {
      this.muteBtn.style.color = '#333333';
    } else {
      this.muteBtn.style.color = '#d0d0d0';
    }
  };

  render() {
    if (this.props.auth.isAuthenticated && !this.props.auth.maintenance) {
      return (
        <div id="player">
          <div className="player-controls">
            <button
              className="play-btn"
              id="play-btn"
              onClick={this.playPause.bind(this)}
            >
              <i className="fas fa-play-circle" />
            </button>
            <div id="spectrum">
              <img
                id="spectrum-bars"
                src="/assets/images/spectrum-off.svg"
                alt="spectrum"
              />
            </div>

            <button className="mute-btn" id="mute-btn" onClick={this.mute}>
              <i className="fas fa-volume-down" />
            </button>

            <div className="switch-wrap">
              <button
                className="switch"
                id="stream-local"
                onClick={this.switchLocal}
              >
                LOCAL
              </button>
              <button
                className="switch"
                id="stream-live"
                onClick={this.switchLive}
              >
                ON AIR
              </button>
            </div>
          </div>

          <div id="buffering">BUFFERING</div>
          <div id="stream-error" />

          <div id="playingNow">
            <div id="nowPlaying" />
            <div id="nowPlayingLS">D3EP Radio Network</div>
          </div>

          <div id="playingNowLocal">
            <div id="nowPlayingLocalMeta">PLAYING A LOCAL FILE</div>
            <div id="playerControl">
              <div id="currentTime">00:00:00</div>
              <div id="localProgressBar">
                <div id="localProgress" />
              </div>
              <div id="currentDuration">00:00:00</div>
            </div>
          </div>
          <div className="player-right">
            <Clock />
            <IcecastStatus />
          </div>
        </div>
      );
    }
    return null;
  }
}

PlayerIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  media: state.media
});

export default withRouter(
  connect(
    mapStateToProps,
    { setActiveItem }
  )(PlayerIndex)
);
