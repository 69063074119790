import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import MyAccountProfile from './MyAccountProfile';
import MyAccountShow from './MyAccountShow';
import MyAccountFiles from './MyAccountFiles';
import alertify from 'alertifyjs';

class MyAccountIndex extends Component {
  _isMounted = false;
  state = {
    data: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/user/account')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            this.setState({
              data: res.data,
              email: res.data.user.email
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  updateEmail = email => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        let data = new FormData();
        data.append('email', email);

        axios
          .post(API_URI + '/user/account/update', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            alertify.success(
              '<i class="far fa-check-circle"></i> Email updated!'
            );
            this.setState({
              data: res.data,
              email: res.data.user.email
            });
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i> ' + err.response.data
            );
          });
      }
    }
  };

  updateLiveBroadcast = obj => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        let data = new FormData();
        data.append('plays_live', obj.plays_live);
        data.append('password', obj.password);

        axios
          .post(API_URI + '/user/account/update', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            alertify.success(
              '<i class="far fa-check-circle"></i> Live broadcast enabled!'
            );
            this.setState({
              data: res.data,
              email: res.data.user.email
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  disableLiveBroadcast = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        axios
          .post(API_URI + '/user/broadcast/deactivate')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            alertify.success('<i class="far fa-check-circle"></i> ' + res.data);
          })
          .catch(err => {
            // this.setState({ error: err.response.data });
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i> ' + err.response.data
            );
          });
      }
    }
  };

  render() {
    if (this.props.auth.isAuthenticated && this.state.data) {
      const account = this.state.data;

      return (
        <div className="content">
          {account.user ? (
            <MyAccountProfile
              profile={account.user}
              update={this.updateEmail}
            />
          ) : null}
          {account.show ? (
            <MyAccountShow
              show={account.show}
              updateSettings={this.updateLiveBroadcast}
              disable={this.disableLiveBroadcast}
            />
          ) : null}
          {account.counts ? <MyAccountFiles data={account} /> : null}
        </div>
      );
    }
    return null;
  }
}

MyAccountIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(MyAccountIndex);
