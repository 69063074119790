import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StatusIcecast extends Component {
  render() {
    if (this.props.icecast && this.props.icecast.status === 'online') {
      const stats = this.props.icecast;
      return (
        <div className="ice-settings">
          <div className="ice-heading">ICECAST</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">STATUS</div>
              <div className="readOnly">{stats.status}</div>
            </div>

            <div className="cell">
              <div className="title">ACTIVE MOUNT POINTS</div>
              <div className="readOnly">{stats.mountpoints}</div>
            </div>

            <div className="cell">
              <div className="title">BITRATE</div>
              <div className="readOnly">{stats.bitrate}</div>
            </div>

            <div className="cell">
              <div className="title">CONNECTIONS</div>
              <div className="readOnly">{stats.connections}</div>
            </div>
            <div className="cell">
              <div className="title">STREAM STARTED</div>
              <div className="readOnly">{stats.stream_start}</div>
            </div>

            <div className="cell">
              <div className="title">STREAM NAME</div>
              <div className="readOnly">{stats.stream_name}</div>
            </div>

            <div className="cell">
              <div className="title">NOW PLAYING</div>
              <div className="readOnly">{stats.now_playing}</div>
            </div>
            <div className="cell">
              <div className="title">VERSION</div>
              <div className="readOnly">{stats.version}</div>
            </div>
          </div>
        </div>
      );
    }
    if (this.props.icecast && this.props.icecast.status === 'offline') {
      return (
        <div className="flex-table">
          <div className="header">ICECAST</div>
          <div className="row">
            <div className="cell-1">STATUS</div>
            <div className="cell-2">offline</div>
          </div>
        </div>
      );
    }
    return null;
  }
}

StatusIcecast.propTypes = {
  icecast: PropTypes.object.isRequired
};

export default StatusIcecast;
