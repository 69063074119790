import { SET_ACTIVE_MEDIA_ITEM } from '../actionTypes';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_MEDIA_ITEM:
      return action.payload;
    default:
      return state;
  }
}
