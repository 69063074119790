import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AVATAR_URI } from '../../globals.js';
import { getSys } from '../../store/actions/authActions';
import moment from 'moment';
import ChatBoxInput from './ChatBoxInput';

class ChatBoxOpen extends Component {
  _isMounted = false;
  messagesEnd = React.createRef();
  state = {
    chat: this.props.sys.chat_count,
    taggedMsg: '',
    tag: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.scrollToBottom();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  scrollToBottom = () => {
    this.messagesEnd.current.scrollIntoView({ behavior: 'auto' });
  };

  componentDidUpdate() {
    if (this.props.sys.chat_count !== this.state.chat) {
      this.scrollToBottom();
    }
  }

  tag = e => {
    let tag = '@' + e.target.alt;
    let chatInput = document.getElementById('chat-message');
    let text = chatInput.value;
    let index = chatInput.selectionStart;

    this.setState({
      taggedMsg: text.slice(0, index) + tag + text.slice(index + 1),
      tag: true,
      taggedUsername: e.target.alt
    });
  };

  clearTag = () => {
    this.setState({
      taggedMsg: '',
      tag: false
    });
  };

  render() {
    if (this.props.messages) {
      const url = AVATAR_URI + '/';
      const messages = this.props.messages.map(msg => (
        <div className="chatbox-msg" key={msg.id}>
          <div className="chatbox-avatar">
            <img
              src={url + msg.avatar}
              alt={msg.username}
              width="30"
              height="30"
              onClick={this.tag}
            />
          </div>
          <div className="chatbox-right">
            <div className="chatbox-meta">
              <div className="chatbox-name">
                <Link to={`/user/${msg.username}`}>{msg.name}</Link>
              </div>
              <div className="chatbox-time">{moment(msg.date).fromNow()}</div>
            </div>
            <div className="chatbox-text">{msg.msg}</div>
          </div>
        </div>
      ));
      return (
        <div className="chatbox">
          <div className="chatbox-wrap">
            <div className="chatbox-header" onClick={this.props.control}>
              <div className="chatbox-icon">
                <i className="far fa-comments" />
              </div>
              <div className="chatbox-title">CHATBOX</div>
              <div className="icon">
                <i className="fas fa-caret-down" />
              </div>
            </div>
            <div className="chatbox-content-wrap">
              <div className="chatbox-content">
                {messages}
                <div ref={this.messagesEnd} />
              </div>

              <div className="chatbox-isTyping">{this.props.isTyping}</div>

              <ChatBoxInput
                reload={this.props.getSys}
                taggedMsg={this.state.taggedMsg}
                tag={this.state.tag}
                clearTag={this.clearTag}
                conn={this.props.conn}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

ChatBoxOpen.propTypes = {
  control: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  sys: PropTypes.object.isRequired
  // conn: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
  sys: state.sys
});

export default connect(
  mapStateToProps,
  { getSys }
)(ChatBoxOpen);

// export default connect(mapStateToProps)(ChatBoxOpen);
