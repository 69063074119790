import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CurrentScheduleCue from './CurrentScheduleCue';
import ReactTooltip from 'react-tooltip';
import SignalSwitch from './SignalSwitch';

class HeaderIndex extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.props.auth.isAuthenticated && !this.props.auth.maintenace) {
      return (
        <div className="header">
          <Link to="/" className="header-logo">
            <img src="/assets/images/amps-new.png" alt="logo" />
          </Link>

          <div className="header-cue">
            <CurrentScheduleCue />
          </div>
          <div className="signal-switch">
            <SignalSwitch />
          </div>
          <ReactTooltip />
        </div>
      );
    }
    return null;
  }
}

HeaderIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(HeaderIndex);
