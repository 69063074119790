import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import Activity from './Activity';

class HomeIndex extends Component {
  _isMounted = false;
  interval = null;
  nav = null;
  state = {
    data: {},
    error: ''
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.loadData, 100);
    this.nav = document.getElementById('nav-home');
    this.nav.classList.add('nav-active');
    this.interval = setInterval(this.loadData, 30000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.nav.classList.remove('nav-active');
    clearInterval(this.interval);
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/activity')
          .then(res => {
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    } else {
      clearInterval(this.interval);
    }
  };

  render() {
    if (this.props.auth.isAuthenticated && !this.props.auth.maintenance) {
      return (
        <div className="content">
          <Activity data={this.state.data} />
        </div>
      );
    }
    return null;
  }
}

HomeIndex.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(HomeIndex);
