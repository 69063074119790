import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import alertify from 'alertifyjs';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import ReactTooltip from 'react-tooltip';

class ManageMediaJingles extends Component {
  _isMounted = false;
  state = {
    data: this.props.data
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  clickAuto = id => {
    if (this._isMounted) {
      let self = this;
      alertify
        .confirm(
          '<b>Are you sure you want to change the status of this jingle?</b><br><br>Jingles flagged with auto true are used by the AUTO DJ scheduler and will appear in dynamically generated playlists.',
          function() {
            self.editAuto(id);
          },
          function() {
            alertify.error(
              '<i class="fas fa-info-circle"></i> Nothing was changed!'
            );
          }
        )
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    }
  };

  editAuto = id => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        let data = new FormData();
        data.append('mid', id);

        axios
          .post(API_URI + '/media/auto', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.props.reload();
            alertify.success(
              '<i class="far fa-check-circle"></i> File has been updated!'
            );
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  clickDelete = id => {
    if (this._isMounted) {
      let self = this;
      alertify
        .confirm(
          "<b>Are you sure you want to delete this jingle?</b><br><br>This process is irreversable, once it's gone it's gone!",
          function() {
            self.deleteMedia(id);
          },
          function() {
            alertify.error(
              '<i class="fas fa-info-circle"></i> You saved this file\'s bacon!'
            );
          }
        )
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    }
  };

  deleteMedia = id => {
    let token = getLS('token');

    if (token) {
      setAuthHeader(token);

      let data = new FormData();
      data.append('id', id);

      axios
        .post(API_URI + '/media/delete', data)
        .then(res => {
          if (res.headers['set-authorization']) {
            setLS('token', res.headers['set-authorization']);
          }
          this.props.reload();
          alertify.success(
            '<i class="far fa-check-circle"></i> File has been dusted!'
          );
        })
        .catch(err => {
          alertify
            .alert(
              '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                err.response.data
            )
            .setHeader(
              '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
            );
        });
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.state.data
    ) {
      const list = this.props.data.map(item => (
        <tr key={item.id} className="single-user">
          <td>
            <Link to={`/media/${item.id}`}>{item.title}</Link>
          </td>
          <td>{item.artist}</td>
          <td>{item.display_name}</td>
          <td>{moment(item.date_added).fromNow()}</td>
          <td className="last">
            {item.active ? (
              <div className="green">
                {' '}
                <i className="fas fa-check-circle" />
              </div>
            ) : (
              <div className="orange">
                <i className="far fa-times-circle" />
              </div>
            )}
          </td>
          <td className="last">
            {item.auto ? (
              <div
                className="clickable green"
                onClick={() => this.clickAuto(item.id)}
              >
                <i className="fas fa-check-circle" />
              </div>
            ) : (
              <div
                className="clickable orange"
                onClick={() => this.clickAuto(item.id)}
              >
                <i className="far fa-times-circle" />
              </div>
            )}
          </td>
          <td className="last">
            {item.locked ? (
              <div
                className="deactivated"
                data-tip="Jingle is playlisted. View this jingles's individual page to delete it!"
              >
                <i className="fas fa-trash-alt" />
              </div>
            ) : (
              <div
                className="clickable red"
                onClick={() => this.clickDelete(item.id)}
              >
                <i className="fas fa-trash-alt" />
              </div>
            )}

            <ReactTooltip />
          </td>
        </tr>
      ));
      return (
        <div className="mediaList">
          <table>
            <tbody>
              <tr>
                <th>TITLE</th>
                <th>ARTIST</th>
                <th>UPLOADED BY</th>
                <th>UPLOADED</th>
                <th className="last">ACTIVATED</th>
                <th className="last">AUTO</th>
                <th className="last">DELETE</th>
              </tr>
              {list}
              <tr>
                <td className="t-bot" colSpan="7">
                  Data retrieved at {moment(new Date()).format('h:mm:ss a')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }
}

ManageMediaJingles.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(ManageMediaJingles);
