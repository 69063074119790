import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import AddUserModal from './AddUserModal';
import alertify from 'alertifyjs';

class AddUser extends Component {
  _isMounted = false;
  state = {
    modal: false,
    users: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadUsers = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/users/get')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              users: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  saveUser = obj => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        let data = new FormData();
        data.append('username', obj.username);
        data.append('display_name', obj.display_name);
        data.append('email', obj.email);
        data.append('password', obj.password);
        data.append('ghost', obj.ghost);

        setAuthHeader(token);
        axios
          .post(API_URI + '/users/add', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              modal: false
            });
            this.props.reload();
          })
          .catch(err => {
            this.setState({
              modal: false
            });
            alertify
              .alert(
                '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                  err.response.data
              )
              .setHeader(
                '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
              );
          });
      }
    }
  };

  openModal = () => {
    if (this._isMounted) {
      this.setState({
        modal: true
      });
    }
  };

  closeModal = () => {
    if (this._isMounted) {
      this.setState({
        modal: false
      });
    }
  };

  render() {
    if (this.state.users.length > 0) {
      return (
        <div className="add-dj">
          <button className="btn" onClick={this.openModal}>
            <i className="fas fa-user-plus" /> ADD A NEW USER TO THE SYSTEM
          </button>
          {this.state.modal ? (
            <AddUserModal save={this.saveUser} close={this.closeModal} />
          ) : null}
        </div>
      );
    }
    return null;
  }
}

AddUser.propTypes = {
  reload: PropTypes.func.isRequired
};

export default AddUser;
