import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Maintenance extends Component {
  render() {
    return (
      <div className="login-wrap">
        <div className="login-form">
          <div className="login-icon">
            <i className="fas fa-wrench" />
          </div>
          <h2>HOLD TIGHT SELECTA</h2>
          <p>
            We're either adding something brilliant or fixing something that
            broke...
          </p>
          <p>We'll be back in a few minutes!</p>
        </div>
      </div>
    );
  }
}

Maintenance.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(Maintenance);
