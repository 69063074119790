import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ChatBoxClosed extends Component {
  _isMounted = false;
  timer = null;
  state = {
    count: 0,
    unread: 0
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      count: this.props.sys.chat_count
    });
    this.timer = setInterval(this.checkNewMessages, 2000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timer);
  }

  checkNewMessages = () => {
    if (
      this._isMounted &&
      this.props.sys.chat_count &&
      this.props.sys.chat_count !== this.state.count
    ) {
      const diff = this.props.sys.chat_count - this.state.count;
      this.setState({
        unread: diff,
        count: this.props.sys.chat_count
      });
    }
  };

  onClickOpen = () => {
    this.props.control();
  };
  render() {
    if (this.props.sys.chat) {
      const unread = this.state.unread;
      return (
        <div className="chatbox">
          <div className="chatbox-wrap" style={{ width: '100px' }}>
            <div className="chatbox-header" onClick={this.onClickOpen}>
              <div className={`chatbox-notify ${unread === 0 ? 'empty' : ''}`}>
                <p>{unread}</p>
              </div>

              {unread === 0 ? (
                <div className="chatbox-title">
                  <i className="far fa-comments" />
                </div>
              ) : (
                <div className="chatbox-title">
                  <i className="fas fa-comments" />
                </div>
              )}
              <div className="icon">
                <i className="fas fa-caret-up" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

ChatBoxClosed.propTypes = {
  control: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  // messages: PropTypes.array.isRequired,
  sys: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
  sys: state.sys
});

export default connect(mapStateToProps)(ChatBoxClosed);
