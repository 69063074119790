import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import alertify from 'alertifyjs';

class MyAccountShow extends Component {
  state = {
    checked: this.props.show[0].broadcast.plays_live,
    password: this.props.show[0].broadcast.password,
    edit: false
  };

  handleCheckboxChange = e => {
    if (this.state.checked) {
      // disable live broadcast
      this.props.disable(0);
    } else if (
      this.state.password &&
      this.validatePass(this.state.password) &&
      this.state.password.length >= 6
    ) {
      // enable live broadcast
      let obj = {
        password: this.state.password,
        plays_live: 1
      };
      this.props.updateSettings(obj);
    }
    this.setState({ checked: e.target.checked });
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validatePass = pass => {
    let str = pass.length;
    pass = pass.replace(/[^A-Za-z0-9]/g, '');
    if (str === pass.length && pass.length >= 6) {
      return true;
    }
    return false;
  };

  edit = () => {
    this.setState({
      edit: !this.state.edit
    });
  };

  updatePassword = () => {
    if (
      this.validatePass(this.state.password) &&
      this.state.password.length >= 6
    ) {
      this.setState({
        edit: !this.state.edit
      });
      // Now save the password here....
      let plays_live;
      if (this.state.checked === true) {
        plays_live = 1;
      } else {
        plays_live = 0;
      }
      let obj = {
        password: this.state.password,
        plays_live: plays_live
      };
      this.props.updateSettings(obj);
    } else {
      alertify
        .alert(
          '<i class="fas fa-exclamation-triangle"></i> Error: <b>Password supplied is not valid!</b><br /><br />Your broadcast password must contain a minimum of 6 characters and must be alphanumeric, no special chars with no spaces. <br /><br />e.g : <b>mYpa55w0rD</b>'
        )
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    }
  };

  render() {
    if (this.props.show[0]) {
      const show = this.props.show[0];
      return (
        <div className="ac-show-wrap">
          <h2>My Show</h2>
          <div className="ac-meta">
            <div className="ac-title">
              {show.next_slot.title ? show.next_slot.title : ''}
            </div>
            <div>
              Schedule:{' '}
              {show.next_slot.frequency ? (
                <span>
                  <b>{show.next_slot.scheduled}</b> ({show.next_slot.frequency})
                </span>
              ) : (
                ''
              )}
            </div>
            <div>
              Next Airs:{' '}
              <b>
                {show.next_slot.date
                  ? moment(show.next_slot.date).format('LLL')
                  : ''}{' '}
              </b>
              (UK)
            </div>

            <div className="ac-live">
              <input
                className="live-switch"
                id="live-switch"
                type="checkbox"
                checked={this.state.checked}
                onChange={this.handleCheckboxChange}
              />
              <label
                className={
                  show.broadcast.password ? 'ls-label' : 'ls-label noPass'
                }
                htmlFor="live-switch"
              >
                label
              </label>

              {this.state.checked && show.broadcast.password ? (
                <div className="enabled">LIVE BROADCAST ENABLED</div>
              ) : (
                <div className="disabled">LIVE BROADCAST DISABLED</div>
              )}
            </div>
            {this.state.checked ? (
              <div className="live-pass">
                {this.state.edit ? (
                  <div>
                    <input
                      type="text"
                      name="password"
                      className="passInput"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                    <button className="btn" onClick={this.updatePassword}>
                      <i className="far fa-save" /> SAVE
                    </button>
                    <div className="passInfo">
                      (Password must contain a minimum of 6 characters,
                      alphanumeric, no special chars and no spaces)
                    </div>
                  </div>
                ) : (
                  <div>
                    {this.state.password ? (
                      <div>
                        Password: <b>{show.broadcast.password}</b>{' '}
                        <button className="btn" onClick={this.edit}>
                          <i className="far fa-edit" /> CHANGE PASSWORD
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button className="btn" onClick={this.edit}>
                          <i className="far fa-hand-point-right" /> CLICK HERE
                          TO SET YOUR LIVE BROADCAST PASSWORD{' '}
                          <i className="far fa-hand-point-left" />
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : null}
            {this.state.checked ? (
              <div>
                {show.multi ? (
                  <div className="multi">
                    <i className="fas fa-exclamation-triangle" /> Please be
                    aware that as this is a multi dj show changing the password
                    here will change it for all other Djs associated with this
                    show.
                  </div>
                ) : null}
              </div>
            ) : null}

            {this.state.checked && show.broadcast.password ? (
              <div className="lb-connection">
                <div>
                  URL: <b>broadcast.d3ep.com</b>
                </div>
                <div>
                  Port: <b>8005</b>
                </div>
                <div>
                  Mount Point: <b>/show</b>
                </div>
                <div>
                  User (if required): <b>source</b>
                </div>
              </div>
            ) : null}

            {this.state.checked && show.broadcast.password ? (
              <div className="lb-notice">
                <div className="notice">
                  <i className="fas fa-radiation-alt" />{' '}
                  <b>
                    IMPORTANT - Please disable this feature if you know you are
                    NOT going to be broadcasting live this week!
                  </b>
                </div>
                <div>
                  When live broadcast is enabled a different set of intro
                  jingles are used at the head of your show. They are
                  deliberatly non-descript, containing either no or very little
                  audio description, they are more like generic sound beds. This
                  is by design to allow you a much smoother live override at the
                  beginning of your broadcast. The transitions will be much
                  cleaner for the listeners, as your connection won't cut off a
                  jingle half way through a sentence for example. Failure to
                  disable it will result in a long, pointless pad before your
                  backup file starts and will sound crap!
                </div>
                <div>
                  Equally as important, <b>DON'T FORGET TO RE-ENABLE IT</b>{' '}
                  before your next live show. Settings are applied the moment
                  your show starts. Whatever this is set to at that time will
                  dictate your live access ability for that particular show and
                  it <b>cannot be changed</b> once the show has started.
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return null;
  }
}

MyAccountShow.propTypes = {
  show: PropTypes.array.isRequired,
  updateSettings: PropTypes.func.isRequired,
  disable: PropTypes.func.isRequired
};

export default MyAccountShow;
