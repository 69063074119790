import React, { Component } from 'react';
import PropTypes from 'prop-types';
import alertify from 'alertifyjs';
import moment from 'moment';

class IcecastSettings extends Component {
  state = {
    location: this.props.icecast.location,
    hostname: this.props.icecast.hostname,
    admin: this.props.icecast.admin,
    port: this.props.icecast.port,
    adminUser: this.props.icecast.auth['admin-user'],
    adminPassword: this.props.icecast.auth['admin-password'],
    sourcePassword: this.props.icecast.auth['source-password'],
    relayPassword: this.props.icecast.auth['relay-password'],
    burstSize: this.props.icecast['burst-size'],
    fallbackOverride: this.props.icecast['fallback-override'],
    fileserve: this.props.icecast.fileserve,
    chroot: this.props.icecast.chroot,
    onConnect: this.props.icecast['on-connect'],
    onDisconnect: this.props.icecast['on-disconnect'],
    basedir: this.props.icecast.paths.basedir,
    logdir: this.props.icecast.paths.logdir,
    webroot: this.props.icecast.paths.webroot,
    aliasDestination: this.props.icecast.paths['alias-destination'],
    errorlog: this.props.icecast.logging.errorlog,
    accesslog: this.props.icecast.logging.accesslog,
    loglevel: this.props.icecast.logging.loglevel,
    logsize: this.props.icecast.logging.logsize,
    mountNameM1: this.props.icecast.m1['mount-name'],
    usernameM1: this.props.icecast.m1.username,
    passwordM1: this.props.icecast.m1.password,
    maxListenersM1: this.props.icecast.m1['max-listeners'],
    hiddenM1: this.props.icecast.m1.hidden,
    publicM1: this.props.icecast.m1.public,
    mountNameM2: this.props.icecast.m2['mount-name'],
    usernameM2: this.props.icecast.m2.username,
    passwordM2: this.props.icecast.m2.password,
    maxListenersM2: this.props.icecast.m2['max-listeners'],
    hiddenM2: this.props.icecast.m2.hidden,
    publicM2: this.props.icecast.m2.public,
    mountNameM3: this.props.icecast.m3['mount-name'],
    usernameM3: this.props.icecast.m3.username,
    passwordM3: this.props.icecast.m3.password,
    maxListenersM3: this.props.icecast.m3['max-listeners'],
    hiddenM3: this.props.icecast.m3.hidden,
    publicM3: this.props.icecast.m3.public,
    mountNameM4: this.props.icecast.m4
      ? this.props.icecast.m4['mount-name']
      : '',
    usernameM4: this.props.icecast.m4 ? this.props.icecast.m4.username : '',
    passwordM4: this.props.icecast.m4 ? this.props.icecast.m4.password : '',
    maxListenersM4: this.props.icecast.m4
      ? this.props.icecast.m4['max-listeners']
      : '',
    hiddenM4: this.props.icecast.m4 ? this.props.icecast.m4.hidden : 0,
    publicM4: this.props.icecast.m4 ? this.props.icecast.m4.public : 0,
    last_created: this.props.icecast.last_created
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  save = e => {
    e.preventDefault();
    let data = this.state;
    let check = Object.keys(data).map(function(i) {
      if (data[i] === '') {
        return undefined;
      } else {
        return 'ok';
      }
    });

    for (var i = 0; i < check.length; i++) {
      if (typeof check[i] === 'undefined') {
        alertify
          .alert(
            '<i class="fas fa-exclamation-triangle"></i> <b>Error: Empty value detected!</b><br /><br />All fields must contain a value.'
          )
          .setHeader(
            '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
          );
        return;
      }
    }
    this.props.update(data);
  };

  render() {
    if (this.props.icecast) {
      const icecast = this.state;
      return (
        <div className="ice-settings">
          {icecast.last_created ? (
            <div className="conf-gen">
              <i className="far fa-file-alt" /> Config file last generated{' '}
              <b>{moment(icecast.last_created).fromNow()}</b>
            </div>
          ) : null}
          <div className="ice-heading">GLOBAL SETTINGS</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">LOCATION</div>
              <input
                type="text"
                name="location"
                ref="location"
                onChange={this.onChange}
                value={icecast.location}
              />
            </div>

            <div className="cell">
              <div className="title">ADMIN EMAIL</div>
              <input
                type="text"
                name="admin"
                ref="admin"
                onChange={this.onChange}
                value={icecast.admin}
              />
            </div>

            <div className="cell">
              <div className="title">HOSTNAME</div>
              <input
                type="text"
                name="hostname"
                ref="hostname"
                onChange={this.onChange}
                value={icecast.hostname}
              />
            </div>

            <div className="cell">
              <div className="title">PORT</div>
              <input
                type="text"
                name="port"
                ref="port"
                onChange={this.onChange}
                value={icecast.port}
              />
            </div>

            <div className="cell">
              <div className="title">ADMIN USERNAME</div>
              <input
                type="text"
                name="adminUser"
                ref="adminUser"
                onChange={this.onChange}
                value={icecast.adminUser}
              />
            </div>

            <div className="cell">
              <div className="title">ADMIN PASSWORD</div>
              <input
                type="text"
                name="adminPassword"
                ref="adminPassword"
                onChange={this.onChange}
                value={icecast.adminPassword}
              />
            </div>

            <div className="cell">
              <div className="title">SOURCE PASSWORD</div>
              <input
                type="text"
                name="sourcePassword"
                ref="sourcePassword"
                onChange={this.onChange}
                value={icecast.sourcePassword}
              />
            </div>

            <div className="cell">
              <div className="title">RELAY PASSWORD</div>
              <input
                type="text"
                name="relayPassword"
                ref="relayPassword"
                onChange={this.onChange}
                value={icecast.relayPassword}
              />
            </div>

            <div className="cell">
              <div className="title">BURST SIZE</div>
              <input
                type="text"
                name="burstSize"
                ref="burstSize"
                onChange={this.onChange}
                value={icecast.burstSize}
              />
            </div>

            <div className="cell">
              <div className="title">FALLBACK OVERRIDE</div>
              <input
                type="text"
                name="fallbackOverride"
                ref="fallbackOverride"
                onChange={this.onChange}
                value={icecast.fallbackOverride}
              />
            </div>

            <div className="cell">
              <div className="title">FILESERVE</div>
              <input
                type="text"
                name="fileserve"
                ref="fileserve"
                onChange={this.onChange}
                value={icecast.fileserve}
              />
            </div>

            <div className="cell">
              <div className="title">CHROOT</div>
              <input
                type="text"
                name="chroot"
                ref="chroot"
                onChange={this.onChange}
                value={icecast.chroot}
              />
            </div>

            <div className="cell">
              <div className="title">BASE DIR</div>
              <input
                type="text"
                name="basedir"
                ref="basedir"
                onChange={this.onChange}
                value={icecast.basedir}
              />
            </div>

            <div className="cell">
              <div className="title">LOG DIR</div>
              <input
                type="text"
                name="logdir"
                ref="logdir"
                onChange={this.onChange}
                value={icecast.logdir}
              />
            </div>
            <div className="cell">
              <div className="title">WEBROOT</div>
              <input
                type="text"
                name="webroot"
                ref="webroot"
                onChange={this.onChange}
                value={icecast.webroot}
              />
            </div>
            <div className="cell">
              <div className="title">ALIAS DESTINATION</div>
              <input
                type="text"
                name="aliasDestination"
                ref="aliasDestination"
                onChange={this.onChange}
                value={icecast.aliasDestination}
              />
            </div>
            <div className="cell">
              <div className="title">ERROR LOG</div>
              <input
                type="text"
                name="errorlog"
                ref="errorlog"
                onChange={this.onChange}
                value={icecast.errorlog}
              />
            </div>
            <div className="cell">
              <div className="title">ACCESS LOG</div>
              <input
                type="text"
                name="accesslog"
                ref="accesslog"
                onChange={this.onChange}
                value={icecast.accesslog}
              />
            </div>
            <div className="cell">
              <div className="title">LOG LEVEL</div>
              <input
                type="text"
                name="loglevel"
                ref="loglevel"
                onChange={this.onChange}
                value={icecast.loglevel}
              />
            </div>
            <div className="cell">
              <div className="title">LOG SIZE</div>
              <input
                type="text"
                name="logsize"
                ref="logsize"
                onChange={this.onChange}
                value={icecast.logsize}
              />
            </div>

            <div className="cell">
              <div className="title">ON CONNECT</div>
              <input
                type="text"
                name="onConnect"
                ref="onConnect"
                onChange={this.onChange}
                value={icecast.onConnect}
              />
            </div>

            <div className="cell">
              <div className="title">ON DISCONNECT</div>
              <input
                type="text"
                name="onDisconnect"
                ref="onDisconnect"
                onChange={this.onChange}
                value={icecast.onDisconnect}
              />
            </div>
          </div>
          <div className="ice-heading">MOUNT POINT 1 (Master Out)</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">MOUNT POINT</div>
              <input
                type="text"
                name="mountNameM1"
                onChange={this.onChange}
                value={icecast.mountNameM1}
              />
            </div>
            <div className="cell">
              <div className="title">USERNAME</div>
              <input
                type="text"
                name="usernameM1"
                onChange={this.onChange}
                value={icecast.usernameM1}
              />
            </div>
            <div className="cell">
              <div className="title">PASSWORD</div>
              <input
                type="text"
                name="passwordM1"
                onChange={this.onChange}
                value={icecast.passwordM1}
              />
            </div>
            <div className="cell">
              <div className="title">MAX LISTENERS</div>
              <input
                type="text"
                name="maxListenersM1"
                onChange={this.onChange}
                value={icecast.maxListenersM1}
              />
            </div>
            <div className="cell">
              <div className="title">HIDDEN</div>
              <input
                type="text"
                name="hiddenM1"
                onChange={this.onChange}
                value={icecast.hiddenM1}
              />
            </div>
            <div className="cell">
              <div className="title">PUBLIC</div>
              <input
                type="text"
                name="publicM1"
                onChange={this.onChange}
                value={icecast.publicM1}
              />
            </div>
          </div>
          <div className="ice-heading">MOUNT POINT 2 (Live)</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">MOUNT POINT</div>
              <input
                type="text"
                name="mountNameM2"
                onChange={this.onChange}
                value={icecast.mountNameM2}
              />
            </div>
            <div className="cell">
              <div className="title">USERNAME</div>
              <input
                type="text"
                name="usernameM2"
                onChange={this.onChange}
                value={icecast.usernameM2}
              />
            </div>
            <div className="cell">
              <div className="title">PASSWORD</div>
              <input
                type="text"
                name="passwordM2"
                onChange={this.onChange}
                value={icecast.passwordM2}
              />
            </div>
            <div className="cell">
              <div className="title">MAX LISTENERS</div>
              <input
                type="text"
                name="maxListenersM2"
                onChange={this.onChange}
                value={icecast.maxListenersM2}
              />
            </div>
            <div className="cell">
              <div className="title">HIDDEN</div>
              <input
                type="text"
                name="hiddenM2"
                onChange={this.onChange}
                value={icecast.hiddenM2}
              />
            </div>
            <div className="cell">
              <div className="title">PUBLIC</div>
              <input
                type="text"
                name="publicM2"
                onChange={this.onChange}
                value={icecast.publicM2}
              />
            </div>
          </div>
          <div className="ice-heading">MOUNT POINT 3 (Ices)</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">MOUNT POINT</div>
              <input
                type="text"
                name="mountNameM3"
                onChange={this.onChange}
                value={icecast.mountNameM3}
              />
            </div>
            <div className="cell">
              <div className="title">USERNAME</div>
              <input
                type="text"
                name="usernameM3"
                onChange={this.onChange}
                value={icecast.usernameM3}
              />
            </div>
            <div className="cell">
              <div className="title">PASSWORD</div>
              <input
                type="text"
                name="passwordM3"
                onChange={this.onChange}
                value={icecast.passwordM3}
              />
            </div>
            <div className="cell">
              <div className="title">MAX LISTENERS</div>
              <input
                type="text"
                name="maxListenersM3"
                onChange={this.onChange}
                value={icecast.maxListenersM3}
              />
            </div>
            <div className="cell">
              <div className="title">HIDDEN</div>
              <input
                type="text"
                name="hiddenM3"
                onChange={this.onChange}
                value={icecast.hiddenM3}
              />
            </div>
            <div className="cell">
              <div className="title">PUBLIC</div>
              <input
                type="text"
                name="publicM3"
                onChange={this.onChange}
                value={icecast.publicM3}
              />
            </div>
          </div>
          <div className="ice-heading">MOUNT POINT 4 (Master)</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">MOUNT POINT</div>
              <input
                type="text"
                name="mountNameM4"
                onChange={this.onChange}
                value={icecast.mountNameM4}
              />
            </div>
            <div className="cell">
              <div className="title">USERNAME</div>
              <input
                type="text"
                name="usernameM4"
                onChange={this.onChange}
                value={icecast.usernameM4}
              />
            </div>
            <div className="cell">
              <div className="title">PASSWORD</div>
              <input
                type="text"
                name="passwordM4"
                onChange={this.onChange}
                value={icecast.passwordM4}
              />
            </div>
            <div className="cell">
              <div className="title">MAX LISTENERS</div>
              <input
                type="text"
                name="maxListenersM4"
                onChange={this.onChange}
                value={icecast.maxListenersM4}
              />
            </div>
            <div className="cell">
              <div className="title">HIDDEN</div>
              <input
                type="text"
                name="hiddenM4"
                onChange={this.onChange}
                value={icecast.hiddenM4}
              />
            </div>
            <div className="cell">
              <div className="title">PUBLIC</div>
              <input
                type="text"
                name="publicM4"
                onChange={this.onChange}
                value={icecast.publicM4}
              />
            </div>
          </div>
          <div className="ice-settings-save">
            <button className="btn blue-btn" onClick={this.save}>
              UPDATE SETTINGS
            </button>
          </div>
        </div>
      );
    }
    return null;
  }
}

IcecastSettings.propTypes = {
  icecast: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired
};

export default IcecastSettings;
