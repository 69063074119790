import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';

class MyPlaylistsIndex extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      data: {},
      error: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/playlists/get')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data.playlists
            });
          })
          .catch(err => {
            this.setState({
              error: err.response.data
            });
          });
      }
    }
  };

  render() {
    if (this.state.error) {
      return (
        <div className="content">
          <div className="big-icon">
            <i className="fas fa-sad-tear" />
          </div>
          <h3>{this.state.error}</h3>
        </div>
      );
    }
    if (this.state.data[0]) {
      const data = this.state.data;
      return (
        <div className="content">
          <div className="playlists-wrap">
            <h2>
              <i className="fas fa-bars" /> My Playlists
            </h2>
            <div className="item header">
              <div className="one hdr">TITLE</div>
              <div className="two hdr">ITEMS</div>
              <div className="three hdr">DATE CREATED</div>
              <div className="four hdr">LAST AIRED</div>
              <div className="five hdr">DURATION</div>
            </div>
            {data.map(item => (
              <Link className="item" key={item.id} to={`/playlist/${item.id}`}>
                <div className="one">{item.title}</div>
                <div className="two">
                  <i className="fas fa-file-medical-alt" />{' '}
                  <b>{item.items_count}</b>
                </div>
                <div className="three">{item.time_ago}</div>
                <div className="four">{item.last_aired}</div>
                <div className="five">{item.duration}</div>
              </Link>
            ))}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default MyPlaylistsIndex;
