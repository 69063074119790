import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import DjModal from './DjModal';
import alertify from 'alertifyjs';

class DjsList extends Component {
  _isMounted = false;
  state = {
    editDj: {},
    modal: false,
    users: this.props.users
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  editDj = id => {
    for (var i = 0; i < this.props.djs.length; i++) {
      if (this.props.djs[i].id === id) {
        this.setState({
          editDj: this.props.djs[i],
          modal: true
        });
      }
    }
  };

  closeModal = () => {
    this.setState({
      dj: {},
      modal: false
    });
  };

  delete = id => {
    if (this._isMounted) {
      this.setState({
        dj: {},
        modal: false
      });

      let self = this;
      alertify
        .confirm(
          '<b>Are you sure you want to delete this DJ / Slot?',
          function() {
            self.deleteDj(id);
          },
          function() {
            alertify.error('<i class="fas fa-info-circle"></i> Cancelled!');
          }
        )
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    }
  };

  deleteDj = id => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        let data = new FormData();
        data.append('id', id);

        setAuthHeader(token);
        axios
          .post(API_URI + '/scheduling/slot/delete', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.props.reload();
            alertify.success(
              '<i class="far fa-check-circle"></i> DJ / Slot was deleted!'
            );
          })
          .catch(err => {
            this.setState({
              dj: {},
              modal: false
            });
            alertify
              .alert(
                '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                  err.response.data
              )
              .setHeader(
                '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
              );
          });
      }
    }
  };

  saveDj = obj => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        let data = new FormData();
        data.append('id', obj.id);
        data.append('user_id', obj.user_id);
        data.append('name', obj.name);
        data.append('show_title', obj.show_title);
        data.append('start_hour', obj.start_hour);
        data.append('end_hour', obj.end_hour);
        data.append('day_no', obj.day_no);
        data.append('password', obj.password);
        data.append('s1', obj.s1);
        data.append('s2', obj.s2);
        data.append('s3', obj.s3);
        data.append('s4', obj.s4);
        data.append('plays_live', obj.plays_live);
        data.append('active', obj.active);
        data.append('multi', obj.multi);

        setAuthHeader(token);
        axios
          .post(API_URI + '/scheduling/djs/edit', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              dj: {},
              modal: false
            });
            this.props.reload();
          })
          .catch(err => {
            this.setState({
              dj: {},
              modal: false
            });
            alertify
              .alert(
                '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                  err.response.data
              )
              .setHeader(
                '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
              );
          });
      }
    }
  };

  render() {
    const djList = this.props.djs.map(dj => (
      <div
        key={dj.id}
        className={`single-dj ${dj.freq} ${dj.active ? null : 'deactivated'}`}
        onClick={() => this.editDj(dj.id)}
      >
        <div className="name">{dj.name}</div>
        <div className="show">{dj.show_title}</div>
        <div className="day">{dj.day.substring(0, 3).toUpperCase()}</div>
        <div className="time">
          {dj.start_hour === 0
            ? '00:00'
            : dj.start_hour < 10
            ? '0' + dj.start_hour + ':00'
            : dj.start_hour + ':00'}{' '}
          -{' '}
          {dj.end_hour === 0
            ? '00:00'
            : dj.end_hour < 10
            ? '0' + dj.end_hour + ':00'
            : dj.end_hour + ':00'}{' '}
        </div>
        <div className="shifts">
          {dj.shift1 ? (
            <div className="shift-on">1</div>
          ) : (
            <div className="shift-off">1</div>
          )}
          {dj.shift2 ? (
            <div className="shift-on">2</div>
          ) : (
            <div className="shift-off">2</div>
          )}
          {dj.shift3 ? (
            <div className="shift-on">3</div>
          ) : (
            <div className="shift-off">3</div>
          )}
          {dj.shift4 ? (
            <div className="shift-on">4</div>
          ) : (
            <div className="shift-off">4</div>
          )}
        </div>
        <div className="password">
          {dj.plays_live && dj.password ? dj.password : '- - - - - -'}
        </div>
        <div className="live">
          {dj.plays_live ? (
            <i className="fas fa-check-circle" />
          ) : (
            <i className="far fa-times-circle" />
          )}
        </div>
        <div className="isActive">
          {dj.active ? (
            <i className="fas fa-check-circle" />
          ) : (
            <i className="far fa-times-circle" />
          )}
        </div>
      </div>
    ));
    if (this.state.users.length > 0) {
      return (
        <div>
          <div className="djslist">
            <div className="s-dj-header">
              <div className="name">NAME</div>
              <div className="show">SHOW</div>
              <div className="day">DAY</div>
              <div className="day">TIME</div>
              <div className="shifts">SHIFTS</div>
              <div className="password">PASSWORD</div>
              <div className="live">LIVE</div>
              <div className="isActive">ACTIVE</div>
            </div>
            {djList}
          </div>
          {this.state.modal ? (
            <DjModal
              dj={this.state.editDj}
              close={this.closeModal}
              save={this.saveDj}
              delete={this.delete}
              users={this.state.users}
            />
          ) : null}
        </div>
      );
    }
    return null;
  }
}

DjsList.propTypes = {
  djs: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};

export default DjsList;
