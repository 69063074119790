import { SET_SYS } from '../actionTypes';

const initialState = {
  cue: {},
  current_shift: 0,
  icecast: 'offline',
  now: {},
  next: {},
  timestamp: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SYS:
      return action.payload;
    default:
      return state;
  }
}
