import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import NotificationsList from './NotificationsList';

class NotificationsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      active: false
    };
  }

  showMenu = e => {
    e.preventDefault();

    this.setState({ showMenu: true, active: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  };

  closeMenu = () => {
    this.setState({ showMenu: false, active: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
    if (this.props.notifications.unseen > 0) {
      this.markNotificationsAsSeen();
      this.props.reload();
    }
  };

  markNotificationsAsSeen = () => {
    let token = getLS('token');

    if (token) {
      setAuthHeader(token);
      axios
        .post(API_URI + '/notifications/seen')
        .then(res => {
          if (res.headers['set-authorization']) {
            setLS('token', res.headers['set-authorization']);
          }
        })
        .catch(err => {
          // console.log(err);
        });
    }
  };

  render() {
    let count = this.props.notifications.unseen;

    return (
      <div className="notifications">
        <button
          className={this.state.active ? 'notify-btn active' : 'notify-btn'}
          onClick={this.showMenu}
        >
          {/* <i className="fas fa-bell" /> */}
          {this.props.notifications.unseen ? (
            <div id="notifyCountNew" className="unseen">
              {count}
            </div>
          ) : (
            <div id="notifyCountNew">
              <i className="fas fa-bullhorn" />
            </div>
          )}
        </button>

        {/* {this.props.notifications.unseen ? (
          <div id="notifyCount">{count}</div>
        ) : null} */}

        {this.state.showMenu ? (
          <div className="notification-content">
            <div className="notification-list-wrap">
              <NotificationsList
                notifications={this.props.notifications.items}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

NotificationsIndex.propTypes = {
  notifications: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired
};

export default NotificationsIndex;
