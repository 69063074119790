import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import errorReducer from './reducers/errorReducer';
import sysReducer from './reducers/sysReducer';
import mediaReducer from './reducers/mediaReducer';
import cueReducer from './reducers/cueReducer';

export default combineReducers({
  auth: authReducer,
  error: errorReducer,
  sys: sysReducer,
  media: mediaReducer,
  cue: cueReducer
});
