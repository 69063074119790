import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import StatusEntities from './StatusEntities';
import StatusMedia from './StatusMedia';
import StatusServer from './StatusServer';
import StatusIcecast from './StatusIcecast';
import Loader from '../../common/Loader';

class SystemReportIndex extends Component {
  _isMounted = false;
  state = {
    data: {},
    error: ''
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/admin/system')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.status });
          });
      }
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.state.data.icecast &&
      this.state.data.media &&
      this.state.data.server &&
      this.state.data.entities
    ) {
      return (
        <div className="content">
          <h3>System Report</h3>
          <StatusEntities entities={this.state.data.entities} />
          <StatusMedia media={this.state.data.media} />
          <StatusServer server={this.state.data.server} />
          <StatusIcecast icecast={this.state.data.icecast} />
        </div>
      );
    }
    return <Loader />;
  }
}

SystemReportIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(SystemReportIndex);
