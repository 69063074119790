import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import alertify from 'alertifyjs';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'rgb(50, 50, 50)' : 'rgb(27, 27, 27)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? '' : '',
  minHeight: '49vh'
});

class EditSinglePlaylistItem extends Component {
  _isMounted = false;
  state = {
    items: this.props.media,
    selected: this.props.selected,
    title: this.props.title
  };

  componentDidMount() {
    this._isMounted = true;
    this.calculateDuration();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  id2List = {
    medialist: 'items',
    playlist: 'selected'
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'playlist') {
        state = { selected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        items: result.medialist,
        selected: result.playlist
      });
    }
  };

  componentDidUpdate() {
    this.calculateDuration();
  }

  calculateDuration = () => {
    let total = '';
    if (this.state.selected[0]) {
      let arr = this.state.selected;
      let arrayLength = this.state.selected.length;
      for (var i = 0; i < arrayLength; i++) {
        total = parseInt(total + arr[i].seconds);
      }
    }
    let duration = document.getElementById('t-duration');
    duration.innerText = this.formatTime(total);
  };

  formatTime = n => {
    let hrs = Math.floor(n / 3600);
    let mins = Math.floor((n - hrs * 3600) / 60);
    let secs = n - (hrs * 3600 + mins * 60);
    if (hrs < 10) {
      hrs = '0' + hrs;
    }
    if (mins < 10) {
      mins = '0' + mins;
    }
    if (secs < 10) {
      secs = '0' + secs;
    }
    return hrs + ':' + mins + ':' + secs;
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  save = () => {
    if (this.state.title !== '') {
      let ids = this.state.selected.map(a => a.id);
      let data = {
        title: this.state.title.trim(),
        array: ids
      };

      if (ids.length < 1) {
        alertify.error(
          '<i class="fas fa-info-circle"></i> Playlist can\'t be empty!'
        );
        return;
      }

      let self = this;
      alertify
        .confirm(
          '<b>Are you sure you want to save these changes to this playlist?</b>',
          function() {
            self.props.save(data);
          },
          function() {
            alertify.error(
              '<i class="fas fa-info-circle"></i> Changes not saved!'
            );
          }
        )
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    } else {
      alertify.error(
        '<i class="fas fa-info-circle"></i> Title can\'t be left blank!'
      );
    }
  };

  render() {
    if (this.state.items.length > 0) {
      console.log(this.state.selected);
      return (
        <div className="edit-pl-wrap">
          <h3 className="cpl">
            Currently editing <b>{this.props.title}</b>
          </h3>
          <div className="cpl-header">
            <div className="left">
              <div className="title">
                <input
                  type="text"
                  name="title"
                  value={this.state.title}
                  placeholder="title"
                  onChange={e => this.onChange(e)}
                />
              </div>

              <button
                className="btn blue-btn"
                type="submit"
                name="save-media"
                onClick={this.save}
              >
                <i className="far fa-save" /> SAVE
              </button>
            </div>
            <div className="right">
              <div className="total-duration">
                <i className="far fa-clock" /> Playlist Duration:{' '}
                <b>
                  <span id="t-duration" />
                </b>
              </div>
            </div>
          </div>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="create-pl-wrap">
              <div className="create-medialist">
                <Droppable droppableId="medialist">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.items.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="list-item">
                                <div className="type">
                                  {item.type === 'track' ? (
                                    <i className="fas fa-music" />
                                  ) : (
                                    <i className="fas fa-microphone-alt" />
                                  )}
                                </div>
                                <div className="meta">
                                  <div className="title">{item.title}</div>
                                  <div className="artist">{item.artist}</div>
                                </div>
                                <div className="stamps">
                                  <div className="duration">
                                    {item.duration}
                                  </div>
                                  <div className="time">{item.time_ago}</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>

              <div className="create-playlist">
                <Droppable droppableId="playlist">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.selected.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div className="list-item">
                                <div className="position">
                                  {index + 1 < 10 ? `0${index + 1}` : index + 1}
                                </div>
                                <div className="type">
                                  {item.type === 'track' ? (
                                    <i className="fas fa-music" />
                                  ) : (
                                    <i className="fas fa-microphone-alt" />
                                  )}
                                </div>
                                <div className="meta">
                                  <div className="title">{item.title}</div>
                                  <div className="artist">{item.artist}</div>
                                </div>
                                <div className="stamps">
                                  <div className="duration">
                                    {item.duration}
                                  </div>
                                  <div className="time">{item.time_ago}</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </div>
          </DragDropContext>
        </div>
      );
    }
    return null;
  }
}

EditSinglePlaylistItem.propTypes = {
  selected: PropTypes.array.isRequired,
  media: PropTypes.array.isRequired,
  seconds: PropTypes.number.isRequired,
  pid: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired
};

export default EditSinglePlaylistItem;
