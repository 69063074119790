import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SchedModal from './SchedModal';
import axios from 'axios';
import { connect } from 'react-redux';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import alertify from 'alertifyjs';

class SchedulingLayout extends Component {
  _isMounted = false;
  state = {
    modalOpen: false,
    editing: null,
    now: {}
  };

  componentDidMount() {
    this._isMounted = true;
    // console.log('SchedulingLayout ' + this.props.shift + ' Mounted');
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if (this.state.now.dj !== nextProps.sys.now.dj) {
      this.setState({
        now: nextProps.sys.now
      });
      // console.log('SchedulingLayout ' + this.props.shift + ' Updated');
    }
  }

  editSlot = id => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        let data = new FormData();
        data.append('id', id.substr(2));

        setAuthHeader(token);
        axios
          .post(API_URI + '/scheduling/slot/get', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              editing: res.data,
              modalOpen: true
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  closeModal = () => {
    this.setState({
      editing: null,
      modalOpen: false
    });
  };

  saveEdit = obj => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        let data = new FormData();
        data.append('id', obj.id);
        data.append('user_id', obj.user_id);
        data.append('name', obj.name);
        data.append('show_title', obj.show_title);
        data.append('start_hour', obj.start_hour);
        data.append('end_hour', obj.end_hour);
        data.append('day_no', obj.day_no);
        data.append('password', obj.password);
        data.append('s1', obj.s1);
        data.append('s2', obj.s2);
        data.append('s3', obj.s3);
        data.append('s4', obj.s4);
        data.append('plays_live', obj.plays_live);
        data.append('active', obj.active);
        data.append('multi', obj.multi);
        data.append('playlist', obj.playlist);

        setAuthHeader(token);
        axios
          .post(API_URI + '/scheduling/djs/edit', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              modal: false
            });
            this.closeModal();
            this.props.reload();
          })
          .catch(err => {
            this.setState({
              modal: false
            });
            this.closeModal();
            alertify
              .alert(
                '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                  err.response.data
              )
              .setHeader(
                '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
              );
          });
      }
    }
  };

  delete = id => {
    if (this._isMounted) {
      this.closeModal();

      let self = this;
      alertify
        .confirm(
          '<b>Are you sure you want to delete this DJ / Slot?',
          function() {
            self.deleteDj(id);
          },
          function() {
            alertify.error('<i class="fas fa-info-circle"></i> Cancelled!');
          }
        )
        .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
    }
  };

  deleteDj = id => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        let data = new FormData();
        data.append('id', id);

        setAuthHeader(token);
        axios
          .post(API_URI + '/scheduling/slot/delete', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.props.reload();
            alertify.success(
              '<i class="far fa-check-circle"></i> DJ / Slot was deleted!'
            );
          })
          .catch(err => {
            this.setState({
              dj: {},
              modal: false
            });
            alertify
              .alert(
                '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                  err.response.data
              )
              .setHeader(
                '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
              );
          });
      }
    }
  };

  render() {
    if (
      this.props.shiftData &&
      this.props.sys &&
      this.props.shift &&
      this.props.users.length > 0 &&
      this.props.playlists
    ) {
      const d = new Date();
      let today = d.getDay();
      let djs = this.props.shiftData;
      let shift = this.props.shift;
      let nowShift = 0;
      let nowId = 0;

      if (this.props.sys.now) {
        nowShift = this.props.sys.now.shift;
        nowId = this.props.sys.now.id;
      }

      let Monday = djs.map(item =>
        item.day === 1 ? (
          <div
            key={`s${shift}-${item.id}`}
            onClick={() => this.editSlot(shift + '-' + item.id)}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
            {item.playlist !== 'no' ? (
              <span className="use-playlist">
                <i className="fab fa-product-hunt" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Tuesday = djs.map(item =>
        item.day === 2 ? (
          <div
            key={`s${shift}-${item.id}`}
            onClick={() => this.editSlot(shift + '-' + item.id)}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
            {item.playlist !== 'no' ? (
              <span className="use-playlist">
                <i className="fab fa-product-hunt" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Wednesday = djs.map(item =>
        item.day === 3 ? (
          <div
            key={`s${shift}-${item.id}`}
            onClick={() => this.editSlot(shift + '-' + item.id)}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
            {item.playlist !== 'no' ? (
              <span className="use-playlist">
                <i className="fab fa-product-hunt" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Thursday = djs.map(item =>
        item.day === 4 ? (
          <div
            key={`s${shift}-${item.id}`}
            onClick={() => this.editSlot(shift + '-' + item.id)}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
            {item.playlist !== 'no' ? (
              <span className="use-playlist">
                <i className="fab fa-product-hunt" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Friday = djs.map(item =>
        item.day === 5 ? (
          <div
            key={`s${shift}-${item.id}`}
            onClick={() => this.editSlot(shift + '-' + item.id)}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
            {item.playlist !== 'no' ? (
              <span className="use-playlist">
                <i className="fab fa-product-hunt" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Saturday = djs.map(item =>
        item.day === 6 ? (
          <div
            key={`s${shift}-${item.id}`}
            onClick={() => this.editSlot(shift + '-' + item.id)}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
            {item.playlist !== 'no' ? (
              <span className="use-playlist">
                <i className="fab fa-product-hunt" />
              </span>
            ) : null}
          </div>
        ) : (
          ''
        )
      );
      let Sunday = djs.map(item =>
        item.day === 7 ? (
          <div
            key={`s${shift}-${item.id}`}
            onClick={() => this.editSlot(shift + '-' + item.id)}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
            {item.playlist !== 'no' ? (
              <span className="use-playlist">
                <i className="fab fa-product-hunt" />
              </span>
            ) : null}
          </div>
        ) : (
          ''
        )
      );

      return (
        <div className="schedule">
          <div className={`banner ${this.props.current ? 'active' : ''}`}>
            SHIFT {shift}
          </div>
          <div className="sch-table">
            <div className="col1">
              <div
                className={`col-hr ${
                  today === 1 && this.props.current ? 'active' : ''
                }`}
              >
                MON
              </div>
              {Monday}
            </div>
            <div className="col2">
              <div
                className={`col-hr ${
                  today === 2 && this.props.current ? 'active' : ''
                }`}
              >
                TUE
              </div>
              {Tuesday}
            </div>
            <div className="col3">
              <div
                className={`col-hr ${
                  today === 3 && this.props.current ? 'active' : ''
                }`}
              >
                WED
              </div>
              {Wednesday}
            </div>
            <div className="col4">
              <div
                className={`col-hr ${
                  today === 4 && this.props.current ? 'active' : null
                }`}
              >
                THU
              </div>
              {Thursday}
            </div>
            <div className="col5">
              <div
                className={`col-hr ${
                  today === 5 && this.props.current ? 'active' : null
                }`}
              >
                FRI
              </div>
              {Friday}
            </div>
            <div className="col6">
              <div
                className={`col-hr ${
                  today === 6 && this.props.current ? 'active' : null
                }`}
              >
                SAT
              </div>
              {Saturday}
            </div>
            <div className="col7">
              <div
                className={`col-hr ${
                  today === 0 && this.props.current ? 'active' : null
                }`}
              >
                SUN
              </div>
              {Sunday}
            </div>
          </div>
          {this.state.modalOpen ? (
            <SchedModal
              slot={this.state.editing.slot}
              djs={this.state.editing.djs}
              close={this.closeModal}
              save={this.saveEdit}
              delete={this.delete}
              users={this.props.users}
              playlists={this.props.playlists}
            />
          ) : null}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  sys: state.sys
});

SchedulingLayout.propTypes = {
  shift: PropTypes.number.isRequired,
  shiftData: PropTypes.array.isRequired,
  current: PropTypes.bool.isRequired,
  reload: PropTypes.func.isRequired,
  sys: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  playlists: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(SchedulingLayout);
