import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class HistoryList extends Component {
  state = {
    history: []
  };

  componentDidMount() {
    this.setState({
      history: this.props.history
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.history !== this.props.history) {
      this.setState({
        history: this.props.history
      });
    }
  }

  render() {
    if (this.state.history) {
      let history = this.state.history;

      let historyList = history.map(item => (
        <tr key={item.id} className="item">
          <td className="first-cell">{item.playback_time}</td>
          <td className="type">
            {item.type === 'jingle' ? (
              <i className="fas fa-microphone-alt" />
            ) : item.type === 'track' ? (
              <i className="fas fa-music" />
            ) : (
              <i className="fas fa-vr-cardboard" />
            )}
          </td>
          <td>
            <Link to={`media/${item.mid}`}>{item.title}</Link>
          </td>
          <td className="last-cell">{item.artist}</td>
        </tr>
      ));

      return (
        <div className="history-list">
          <table>
            <tbody>
              <tr className="hdr">
                <td className="first-cell">Date / Time</td>
                <td className="type">Type</td>
                <td>Title</td>
                <td className="last-cell">Artist</td>
              </tr>
              {historyList}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  }
}

HistoryList.propTypes = {
  history: PropTypes.array.isRequired
};

export default HistoryList;
