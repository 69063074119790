import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class ScheduleLayout extends Component {
  _isMounted = false;

  state = {
    now: {}
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if (this.state.now.dj !== nextProps.sys.now.dj) {
      this.setState({
        now: nextProps.sys.now
      });
    }
  }

  render() {
    if (this.props.shiftData && this.props.sys) {
      const d = new Date();
      let today = d.getDay();
      const djs = this.props.shiftData;
      const shift = this.props.shift;
      let nowShift = 0;
      let nowId = 0;

      if (this.props.sys.now) {
        nowShift = this.props.sys.now.shift;
        nowId = this.props.sys.now.id;
      }

      let Monday = djs.map(item =>
        item.day === 1 ? (
          <div
            key={`s${shift}-${item.id}`}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Tuesday = djs.map(item =>
        item.day === 2 ? (
          <div
            key={`s${shift}-${item.id}`}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Wednesday = djs.map(item =>
        item.day === 3 ? (
          <div
            key={`s${shift}-${item.id}`}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Thursday = djs.map(item =>
        item.day === 4 ? (
          <div
            key={`s${shift}-${item.id}`}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Friday = djs.map(item =>
        item.day === 5 ? (
          <div
            key={`s${shift}-${item.id}`}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Saturday = djs.map(item =>
        item.day === 6 ? (
          <div
            key={`s${shift}-${item.id}`}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
          </div>
        ) : null
      );
      let Sunday = djs.map(item =>
        item.day === 7 ? (
          <div
            key={`s${shift}-${item.id}`}
            className={`col-cell ${item.freq} ${
              shift === nowShift && item.id === nowId ? 'active' : ''
            }`}
          >
            {item.st_hr === 0
              ? '00:00'
              : item.st_hr < 10
              ? '0' + item.st_hr + ':00'
              : item.st_hr + ':00'}{' '}
            -{' '}
            {item.end_hr === 0
              ? '00:00'
              : item.end_hr < 10
              ? '0' + item.end_hr + ':00'
              : item.end_hr + ':00'}{' '}
            {item.name}{' '}
            {item.live ? (
              <span className="b-live">
                <i className="fas fa-square" />
              </span>
            ) : null}
          </div>
        ) : null
      );

      return (
        <div className="schedule">
          <div className={`banner ${this.props.current ? 'active' : null}`}>
            {this.props.current ? (
              <i className="fas fa-hand-point-right" />
            ) : null}{' '}
            SHIFT {shift}{' '}
            {this.props.current ? (
              <i className="fas fa-hand-point-left" />
            ) : null}
          </div>

          <div className="sch-table">
            <div className="col1">
              <div
                className={`col-hr ${
                  today === 1 && this.props.current ? 'active' : null
                }`}
              >
                MON
              </div>
              {Monday}
            </div>
            <div className="col2">
              <div
                className={`col-hr ${
                  today === 2 && this.props.current ? 'active' : null
                }`}
              >
                TUE
              </div>
              {Tuesday}
            </div>
            <div className="col3">
              <div
                className={`col-hr ${
                  today === 3 && this.props.current ? 'active' : null
                }`}
              >
                WED
              </div>
              {Wednesday}
            </div>
            <div className="col4">
              <div
                className={`col-hr ${
                  today === 4 && this.props.current ? 'active' : null
                }`}
              >
                THU
              </div>
              {Thursday}
            </div>
            <div className="col5">
              <div
                className={`col-hr ${
                  today === 5 && this.props.current ? 'active' : null
                }`}
              >
                FRI
              </div>
              {Friday}
            </div>
            <div className="col6">
              <div
                className={`col-hr ${
                  today === 6 && this.props.current ? 'active' : null
                }`}
              >
                SAT
              </div>
              {Saturday}
            </div>
            <div className="col7">
              <div
                className={`col-hr ${
                  today === 0 && this.props.current ? 'active' : null
                }`}
              >
                SUN
              </div>
              {Sunday}
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

ScheduleLayout.propTypes = {
  shift: PropTypes.number.isRequired,
  shiftData: PropTypes.array.isRequired,
  current: PropTypes.bool.isRequired,
  sys: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sys: state.sys
});

export default connect(mapStateToProps)(ScheduleLayout);
