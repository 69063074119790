import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import alertify from 'alertifyjs';
import Error404 from '../common/Error404';
import FeaturedInPlaylists from './FeaturedInPlaylists';
import Loader from '../common/Loader';

class SingleMediaItemIndex extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      data: {},
      path: this.props.location.pathname,
      title: '',
      artist: '',
      active: '',
      type: '',
      error: '',
      saving: false,
      deleted: false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.refresh();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._isMounted) {
      if (prevState.path !== this.state.path) {
        this.refresh();
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname !== prevState.path) {
      return {
        path: nextProps.location.pathname
      };
    } else return null;
  }

  refresh = props => {
    if (this._isMounted) {
      props = props || this.props;

      this.setState({
        ready: false,
        path: this.props.location.pathname,
        data: {},
        title: '',
        artist: '',
        active: '',
        type: '',
        error: '',
        tags: ''
      });

      let token = getLS('token');

      if (token) {
        setAuthHeader(getLS('token'));

        let data = new FormData();
        data.append('mid', this.state.path.substring(7));

        axios
          .post(API_URI + '/media/get_single', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            this.setState({
              ready: true,
              data: res.data.media,
              title: res.data.media.title,
              artist: res.data.media.artist,
              active: res.data.media.active,
              type: res.data.media.type,
              error: '',
              tags: res.data.media.tags ? res.data.media.tags : false
            });

            let data = {
              tid: res.data.media.id,
              artist: res.data.media.artist,
              title: res.data.media.title,
              bpm: res.data.media.bpm,
              seconds: res.data.media.seconds,
              pcm: res.data.media.pcm
            };

            this.initPlayer(data);
          })
          .catch(err => {
            this.setState({
              ready: true,
              data: {},
              error: err.response.status
            });
          });
      }
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  downloadMedia = e => {
    var url = 'https://api.d3ep.com/download?id=' + e.target.id;
    window.open(url);
  }

  editMedia = () => {
    let token = getLS('token');

    if (token) {
      setAuthHeader(getLS('token'));
      if (this.state.artist === '') {
        alertify.alert("Artist can't be left blank");
        return;
      }
      if (this.state.title === '') {
        alertify.alert("Title can't' be left blank");
        return;
      }

      this.setState({
        saving: true
      });

      let data = new FormData();
      data.append('id', this.state.data.id);
      data.append('active', this.state.active);
      data.append('type', this.state.type);
      data.append('title', this.state.title);
      data.append('artist', this.state.artist);

      axios
        .post(API_URI + '/media/edit_single', data)
        .then(res => {
          if (res.headers['set-authorization']) {
            setLS('token', res.headers['set-authorization']);
          }
          this.setState({
            saving: false
          });
          this.refresh();
          alertify.success('Media Saved!');
        })
        .catch(err => {
          this.setState({
            saving: false
          });
          console.log(err);
        });
    }
  };

  initPlayer = data => {
    new window.AmpsPlayer({
      wrapper: 'amps-player-wrapper',
      mediasrc: API_URI + '/media?id=' + data.tid,
      uid: 'waveplay',
      artist: data.artist,
      title: data.title,
      bpm: data.bpm,
      duration: data.seconds,
      pcm: data.pcm
    });
  };

  delete = () => {
    let self = this;
    alertify
      .confirm(
        "<b>Are you sure you want to delete this media file?</b><br><br>This operation is final and can't be undone!",
        function() {
          self.deleteMedia();
        },
        function() {
          alertify.error(
            '<i class="fas fa-info-circle"></i> The file got lucky!'
          );
        }
      )
      .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
  };

  deleteMedia = () => {
    let token = getLS('token');

    if (token) {
      setAuthHeader(getLS('token'));

      let data = new FormData();
      data.append('id', this.state.data.id);

      axios
        .post(API_URI + '/media/delete', data)
        .then(res => {
          if (res.headers['set-authorization']) {
            setLS('token', res.headers['set-authorization']);
          }
          this.refresh();
          alertify.success('Media Deleted!');
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  render() {
    if (
      this.state.ready &&
      !this.state.error &&
      this.props.auth.isAuthenticated
    ) {
      let media = this.state.data;
      let user = this.props.auth.user;

      /* User is admin */
      if (user.can_admin) {
        return (
          <div className="content">
            <div className="sin-play-wrap">
              <div id="amps-player-wrapper" />
            </div>
            <div className="single-media">
              <div className="artwork">
                <i className="fas fa-file-audio" />
              </div>

              <div className="meta">
                <div className="title">
                  <input
                    type="text"
                    name="title"
                    value={this.state.title}
                    placeholder="title"
                    onChange={e => this.onChange(e)}
                  />
                </div>

                <div>
                  <input
                    type="text"
                    name="artist"
                    value={this.state.artist}
                    placeholder="artist"
                    onChange={e => this.onChange(e)}
                  />
                </div>

                {media.locked ? (
                  <div>
                    Media Type:{' '}
                    <span className="data">
                      {media.type.charAt(0).toUpperCase() + media.type.slice(1)}
                    </span>
                  </div>
                ) : (
                  <div className="type-select">
                    Media Type:{' '}
                    <select
                      name="type"
                      value={this.state.type}
                      onChange={e => this.onChange(e)}
                    >
                      <option value="show">Show</option>
                      <option value="track">Track</option>
                      <option value="jingle">Jingle</option>
                    </select>
                  </div>
                )}

                {media.locked ? (
                  <div>
                    Active:{' '}
                    <span className="data">
                      {this.state.active === 1 ? 'YES' : 'NO'}
                    </span>
                  </div>
                ) : (
                  <div>
                    Active:
                    <input
                      type="radio"
                      name="active"
                      value="1"
                      defaultChecked={this.state.active === 1}
                      onChange={e => this.onChange(e)}
                    />
                    <span className="data">YES</span>
                    <input
                      type="radio"
                      name="active"
                      value="0"
                      defaultChecked={this.state.active === 0}
                      onChange={e => this.onChange(e)}
                    />
                    <span className="data">NO</span>
                  </div>
                )}

                <div>
                  Duration: <span className="data">{media.duration}</span>
                </div>

                <div>
                  AirPlay Count:{' '}
                  <span className="data">{media.play_count}</span>
                </div>

                <div>
                  Last Aired: <span className="data">{media.last_played}</span>
                </div>

                {media.bpm > 0 ? (
                  <div>
                    BPM: <span className="data">{media.bpm}</span>
                  </div>
                ) : null}

                <div>
                  Media ID: <span className="data">{media.id}</span>
                </div>

                <div>
                  ID3 Tags:{' '}
                  <span className="data">
                    {media.tags ? (
                      <span className="green">
                        <i className="fas fa-check" />
                      </span>
                    ) : (
                      <span className="red">
                        <i className="fas fa-times" />
                      </span>
                    )}
                  </span>
                </div>

                <div className="owner">
                  Uploaded by{' '}
                  <strong>
                    {user.id === media.user_id ? 'You' : media.owner}
                  </strong>{' '}
                  {media.time_ago}
                </div>

                {media.locked ? (
                  <div className="locked">
                    <i className="fas fa-exclamation-triangle" /> Item in active
                    playlist! ID3 Tags can't be updated at the moment.
                  </div>
                ) : (
                  <div className="spacer" />
                )}

                <button
                  className="btn blue-btn"
                  type="submit"
                  name="edit-media"
                  onClick={this.editMedia}
                >
                  SAVE CHANGES
                </button>
                
       
                <button
                  className="btn delete-btn"
                  type="submit"
                  name="download-media"
                  onClick={this.downloadMedia}
                  id={media.id}
                >
                  <i className="fas fa-download" /> DOWNLOAD
                </button>
     

                {!media.locked ? (
                  <button
                    className="btn delete-btn"
                    type="submit"
                    name="delete-media"
                    onClick={this.delete}
                  >
                    <i className="far fa-trash-alt" /> DELETE
                  </button>
                ) : null}
              </div>
            </div>
            {media.playlists.length > 0 ? (
              <FeaturedInPlaylists items={media.playlists} />
            ) : null}
          </div>
        );
      }

      /* User can edit and this isn't a show */
      if (media.can_edit && media.type !== 'show') {
        return (
          <div className="content">
            <div className="sin-play-wrap">
              <div id="amps-player-wrapper" />
            </div>
            <div className="single-media">
              <div className="artwork">
                <i className="fas fa-file-audio" />
              </div>

              <div className="meta">
                <div className="title">
                  <input
                    type="text"
                    name="title"
                    value={this.state.title}
                    placeholder="title"
                    onChange={e => this.onChange(e)}
                  />
                </div>

                <div>
                  <input
                    type="text"
                    name="artist"
                    value={this.state.artist}
                    placeholder="artist"
                    onChange={e => this.onChange(e)}
                  />
                </div>

                {media.locked ? (
                  <div>
                    Media Type:{' '}
                    <span className="data">
                      {media.type.charAt(0).toUpperCase() + media.type.slice(1)}
                    </span>
                  </div>
                ) : (
                  <div className="type-select">
                    Media Type:{' '}
                    <select
                      name="type"
                      value={this.state.type}
                      onChange={e => this.onChange(e)}
                    >
                      <option value="track">Track</option>
                      <option value="jingle">Jingle</option>
                    </select>
                  </div>
                )}

                {media.locked ? (
                  <div>
                    Active:{' '}
                    <span className="data">
                      {this.state.active === 1 ? 'YES' : 'NO'}
                    </span>
                  </div>
                ) : (
                  <div>
                    Active:
                    <input
                      type="radio"
                      name="active"
                      value="1"
                      defaultChecked={this.state.active === 1}
                      onChange={e => this.onChange(e)}
                    />
                    <span className="data">YES</span>
                    <input
                      type="radio"
                      name="active"
                      value="0"
                      defaultChecked={this.state.active === 0}
                      onChange={e => this.onChange(e)}
                    />
                    <span className="data">NO</span>
                  </div>
                )}

                <div>
                  Duration: <span className="data">{media.duration}</span>
                </div>

                <div>
                  AirPlay Count:{' '}
                  <span className="data">{media.play_count}</span>
                </div>

                <div>
                  Last Aired: <span className="data">{media.last_played}</span>
                </div>

                {media.bpm > 0 ? (
                  <div>
                    BPM: <span className="data">{media.bpm}</span>
                  </div>
                ) : null}

                <div>
                  Media ID: <span className="data">{media.id}</span>
                </div>

                <div className="owner">
                  Uploaded by{' '}
                  <strong>
                    {user.id === media.user_id ? 'You' : media.owner}
                  </strong>{' '}
                  {media.time_ago}
                </div>

                {media.locked ? (
                  <div className="locked">
                    <i className="fas fa-exclamation-triangle" /> Limited
                    editing available as file is scheduled in the active
                    playlist!
                  </div>
                ) : (
                  <div className="spacer" />
                )}

                <button
                  className="btn blue-btn"
                  type="submit"
                  name="edit-media"
                  onClick={this.editMedia}
                >
                  SAVE CHANGES
                </button>

                <button
                  className="btn delete-btn"
                  type="submit"
                  name="download-media"
                  onClick={this.downloadMedia}
                  id={media.id}
                >
                  <i className="fas fa-download" /> DOWNLOAD
                </button>

                {!media.locked ? (
                  <button
                    className="btn delete-btn"
                    type="submit"
                    name="delete-media"
                    onClick={this.delete}
                  >
                    <i className="far fa-trash-alt" /> DELETE
                  </button>
                ) : null}
              </div>
            </div>
            {media.playlists.length > 0 ? (
              <FeaturedInPlaylists items={media.playlists} />
            ) : null}
          </div>
        );
      }

      /* User can edit and this is a show */
      if (media.can_edit && media.type === 'show') {
        return (
          <div className="content">
            <div className="sin-play-wrap">
              <div id="amps-player-wrapper" />
            </div>
            <div className="single-media">
              <div className="artwork">
                <i className="fas fa-file-audio" />
              </div>

              <div className="meta">
                <div className="title">
                  <input
                    type="text"
                    name="title"
                    value={this.state.title}
                    placeholder="title"
                    onChange={e => this.onChange(e)}
                  />
                </div>

                <div>
                  <input
                    type="text"
                    name="artist"
                    value={this.state.artist}
                    placeholder="artist"
                    onChange={e => this.onChange(e)}
                  />
                </div>

                <div className="type-select">
                  Media Type: <span className="data">Show</span>
                </div>

                <div className="type-select">
                  Active: <span className="data">YES</span>
                </div>

                <div>
                  Duration: <span className="data">{media.duration}</span>
                </div>

                <div>
                  AirPlay Count:{' '}
                  <span className="data">{media.play_count}</span>
                </div>

                <div>
                  Last Aired: <span className="data">{media.last_played}</span>
                </div>

                {media.bpm > 0 ? (
                  <div>
                    BPM: <span className="data">{media.bpm}</span>
                  </div>
                ) : null}

                <div>
                  Media ID: <span className="data">{media.id}</span>
                </div>

                <div className="owner">
                  Uploaded by{' '}
                  <strong>
                    {user.id === media.user_id ? 'You' : media.owner}
                  </strong>{' '}
                  {media.time_ago}
                </div>

                {media.locked ? (
                  <div className="locked">
                    <i className="fas fa-exclamation-triangle" /> Limited
                    editing available as this file is currenty mounted in the
                    active playlist!
                  </div>
                ) : (
                  <div className="spacer" />
                )}

                <button
                  className="btn blue-btn"
                  type="submit"
                  name="edit-media"
                  onClick={this.editMedia}
                >
                  SAVE CHANGES
                </button>

                <button
                  className="btn delete-btn"
                  type="submit"
                  name="download-media"
                  onClick={this.downloadMedia}
                  id={media.id}
                >
                  <i className="fas fa-download" /> DOWNLOAD
                </button>

                {!media.locked ? (
                  <button
                    className="btn delete-btn"
                    type="submit"
                    name="delete-media"
                    onClick={this.delete}
                  >
                    <i className="far fa-trash-alt" /> DELETE
                  </button>
                ) : null}
              </div>
            </div>
            {media.playlists.length > 0 ? (
              <FeaturedInPlaylists items={media.playlists} />
            ) : null}
          </div>
        );
      }

      /* User can't edit */
      return (
        <div className="content">
          <div className="sin-play-wrap">
            <div id="amps-player-wrapper" />
          </div>
          <div className="single-media">
            <div className="artwork">
              <i className="fas fa-file-audio" />
            </div>

            <div className="meta">
              <div className="title">{media.title}</div>

              <div className="artist">{media.artist}</div>

              <div>
                Media Type:
                <span className="data">
                  {media.type.charAt(0).toUpperCase() + media.type.slice(1)}
                </span>
              </div>

              <div>
                Active:
                {media.active === 1 ? (
                  <span className="data">Yes</span>
                ) : (
                  <span className="data">No</span>
                )}
              </div>

              <div>
                Duration: <span className="data">{media.duration}</span>
              </div>

              <div>
                AirPlay Count: <span className="data">{media.play_count}</span>
              </div>

              <div>
                Last Aired: <span className="data">{media.last_played}</span>
              </div>

              {media.bpm > 0 ? (
                <div>
                  BPM: <span className="data">{media.bpm}</span>
                </div>
              ) : null}

              <div>
                Media ID: <span className="data">{media.id}</span>
              </div>

              <div className="owner">
                Uploaded by <strong>{media.owner}</strong> {media.time_ago}
              </div>

              <button
                  className="btn delete-btn"
                  type="submit"
                  name="download-media"
                  onClick={this.downloadMedia}
                  id={media.id}
                >
                  <i className="fas fa-download" /> DOWNLOAD
                </button>
            </div>
          </div>
          {media.playlists.length > 0 ? (
            <FeaturedInPlaylists items={media.playlists} />
          ) : null}
        </div>
      );
    }
    if (this.state.ready && this.state.error === 404) {
      return <Error404 />;
    }
    return <Loader />;
  }
}

SingleMediaItemIndex.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(SingleMediaItemIndex);
