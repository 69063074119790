import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import SchedulingLayout from './SchedulingLayout';
import Error404 from '../../common/Error404';
import Loader from '../../common/Loader';

class SchedulingIndex extends Component {
  _isMounted = false;
  state = {
    data: {},
    users: {},
    error: '',
    playlists: {}
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.loadData, 200);
    setTimeout(this.loadUsers, 200);
    setTimeout(this.loadPlaylists, 200);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/scheduling')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            // this.setState({ error: err.response.data });
            console.log(err);
          });
      }
    }
  };

  loadUsers = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/users/get')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              users: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  loadPlaylists = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/playlists/all')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              playlists: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.state.data.shift1 &&
      this.state.data.shift2 &&
      this.state.data.shift3 &&
      this.state.data.shift4 &&
      this.state.data.current_shift &&
      this.state.users.length > 0 &&
      this.state.playlists
    ) {
      return (
        <div className="content">
          <div className="schedule-wrap">
            <div className="legend">
              <div className="l-info">LEGEND: </div>
              <div className="l-week">WEEKLY</div>
              <div className="l-biwk">BI-WEEKLY</div>
              <div className="l-mont">MONTHLY</div>
              <div className="l-live">LIVE BROADCAST</div>
              <div className="l-shft">CURRENT</div>
              <div className="l-plist">USE PLAYLIST</div>
            </div>

            <SchedulingLayout
              shift={1}
              shiftData={this.state.data.shift1}
              current={this.state.data.current_shift === 1 ? true : false}
              reload={this.loadData}
              users={this.state.users}
              playlists={this.state.playlists}
            />
            <SchedulingLayout
              shift={2}
              shiftData={this.state.data.shift2}
              current={this.state.data.current_shift === 2 ? true : false}
              reload={this.loadData}
              users={this.state.users}
              playlists={this.state.playlists}
            />
            <SchedulingLayout
              shift={3}
              shiftData={this.state.data.shift3}
              current={this.state.data.current_shift === 3 ? true : false}
              reload={this.loadData}
              users={this.state.users}
              playlists={this.state.playlists}
            />
            <SchedulingLayout
              shift={4}
              shiftData={this.state.data.shift4}
              current={this.state.data.current_shift === 4 ? true : false}
              reload={this.loadData}
              users={this.state.users}
              playlists={this.state.playlists}
            />
          </div>
        </div>
      );
    }
    if (this.props.auth.isAuthenticated && !this.props.auth.user.can_admin) {
      return <Error404 />;
    }
    if (this.state.error) {
      return <div>ERROR SHIZZLE</div>;
    }
    return <Loader />;
  }
}

SchedulingIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(SchedulingIndex);
