import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSys } from '../../store/actions/authActions';

class Clock extends Component {
  _isMounted = false;
  clock = null;
  interval = null;
  timer = null;
  d = new Date();
  timestamp = null;

  state = {
    initTime: 0
  };

  componentDidMount() {
    this._isMounted = true;
    clearInterval(this.interval);
    clearInterval(this.timer);
    this.props.getSys();
    this.clock = document.getElementById('clockTime');
    this.clock.innerText = '00:00:00';
    setTimeout(this.setTime, 100);
    this.timer = setInterval(this.props.getSys, 10000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
    clearInterval(this.timer);
    this.clock = null;
  }

  componentDidUpdate(nextProps) {
    const diff = nextProps.sys.timestamp * 1000 - this.state.initTime;
    if (diff > 59000) {
      this.setState({ initTime: nextProps.sys.timestamp * 1000 });
      this.setTime();
    }
  }

  setTime = () => {
    if (this._isMounted && this.props.sys.timestamp) {
      clearInterval(this.interval);
      this.timestamp = this.props.sys.timestamp * 1000;
      this.d.setTime(this.timestamp);
      this.interval = setInterval(this.updateTime, 1000);
      this.initTime();
    }
  };

  initTime = () => {
    if (this.state.initTime === 0) {
      this.setState({ initTime: this.timestamp });
    }
  };

  updateTime = () => {
    if (this.d) {
      this.d.setSeconds(this.d.getSeconds() + 1);
      this.clock.innerText = this.d.toLocaleTimeString('en-US', {
        hour12: false
      });
    }
  };

  render() {
    return (
      <div className="clock">
        <span id="clockTime" />
      </div>
    );
  }
}

Clock.propTypes = {
  sys: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sys: state.sys
});

export default connect(
  mapStateToProps,
  { getSys }
)(Clock);
