import React, { Component } from 'react';

class Butters extends Component {
  render() {
    return (
      <div className="content">
        <div className="butters">
          <div className="hair" />
          <div className="head">
            <div className="eyebrow" />
            <div className="eye">
              <div className="iris" />
              <div className="iris-up" />
            </div>
            <div className="closed_eye">
              <div className="closed_eyelid" />
            </div>
            <div className="mouth" />
            <div className="opened_mouth" />
          </div>
          <div className="body">
            <div className="armpits" />
            <div className="ziper" />
            <div className="arm">
              <div className="hand">
                <div className="thumb" />
              </div>
            </div>
          </div>
          <div className="leg">
            <div className="feet" />
          </div>
        </div>
      </div>
    );
  }
}

export default Butters;
