import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getSys } from '../../store/actions/authActions';
import CurrentScheduleCueItem from './CurrentScheduleCueItem';
import CurrentScheduleCueEmpty from './CurrentScheduleCueEmpty';

class CurrentScheduleCue extends Component {
  _isMounted = false;
  timer = null;
  state = {
    data: {}
  };

  componentDidMount() {
    this._isMounted = true;
    clearInterval(this.timer);
    setTimeout(this.props.getSys, 200);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timer);
  }

  reload = () => {
    if (this._isMounted) {
      this.props.getSys();
    }
  };

  render() {
    if (
      // this.props.sys.now &&
      // this.props.sys.next &&
      this.props.auth.isAuthenticated &&
      this.props.sys.now.scheduled &&
      this.props.sys.next.scheduled
    ) {
      return (
        <CurrentScheduleCueItem
          now={this.props.sys.now}
          next={this.props.sys.next}
          reload={this.reload}
        />
      );
    }

    // if (!this.props.auth.isAuthenticated) {
    //   console.log('noauth');
    //   window.location.reload(true);
    //   return null;
    // }

    return <CurrentScheduleCueEmpty />;
  }
}

CurrentScheduleCue.propTypes = {
  auth: PropTypes.object.isRequired,
  sys: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  sys: state.sys
});

export default connect(
  mapStateToProps,
  { getSys }
)(CurrentScheduleCue);
