import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI, AVATAR_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import alertify from 'alertifyjs';

class UserModal extends Component {
  _isMounted = false;
  state = {
    user: this.props.user,
    username: this.props.user.username,
    display_name: this.props.user.display_name,
    email: this.props.user.email,
    password: '',
    avatar: this.props.user.avatar,
    img: AVATAR_URI + '/' + this.props.user.avatar,
    can_admin: this.props.user.can_admin,
    error: '',
    showUpload: false,
    loaded: 0,
    deleteUser: 0,
    deleteMedia: 0
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  onUpdate = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onUpdateUsername = e => {
    const re = /[^a-z0-9]/g;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({
        username: e.target.value
      });
    }
  };

  editCheck = () => {
    if (this._isMounted) {
      if (this.state.deleteUser) {
        let self = this;
        alertify
          .confirm(
            '<b>Are you sure you want to delete this user?</b><br><br>It will completely remove them from the system along with any shows where they are the primary DJ. This operation is irreversable.',
            function() {
              self.edit();
            },
            function() {
              alertify.error(
                '<i class="fas fa-info-circle"></i> Bacon was saved!'
              );
            }
          )
          .setHeader(
            '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
          );
      } else if (this.state.can_admin) {
        let self = this;
        alertify
          .confirm(
            '<b>Are you sure you want to grant this user Admin privileges?</b><br><br>It will give them complete access to all areas of the system!',
            function() {
              self.edit();
            },
            function() {
              alertify.error(
                '<i class="fas fa-info-circle"></i> Nothing was changed!'
              );
            }
          )
          .setHeader(
            '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
          );
      } else {
        this.edit();
      }
    }
  };

  edit = () => {
    if (this._isMounted) {
      if (this.state.username === '') {
        this.setState({
          error: "Username can't be empty!"
        });
        return;
      }
      let regEx = /[^a-z0-9]/g;
      if (regEx.test(this.state.username)) {
        this.setState({
          error:
            'Username can only contain lowercase alphanumeric chars with no spaces!'
        });
        return;
      }
      if (this.state.display_name === '') {
        this.setState({
          error: 'You must provide a display name!'
        });
        return;
      }
      if (this.state.email === '') {
        this.setState({
          error: "Email can't be empty!"
        });
        return;
      }

      let admin = null;

      if (this.state.can_admin) {
        admin = 1;
      } else {
        admin = 0;
      }
      let data = {
        id: this.props.user.id,
        username: this.state.username.toLowerCase().replace(/[^a-z0-9]/g, ''),
        display_name: this.state.display_name.trim(),
        email: this.state.email.trim(),
        password: this.state.password.trim(),
        can_admin: admin,
        delete_user: this.state.deleteUser,
        delete_media: this.state.deleteMedia
      };

      this.props.save(data);
    }
  };

  handleSelectedFile = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    });
  };

  handleUpload = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        if (!this.state.selectedFile) {
          return;
        }

        const data = new FormData();
        data.append(
          'image',
          this.state.selectedFile,
          this.state.selectedFile.name
        );
        data.append('uid', this.props.user.id);

        axios
          .post(API_URI + '/upload/avatar', data, {
            onUploadProgress: ProgressEvent => {
              this.setState({
                loaded: (ProgressEvent.loaded / ProgressEvent.total) * 100
              });
            }
          })
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              avatar: res.data.filename,
              img: res.data.img,
              showUpload: false
            });
            this.props.reload();
          });
      }
    }
  };

  changeAvatar = () => {
    this.setState({
      showUpload: true
    });
  };

  render() {
    return (
      <div className="modal">
        <div className="modal__inner">
          <div className="avatar">
            <img src={this.state.img} alt={this.props.user.display_name} />
          </div>
          <h2>
            <Link to={`/user/${this.state.username}`}>
              {this.props.user.display_name}
            </Link>
          </h2>
          <p>*If password is left blank the existing one will not be updated</p>
          {this.props.user.ghost ? (
            <div className="ghost">
              <i className="fas fa-ghost" /> This is a ghost account without a
              real user, used as a placeholder for multi DJ shows.
            </div>
          ) : null}
          <div className="wrapper">
            <div className="row">
              <div className="lft">USERNAME</div>
              <div className="rht">
                <input
                  type="text"
                  name="username"
                  onChange={this.onUpdateUsername}
                  value={this.state.username}
                />
              </div>
            </div>
            <div className="row">
              <div className="lft">DISPLAY NAME</div>
              <div className="rht">
                <input
                  type="text"
                  name="display_name"
                  onChange={this.onUpdate}
                  value={this.state.display_name}
                />
              </div>
            </div>
            <div className="row">
              <div className="lft">EMAIL</div>
              <div className="rht">
                <input
                  type="text"
                  name="email"
                  onChange={this.onUpdate}
                  value={this.state.email}
                />
              </div>
            </div>

            <div className="row">
              <div className="lft">PASSWORD</div>
              <div className="rht">
                <input
                  type="text"
                  name="password"
                  onChange={this.onUpdate}
                  value={this.state.password}
                />
              </div>
            </div>

            <div className="row">
              <div className="lft">AVATAR</div>
              <div className="rht pointer">
                <input
                  type="text"
                  name="password"
                  readOnly
                  onClick={this.changeAvatar}
                  value={this.state.avatar}
                />
              </div>
            </div>

            {this.state.showUpload ? (
              <div>
                <div className="row">
                  <input
                    type="file"
                    name=""
                    id=""
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={this.handleSelectedFile}
                  />
                  <button className="save-btn" onClick={this.handleUpload}>
                    UPLOAD
                  </button>
                </div>
                <div className="progBar">
                  {Math.round(this.state.loaded, 2)} %
                </div>
              </div>
            ) : null}

            <div className="row">
              <div className="lft blue-tint">MAKE ADMIN</div>
              <div className="rht2 blue-box">
                <input
                  type="checkbox"
                  name="can_admin"
                  checked={this.state.can_admin}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="lft red-tint">DELETE USER</div>
              <div className="rht2 red-box">
                <input
                  type="checkbox"
                  name="deleteUser"
                  checked={this.state.deleteUser}
                  onChange={this.onChange}
                />
              </div>
            </div>

            {this.state.deleteUser ? (
              <div className="row">
                <div className="lft">DELETE USER'S MEDIA?</div>
                <div className="rht2">
                  <input
                    type="checkbox"
                    name="deleteMedia"
                    checked={this.state.deleteMedia}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            ) : null}

            <div className="row-center">
              <button className="save-btn" onClick={this.editCheck}>
                SAVE
              </button>
              <button className="cancel-btn" onClick={this.props.close}>
                CANCEL
              </button>
            </div>
            {this.state.error ? (
              <div className="m-errors">
                <i className="fas fa-exclamation-triangle" /> {this.state.error}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

UserModal.propTypes = {
  user: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired
};

export default UserModal;
