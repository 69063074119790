import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class NowPlaying extends Component {
  _isMounted = false;
  ccProgress = false;
  timer = null;
  currentTime = null;
  ccProgress = null;
  newTime = 0;

  state = {
    percentPlayed: this.props.percent,
    duration: this.props.duration
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.updateProgressBar, 100);
    setTimeout(this.initTime, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timer);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.sys.cue.percent_played !== this.props.sys.cue.percentPlayed) {
      this.updateProgressBar();
    }
    if (nextProps.sys.cue.id !== this.props.sys.cue.id) {
      // media changed
      this.initNewMedia();
    }
  }

  initTime = () => {
    if (this._isMounted && this.state.duration && this.state.percentPlayed) {
      this.currentTime = document.getElementById('c-media-current');
      this.ccProgress = document.getElementById('cc-progress');
      let percent = this.state.percentPlayed;
      let duration = this.state.duration;
      this.newTime = parseInt((duration *= percent) / 100);
      this.timer = setInterval(this.updateTime, 1000);
    }
  };

  updateTime = () => {
    if (this.newTime && this.props.sys.cue.percent_played) {
      this.newTime = this.newTime + 1;
      let hrs = Math.floor(this.newTime / 3600);
      let mins = Math.floor((this.newTime - hrs * 3600) / 60);
      let secs = this.newTime - (hrs * 3600 + mins * 60);

      if (hrs < 10) {
        hrs = '0' + hrs;
      }

      if (mins < 10) {
        mins = '0' + mins;
      }
      if (secs < 10) {
        secs = '0' + secs;
      }

      let display = hrs + ':' + mins + ':' + secs;

      if (this.currentTime) {
        this.currentTime.innerText = display;
      }
    }
  };

  initNewMedia = () => {
    if (
      this._isMounted &&
      this.props.sys.cue.duration_seconds &&
      this.props.sys.cue.percent_played
    ) {
      clearInterval(this.timer);
      this.setState({
        percentPlayed: this.props.sys.cue.percent_played,
        duration: this.props.sys.cue.duration_seconds
      });
      let percent = this.props.sys.cue.percent_played;
      let duration = this.props.sys.cue.duration_seconds;
      this.newTime = parseInt((duration *= percent) / 100);
      this.timer = setInterval(this.updateTime, 1000);
    }
  };

  updateProgressBar = () => {
    if (this._isMounted && this.state.percentPlayed) {
      let val = parseInt(this.props.sys.cue.percent_played) + '%';
      if (this.ccProgress) {
        this.ccProgress.style.width = val;
      }
    }
  };

  render() {
    if (this.props.sys.cue.percent_played) {
      let item = this.props.sys.cue;

      if (item.id) {
        return (
          <div className="cue-wrap">
            <div className="cue-head">NOW PLAYING</div>
            <div className="current-cue">
              <div className="meta">
                <i className="fas fa-headphones-alt" />
                <span className="title">{item.title}</span> by{' '}
                <span className="artist">{item.artist}</span>
              </div>
              <div className="time-display">
                <div id="c-media-current">00:00:00</div>
                <div id="c-media-total">{item.duration}</div>
              </div>

              <div className="bar">
                <span id="cc-progress" />
              </div>
            </div>
          </div>
        );
      }
    }
    return null;
  }
}

NowPlaying.propTypes = {
  sys: PropTypes.object.isRequired,
  percent: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired
};

const mapStateToProps = state => ({
  sys: state.sys
});

export default connect(mapStateToProps)(NowPlaying);
