import React, { Component } from 'react';

export default class Error404 extends Component {
  render() {
    return (
      <div className="content">
        <div className="wrap404">
          <div className="disk-wrapper">
            <div className="light-left" />
            <div className="light-right" />
            <div className="disk">
              <div className="half-circle-top" />
              <div className="half-circle-bottom" />
              <div className="inner-circle">
                <span>4</span>
                <div className="dot" />
                <span>4</span>
              </div>
            </div>
          </div>

          <h3>Whatever it was, it's gone now...</h3>
        </div>
      </div>
    );
  }
}
