import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import Error404 from '../../common/Error404';
import ManageMediaTracks from './ManageMediaTracks';
import ManageMediaJingles from './ManageMediaJingles';
import ManageMediaShows from './ManageMediaShows';
import Loader from '../../common/Loader';

class ManageMediaIndex extends Component {
  _isMounted = false;
  state = {
    data: [],
    selected: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate() {}

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/media/list')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
            if (getLS('amm')) {
              this.setState({
                selected: getLS('amm')
              });
            }
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  handleClick = e => {
    this.setPrefs([e.target.name]);
  };

  setPrefs = x => {
    setLS('amm', x);
    this.setState({
      selected: x.toString()
    });
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.state.data
    ) {
      return (
        <div className="content">
          <div className="djm-wrap">
            <h2>Manage Media</h2>
            <div className="mm-header">
              <button
                className={`btn btn-mm ${
                  this.state.selected === 'tracks' ? 'mm-active' : ''
                }`}
                name="tracks"
                onClick={e => this.handleClick(e)}
              >
                TRACKS
              </button>

              <button
                className={`btn btn-mm ${
                  this.state.selected === 'jingles' ? 'mm-active' : ''
                }`}
                name="jingles"
                onClick={e => this.handleClick(e)}
              >
                JINGLES
              </button>

              <button
                className={`btn btn-mm ${
                  this.state.selected === 'shows' ? 'mm-active' : ''
                }`}
                name="shows"
                onClick={e => this.handleClick(e)}
              >
                SHOWS
              </button>
            </div>
            {this.state.selected === 'tracks' ? (
              <ManageMediaTracks
                data={this.state.data.tracks}
                reload={this.loadData}
              />
            ) : this.state.selected === 'jingles' ? (
              <ManageMediaJingles
                data={this.state.data.jingles}
                reload={this.loadData}
              />
            ) : this.state.selected === 'shows' ? (
              <ManageMediaShows
                data={this.state.data.shows}
                reload={this.loadData}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
      );
    }
    if (this.props.auth.isAuthenticated && !this.props.auth.user.can_admin) {
      return <Error404 />;
    }
    return null;
  }
}

ManageMediaIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(ManageMediaIndex);
