import React, { Component } from 'react';

export default class Kenny extends Component {
  render() {
    return (
      <div id="kenny">
        <div className="kenny_head">
          <div className="kenny_hoodie" />
          <div className="kenny_left_eye">
            <div className="kenny_left_eye_closed" />
          </div>
          <div className="kenny_right_eye">
            <div className="kenny_right_eye_closed" />
          </div>
          <div className="kenny_lace" />
        </div>
      </div>
    );
  }
}
