import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSys } from '../../store/actions/authActions';

class CurrentPlaylistDisplay extends Component {
  _isMounted = false;
  nav = null;
  timer = null;
  state = {
    current_cue: false,
    current_playlist: false,
    cue_found: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.getSys();
    setTimeout(this.setInitialState, 100);
    setTimeout(this.setCue, 500);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timer);
  }

  setInitialState = () => {
    if (this._isMounted) {
      this.setState({
        current_cue: parseInt(this.props.sys.cue.playlist_index - 1),
        current_playlist: this.props.sys.cue.playlist_title
      });

      let currentCueDiv = document.getElementById(
        'cue-' + this.state.current_cue
      );

      if (currentCueDiv) {
        currentCueDiv.classList.add('media-active');
      }
    }
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.sys.cue.id - 1 !== prevState.current_cue) {
  //     return { current_cue: nextProps.sys.cue.id - 1 };
  //   }
  //   return null;
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.sys.cue.id - 1 !== prevState.current_cue) {
  //     //Perform some operation here
  //     this.props.reload();
  //     this.setCue();
  //   }
  // }

  // componentDidUpdate(nextProps) {
  //   if (this._isMounted && this.props.sys.cue.id) {
  //     if (nextProps.sys.cue.id !== this.state.current_cue) {
  //       this.props.reload();
  //       this.setCue();
  //     }
  //   }
  // }

  setCue = () => {
    if (this._isMounted) {
      let currentCue = parseInt(this.props.sys.cue.playlist_index - 1);

      // For the initial load make sure we ignore the default index state
      if (!this.state.current_cue) {
        // do nothing
      } else if (
        this.state.current_cue &&
        currentCue &&
        this.state.current_cue !== currentCue &&
        currentCue !== undefined &&
        this.props.sys.cue.playlist_title !== undefined
      ) {
        // store the latest cue from redux props
        this.setState({
          current_cue: currentCue,
          current_playlist: this.props.sys.cue.playlist_title,
          cue_found: true
        });
      }
      this.handleActiveCue();
    } else {
      clearInterval(this.timer);
    }
  };

  handleActiveCue = () => {
    let activated = Array.from(
      document.querySelectorAll('.playlist-item.media-active')
    );
    activated.forEach(node => {
      node.classList.remove('media-active');
    });

    let currentCueDiv = document.getElementById(
      'cue-' + this.state.current_cue
    );
    if (currentCueDiv) {
      currentCueDiv.classList.add('media-active');
    }
  };

  render() {
    if (
      this.props.data.playlist &&
      this.props.sys.cue.percent_played &&
      this.props.sys.cue.duration_seconds
    ) {
      let items = this.props.data.playlist;

      let playList = items.map((item, index) => (
        <li
          key={index}
          className={`${
            this.state.cued === index
              ? 'playlist-item media-active'
              : 'playlist-item'
          }`}
          id={`cue-${index}`}
        >
          <div className="position">
            {index + 1 < 10 ? '0' + (index + 1) : index + 1}
          </div>
          <div className="icon-static">
            <i
              className={
                item.type === 'track'
                  ? 'fas fa-music'
                  : item.type === 'show'
                  ? 'fas fa-vr-cardboard'
                  : 'fas fa-microphone-alt'
              }
            />
          </div>

          <div className="meta">
            <div className="meta-top">
              <span className="m-title">
                <Link to={`/media/${item.id}`}>{item.title}</Link>
              </span>
              <span className="artist">{item.artist}</span>
            </div>
            <div className="meta-bottom">
              <span className="duration">
                <i className="far fa-clock" /> {item.duration}
              </span>
              <span className="filename">
                <i className="far fa-save" /> {item.filename}
              </span>
              <span className="age">
                <i className="fas fa-upload" /> {item.time_ago}
              </span>
            </div>
          </div>
        </li>
      ));

      return (
        <div>
          <div className="cue-head">CURRENT PLAYLIST</div>
          <div className="current-playlist-meta">
            <h4>
              {this.state.current_playlist ? this.state.current_playlist : null}
            </h4>
            <p>
              Created by <b>{this.props.data.created_by}</b>{' '}
              {this.props.data.date_created}
            </p>
            <p>
              Contains <b>{this.props.data.playlist.length}</b> items and runs
              for <b>{this.props.data.total_duration}</b>
            </p>
          </div>

          <ul className="media-list">{playList}</ul>
        </div>
      );
    }
    return null;
  }
}

CurrentPlaylistDisplay.propTypes = {
  data: PropTypes.object.isRequired,
  media: PropTypes.object.isRequired,
  sys: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  media: state.media,
  sys: state.sys
});

export default connect(
  mapStateToProps,
  { getSys }
)(CurrentPlaylistDisplay);
