import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import Error404 from '../../common/Error404';
import SettingsStatic from './SettingsStatic';
import SettingsOptions from './SettingsOptions';
import Loader from '../../common/Loader';
import alertify from 'alertifyjs';

class SettingsIndex extends Component {
  _isMounted = false;
  state = {
    data: {},
    error: ''
  };

  componentDidMount() {
    this._isMounted = true;
    setTimeout(this.loadData, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/settings')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.status });
          });
      }
    }
  };

  updateSettings = obj => {
    if (this._isMounted) {
      let token = getLS('token');
      if (token) {
        setAuthHeader(token);
        let data = new FormData();
        data.append('activity', obj.activity);
        data.append('bitrate', obj.bitrate);
        data.append('bpm', obj.bpm);
        data.append('chat', obj.chat);
        data.append('inject', obj.inject);
        data.append('shows', obj.shows);
        data.append('repeats', obj.repeats);
        data.append('tags', obj.tags);
        axios
          .post(API_URI + '/settings/update', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.loadData();
            alertify.success(
              '<i class="far fa-check-circle"></i> Settings updated!'
            );
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                err.response.data
            );
          });
      }
    }
  };

  deleteChatCheck = () => {
    let self = this;
    alertify
      .confirm(
        '<b>Are you sure that you want to delete all of the Chatbox messages?</b><br /><br /><i class="fas fa-exclamation-triangle"></i> This operation is irreversible!',
        function() {
          self.deleteChat();
        },
        function() {
          alertify.error(
            '<i class="fas fa-info-circle"></i> Action cancelled!'
          );
        }
      )
      .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
  };

  deleteChat = () => {
    if (this._isMounted) {
      let token = getLS('token');
      if (token) {
        setAuthHeader(token);
        let data = new FormData();
        data.append('delete', 'yes');
        axios
          .post(API_URI + '/admin/dcb', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            alertify.success(
              '<i class="far fa-check-circle"></i> Chatbox messages deleted!'
            );
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                err.response.data
            );
          });
      }
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.state.data.settings
    ) {
      return (
        <div className="content">
          <h3>AMPS Settings</h3>
          <SettingsStatic data={this.state.data} />
          <SettingsOptions
            data={this.state.data}
            update={this.updateSettings}
            deleteChat={this.deleteChatCheck}
          />
        </div>
      );
    }
    if (!this.props.auth.isAuthenticated || !this.props.auth.user.can_admin) {
      return <Error404 />;
    }
    return <Loader />;
  }
}

SettingsIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(SettingsIndex);
