import isEmpty from '../../functions/isEmpty';
import {
  SET_CURRENT_USER,
  CLEAR_CURRENT_USER,
  SET_RESET_REQUEST,
  KILL_RESET_REQUEST,
  SET_NEW_PASSWORD,
  MAINTENANCE_MODE
} from '../actionTypes';

const initialState = {
  isAuthenticated: false,
  maintenance: false,
  user: {},
  request: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        maintenance: false,
        user: action.payload,
        request: false
      };
    case CLEAR_CURRENT_USER:
      return {
        isAuthenticated: false,
        maintenance: false,
        user: {},
        request: false
      };
    case SET_RESET_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        maintenance: false,
        user: {},
        request: true
      };
    case KILL_RESET_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        maintenance: false,
        user: {},
        request: false
      };
    case SET_NEW_PASSWORD:
      return {
        ...state,
        isAuthenticated: false,
        maintenance: false,
        user: {},
        request: false
      };
    case MAINTENANCE_MODE:
      return {
        ...state,
        isAuthenticated: false,
        maintenance: true,
        user: {},
        request: false
      };
    default:
      return state;
  }
}
