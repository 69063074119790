import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StatusEntities extends Component {
  render() {
    if (this.props.entities) {
      const stats = this.props.entities;
      return (
        <div className="ice-settings">
          <div className="ice-heading">ENTITIES</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">USERS</div>
              <div className="readOnly">{stats.users}</div>
            </div>

            <div className="cell">
              <div className="title">ADMINS</div>
              <div className="readOnly">{stats.admins}</div>
            </div>

            <div className="cell">
              <div className="title">SCHEDULED SLOTS</div>
              <div className="readOnly">{stats.active_slots}</div>
            </div>

            <div className="cell">
              <div className="title">ACTIVE DJS</div>
              <div className="readOnly">{stats.active_djs}</div>
            </div>

            <div className="cell">
              <div className="title">TOTAL DJS</div>
              <div className="readOnly">{stats.total_djs}</div>
            </div>

            <div className="cell">
              <div className="title">AUTO DJ SLOTS</div>
              <div className="readOnly">{stats.auto_slots}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

StatusEntities.propTypes = {
  entities: PropTypes.object.isRequired
};

export default StatusEntities;
