import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CurrentScheduleCueItem extends Component {
  _isMounted = false;
  elapsed = null;
  countdown = null;
  countdownProgress = null;
  startDate = null;
  endDate = null;
  elTime = null;
  newTime = null;

  state = {
    schedule: this.props.now,
    bgColor: false,
    txtColor: false,
    dj: this.props.now.dj,
    startHr: false,
    endHr: false
  };

  componentDidMount() {
    this._isMounted = true;
    clearInterval(this.elapsed);
    clearInterval(this.countdown);
    setTimeout(this.setInitialState, 100);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.elapsed);
    clearInterval(this.countdown);
  }

  componentDidUpdate(nextProps) {
    if (nextProps.sys.now.dj !== this.state.dj && this.state.schedule) {
      //this.props.reload();
      // console.log('CurrentScheduleCueItem reloaded parent');
      this.setState({
        dj: nextProps.sys.now.dj,
        schedule: nextProps.sys.now,
        startHr: nextProps.sys.now.start_hr,
        endHr: nextProps.sys.now.end_hr
      });
      clearInterval(this.timer);
      clearInterval(this.countdown);
      console.log('Setting showTime');
      setTimeout(this.setShowTime, 500);
    }
  }

  setInitialState = () => {
    if (this._isMounted && this.props.now) {
      this.setState({
        dj: this.props.now.dj,
        schedule: this.props.now,
        startHr: this.props.now.start_hr,
        endHr: this.props.now.end_hr
      });
      setTimeout(this.setShowTime, 100);
    }
  };

  setShowTime = () => {
    if (this._isMounted && this.props.sys) {
      const startHr = this.state.startHr;
      const endHr = this.state.endHr;
      this.countdownProgress = document.getElementById('cd-progress');
      this.startDate = new Date();

      if (startHr === 0) {
        this.startDate.setHours(0, 0, 0, 0);
      } else {
        this.startDate.setHours(startHr);
      }

      this.startDate.setMinutes(0);
      this.startDate.setSeconds(0);
      this.endDate = new Date();

      if (endHr === 0) {
        this.endDate.setHours(24, 0, 0, 0);
      } else {
        this.endDate.setHours(endHr);
      }
      this.endDate.setMinutes(0);
      this.endDate.setSeconds(0);
      this.elapsed = setInterval(this.timeElapsed, 500);
      this.countdown = setInterval(this.timeRemaining, 500);
    }
  };

  timeElapsed = () => {
    if (this._isMounted) {
      if (Date.now() > this.startDate) {
        let elTime = Date.now() - this.startDate;
        let hours = Math.floor(
          (elTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor((elTime % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((elTime % (1000 * 60)) / 1000);

        if (hours < 10) {
          hours = '0' + hours;
        }

        if (minutes < 10) {
          minutes = '0' + minutes;
        }

        if (seconds < 10) {
          seconds = '0' + seconds;
        }

        this.elTime = hours + ':' + minutes + ':' + seconds;
        document.getElementById('e-countdown').innerHTML = this.elTime;
      } else {
        document.getElementById('e-countdown').innerHTML = '00:00:00';
      }
    }
  };

  timeRemaining = () => {
    const range = this.endDate - this.startDate;
    const diff = this.endDate - new Date();
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((diff % (1000 * 60)) / 1000);

    if (hours < 10) {
      hours = '0' + hours;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    this.newTime = hours + ':' + minutes + ':' + seconds;

    const percent = ((100 * diff) / range).toFixed(1);
    if (percent) {
      if (isNaN(percent)) {
        // do nothing
      } else {
        this.countdownProgress.style.width = percent + '%';
        document.getElementById('countdown').innerHTML = this.newTime;
      }
    }

    if (percent > 50) {
      this.setState({
        bgColor: 'bg-green'
      });
    } else if (percent <= 50 && percent > 10) {
      this.setState({
        bgColor: 'bg-orange'
      });
    } else {
      this.setState({
        bgColor: 'bg-red'
      });
    }

    if (this.state.bgColor) {
      this.countdownProgress.removeAttribute('class');
      this.countdownProgress.classList.add(this.state.bgColor);
    }

    if (diff < 0) {
      clearInterval(this.countdown);
      document.getElementById('countdown').innerHTML = '';
      this.countdownProgress.style.width = '0%';
    }
  };

  render() {
    if (this.props.now.dj && this.state.schedule && this.props.next.dj) {
      const show = this.props.now;
      return (
        <div className="current-schedule-cue">
          <div className="c-top">
            <div className={`onair ${this.state.bgColor}`}>NOW PLAYING</div>
            <div className="meta">
              <div className="top">
                <div>
                  <b>{show.show}</b>{' '}
                  {show.dj === 'AUTO' ? null : (
                    <span>
                      with <b>{show.dj}</b>
                    </span>
                  )}
                </div>
              </div>
              <div className="cd-progress-wrap">
                <span id="cd-progress" />
              </div>
            </div>
          </div>
          <div className="c-bottom">
            <div className="cl-block">
              <div className="clock">
                <i className="far fa-clock" />
              </div>
            </div>
            <div className="elapsed">
              <div className="heading">ELAPSED</div>
              <div id="e-countdown">00:00:00</div>
            </div>
            <div className="remaining">
              <div className="heading">REMAINING</div>
              <div id="countdown">00:00:00</div>
            </div>
            <div className="up-next">
              <div className="heading2">SCHEDULED NEXT</div>
              <div className="up-next-txt">
                <b>{this.props.next.show}</b> with <b>{this.props.next.dj}</b>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

CurrentScheduleCueItem.propTypes = {
  now: PropTypes.object.isRequired,
  next: PropTypes.object.isRequired,
  sys: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  sys: state.sys
});

export default connect(mapStateToProps)(CurrentScheduleCueItem);
