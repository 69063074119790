import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

class IcecastStatus extends Component {
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (this.props.sys.icecast !== '') {
      let icecast = this.props.sys.icecast;
      return (
        <div className="icecast-status">
          {icecast.status === 'online' ? (
            <div
              className="cast-online"
              data-tip="AMPS is online and broadcasting!"
            >
              <i className="fas fa-wifi" /> ONLINE
            </div>
          ) : (
            <div className="cast-offline" data-tip="AMPS is offline!">
              <i className="fas fa-wifi" /> OFFLINE
            </div>
          )}
          <ReactTooltip />
        </div>
      );
    }
    return null;
  }
}

IcecastStatus.propTypes = {
  sys: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sys: state.sys
});

export default connect(mapStateToProps)(IcecastStatus);
