import React, { Component } from 'react';

export default class Stan extends Component {
  render() {
    return (
      <div id="stan">
        <div className="head">
          <div className="hat">
            <div className="hat-l-side" />
            <div className="hat-r-side" />
            <div className="hat-top-1" />
            <div className="hat-top-2" />
            <div className="hat-top-3" />
            <div className="hat-top-4" />
            <div className="hat-top-5" />
            <div className="hat-top-6" />
            <div className="hat-top-7" />
            <div className="hat-top-8" />
          </div>
          <div className="left-eye">
            <div className="eyelid-top" />
            <div className="eyelid-bottom" />
          </div>
          <div className="right-eye">
            <div className="eyelid-top" />
            <div className="eyelid-bottom" />
          </div>
          <div className="mouth" />
        </div>
      </div>
    );
  }
}
