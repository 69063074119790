import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class UserProfilePlaylists extends Component {
  render() {
    if (this.props.data) {
      const data = this.props.data;
      return (
        <div className="playlists-wrap">
          <div className="item header">
            <div className="one hdr">TITLE</div>
            <div className="two hdr">ITEMS</div>
            <div className="three hdr">DATE CREATED</div>
            <div className="four hdr">LAST AIRED</div>
            <div className="five hdr">DURATION</div>
          </div>
          {data.map(item => (
            <Link className="item" key={item.id} to={`/playlist/${item.id}`}>
              <div className="one">{item.title}</div>
              <div className="two">
                <i className="fas fa-file-medical-alt" />{' '}
                <b>{item.items_count}</b>
              </div>
              <div className="three">{item.time_ago}</div>
              <div className="four">{item.last_aired}</div>
              <div className="five">{item.duration}</div>
            </Link>
          ))}
        </div>
      );
    }
    return null;
  }
}

UserProfilePlaylists.propTypes = {
  data: PropTypes.array.isRequired
};

export default UserProfilePlaylists;
