import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import ShowsList from './ShowsList';

class ShowsIndex extends Component {
  _isMounted = false;
  state = {
    data: [],
    sort: '',
    error: '',
    search: '',
    goSearch: ''
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/list/shows')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            this.setState({
              data: res.data.shows,
              sort: 'DATE_DESC'
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onKeyDown = e => {
    if (e.target.value.length > 1) {
      this.setState({
        goSearch: this.state.search
      });
    }

    if (this.state.search.length < 2) {
      this.setState({
        goSearch: ''
      });
    }
  };

  render() {
    if (this.props.auth.isAuthenticated) {
      if (this.state.error) {
        return (
          <div className="content">
            <div className="big-icon">
              <i className="fas fa-sad-tear" />
            </div>
            <h3>{this.state.error}</h3>
          </div>
        );
      }
      return (
        <div className="content">
          <div className="sort-display">
            <span>Sort shows by : </span>

            <select
              name="sort"
              value={this.state.sort}
              onChange={e => this.onChange(e)}
            >
              <option value="ACTIVE_DESC">Activated First</option>
              <option value="ACTIVE_ASC">Deactivated First</option>
              <option value="ARTIST_ASC">Artist Name ASC</option>
              <option value="ARTIST_DESC">Artist Name DESC</option>
              <option value="TITLE_ASC">Track Title ASC</option>
              <option value="TITLE_DESC">Track Title DESC</option>
              <option value="DATE_ASC">Date Uploaded ASC</option>
              <option value="DATE_DESC">Date Uploaded DESC</option>
              <option value="MODIFIED_ASC">Date Modified ASC</option>
              <option value="MODIFIED_DESC">Date Modified DESC</option>
              <option value="DURATION_SHORTEST">Duration Shortest</option>
              <option value="DURATION_LONGEST">Duration Longest</option>
            </select>

            <span className="search">Search shows for : </span>
            <input
              type="text"
              className="searchBox"
              name="search"
              value={this.state.search}
              onChange={e => this.onChange(e)}
              onKeyDown={e => this.onKeyDown(e)}
            />
          </div>
          <ShowsList
            data={this.state.data}
            sort={this.state.sort}
            search={this.state.goSearch}
          />
        </div>
      );
    }
    return null;
  }
}

ShowsIndex.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(ShowsIndex);
