import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AddDjModal extends Component {
  state = {
    users: this.props.users,
    user_id: 1,
    s1: false,
    s2: false,
    s3: false,
    s4: false,
    name: this.props.users[0].display_name,
    password: '',
    show_title: '',
    start_hour: 0,
    end_hour: 0,
    day_no: 1,
    plays_live: 0,
    multi: 0,
    multiDjArray: [],
    active: 0,
    error: ''
  };

  onSelect = e => {
    this.setState({
      [e.target.name]: parseInt(e.target.value)
    });
  };

  onSelectDJ = e => {
    this.setState({
      name: e.target.options[e.target.selectedIndex].text.trim(),
      user_id: parseInt(e.target.value)
    });
  };

  onSelectMultiDJ = e => {
    let obj = {
      name: e.target.options[e.target.selectedIndex].text.trim(),
      user_id: parseInt(e.target.value)
    };
    this.setState({
      multiDjArray: [...this.state.multiDjArray, obj]
    });
  };

  removeMultiDj = index => {
    let djArray = this.state.multiDjArray;
    djArray.splice(index, 1);
    this.setState({
      multiDjArray: djArray
    });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  onUpdate = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  save = () => {
    if (
      this.state.end_hour !== 0 &&
      this.state.end_hour < this.state.start_hour
    ) {
      this.setState({
        error: "End hour can't be less than the Start hour!"
      });
      return;
    }
    if (this.state.end_hour === this.state.start_hour) {
      this.setState({
        error: "Start and End hours can't be the same!"
      });
      return;
    }
    if (!this.state.s1 && !this.state.s2 && !this.state.s3 && !this.state.s4) {
      this.setState({
        error: 'You must select at least one shift!'
      });
      return;
    }
    if (this.state.show_title === '') {
      this.setState({
        error: "Show title can't be left blank!"
      });
      return;
    }
    let active = null;
    let plays_live = null;
    if (this.state.plays_live) {
      plays_live = 1;
    } else {
      plays_live = 0;
    }
    if (this.state.active) {
      active = 1;
    } else {
      active = 0;
    }
    let name = null;
    if (this.state.user_id === 1) {
      name = 'AUTO';
    } else {
      name = this.state.name;
    }

    let multi = '';

    if (this.state.multi) {
      if (this.state.multiDjArray.length !== 0) {
        let arrayLength = this.state.multiDjArray.length;
        // Loop through all extra djs putting their user id into a comma separated string
        for (let i = 0; i < arrayLength; i++) {
          multi += this.state.multiDjArray[i].user_id + ',';
        }
        // Remove any repeats
        multi = Array.from(new Set(multi.split(','))).toString();

        // Remove trailing comma
        multi = multi.slice(0, -1);
      }
    }

    let data = {
      user_id: this.state.user_id,
      name: name,
      show_title: this.state.show_title,
      day_no: this.state.day_no,
      start_hour: this.state.start_hour,
      end_hour: this.state.end_hour,
      plays_live: plays_live,
      active: active,
      password: this.state.password,
      s1: this.state.s1,
      s2: this.state.s2,
      s3: this.state.s3,
      s4: this.state.s4,
      multi: multi
    };
    this.props.save(data);
  };

  render() {
    let selectedMultiDjs = false;

    const usersList = this.state.users.map(user => {
      if (user.id === 1) {
        return null;
      }
      return (
        <option key={user.id} value={user.id}>
          {user.display_name}
        </option>
      );
    });

    const multiDjList = this.state.users.map(user => {
      if (user.id === 1) {
        return null;
      }
      return (
        <option key={`mu-${user.id}`} value={user.id}>
          {user.display_name}
        </option>
      );
    });

    if (this.state.multiDjArray) {
      selectedMultiDjs = this.state.multiDjArray.map((dj, index) => {
        return (
          <div
            key={index}
            className="singleDj"
            onClick={() => this.removeMultiDj(index)}
          >
            {dj.name}
          </div>
        );
      });
    }

    return (
      <div className="modal">
        <div className="modal__inner">
          <h2>
            <i className="fas fa-user-edit" /> Add a DJ
          </h2>
          <div className="wrapper">
            <div className="row">
              <div className="lft">DJ</div>
              <div className="rht">
                <select
                  name="name"
                  value={this.state.user_id}
                  onChange={this.onSelectDJ}
                >
                  <option key="1" value="1">
                    AUTO
                  </option>
                  {usersList}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="lft">SHOW</div>
              <div className="rht">
                <input
                  type="text"
                  name="show_title"
                  onChange={this.onUpdate}
                  value={this.state.show_title}
                />
              </div>
            </div>
            <div className="row">
              <div className="lft">DAY</div>
              <div className="rht">
                <select
                  name="day_no"
                  value={this.state.day_no}
                  onChange={this.onSelect}
                >
                  <option value="1">Monday</option>
                  <option value="2">Tuesday</option>
                  <option value="3">Wednesday</option>
                  <option value="4">Thursday</option>
                  <option value="5">Friday</option>
                  <option value="6">Saturday</option>
                  <option value="7">Sunday</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="lft">START HOUR</div>
              <div className="rht">
                <select
                  name="start_hour"
                  value={this.state.start_hour}
                  onChange={this.onSelect}
                >
                  <option value="0">00:00</option>
                  <option value="1">01:00</option>
                  <option value="2">02:00</option>
                  <option value="3">03:00</option>
                  <option value="4">04:00</option>
                  <option value="5">05:00</option>
                  <option value="6">06:00</option>
                  <option value="7">07:00</option>
                  <option value="8">08:00</option>
                  <option value="9">09:00</option>
                  <option value="10">10:00</option>
                  <option value="11">11:00</option>
                  <option value="12">12:00</option>
                  <option value="13">13:00</option>
                  <option value="14">14:00</option>
                  <option value="15">15:00</option>
                  <option value="16">16:00</option>
                  <option value="17">17:00</option>
                  <option value="18">18:00</option>
                  <option value="19">19:00</option>
                  <option value="20">20:00</option>
                  <option value="21">21:00</option>
                  <option value="22">22:00</option>
                  <option value="23">23:00</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="lft">END HOUR</div>
              <div className="rht">
                <select
                  name="end_hour"
                  value={this.state.end_hour}
                  onChange={this.onSelect}
                >
                  <option value="0">00:00</option>
                  <option value="1">01:00</option>
                  <option value="2">02:00</option>
                  <option value="3">03:00</option>
                  <option value="4">04:00</option>
                  <option value="5">05:00</option>
                  <option value="6">06:00</option>
                  <option value="7">07:00</option>
                  <option value="8">08:00</option>
                  <option value="9">09:00</option>
                  <option value="10">10:00</option>
                  <option value="11">11:00</option>
                  <option value="12">12:00</option>
                  <option value="13">13:00</option>
                  <option value="14">14:00</option>
                  <option value="15">15:00</option>
                  <option value="16">16:00</option>
                  <option value="17">17:00</option>
                  <option value="18">18:00</option>
                  <option value="19">19:00</option>
                  <option value="20">20:00</option>
                  <option value="21">21:00</option>
                  <option value="22">22:00</option>
                  <option value="23">23:00</option>
                </select>
              </div>
            </div>

            <div className="row">
              <div className="lft">SHIFTS</div>
              <div className="rht2">
                1
                <input
                  type="checkbox"
                  name="s1"
                  checked={this.state.s1}
                  onChange={this.onChange}
                />
                2
                <input
                  type="checkbox"
                  name="s2"
                  checked={this.state.s2}
                  onChange={this.onChange}
                />
                3
                <input
                  type="checkbox"
                  name="s3"
                  checked={this.state.s3}
                  onChange={this.onChange}
                />
                4
                <input
                  type="checkbox"
                  name="s4"
                  checked={this.state.s4}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="lft">BROADCASTS LIVE?</div>
              <div className="rht2">
                <input
                  type="checkbox"
                  name="plays_live"
                  checked={this.state.plays_live}
                  onChange={this.onChange}
                />
              </div>
            </div>

            {this.state.plays_live ? (
              <div className="row">
                <div className="lft">BROADCAST PASSWORD</div>
                <div className="rht">
                  <input
                    type="text"
                    name="password"
                    onChange={this.onUpdate}
                    value={this.state.password}
                  />
                </div>
              </div>
            ) : null}

            <div className="row">
              <div className="lft">MULTI-DJ SLOT?</div>
              <div className="rht2">
                <input
                  type="checkbox"
                  name="multi"
                  checked={this.state.multi}
                  onChange={this.onChange}
                />
              </div>
            </div>

            {this.state.multi ? (
              <div className="row">
                <div className="lft">ASSIGN ANOTHER DJ TO THIS SLOT</div>
                <div className="rht">
                  <select name="name" value="" onChange={this.onSelectMultiDJ}>
                    <option key="1" value="1">
                      AUTO
                    </option>
                    {multiDjList}
                  </select>
                </div>
              </div>
            ) : null}

            {this.state.multi ? (
              this.state.multiDjArray.length !== 0 ? (
                <div className="row">
                  <div className="lft2" />
                  <div className="rht3">{selectedMultiDjs}</div>
                </div>
              ) : null
            ) : null}

            <div className="row">
              <div className="lft">ACTIVE</div>
              <div className="rht2">
                <input
                  type="checkbox"
                  name="active"
                  checked={this.state.active}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="row-center">
              <button className="save-btn" onClick={this.save}>
                SAVE
              </button>
              <button className="cancel-btn" onClick={this.props.close}>
                CANCEL
              </button>
            </div>
            {this.state.error ? (
              <div className="m-errors">
                <i className="fas fa-exclamation-triangle" /> {this.state.error}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

AddDjModal.propTypes = {
  users: PropTypes.array.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired
};

export default AddDjModal;
