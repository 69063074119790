export const API_URI = 'https://api.d3ep.com';
export const ICECAST_SERVER = 'http://api.d3ep.com:8000/amps';
export const D3EP_LIVE_STREAM = 'https://stream2.d3ep.com';
export const AVATAR_URI = 'https://api.d3ep.com/assets/images/avatars';
export const MAX_UPLOAD_SIZE = 500;
export const ALLOWED_AUDIO_FORMATS = [
  'wav',
  'aif',
  'aiff',
  'mp3',
  'mpeg',
  'flac',
  'ogg',
  'x-m4a',
  'aac',
  'x-ms-wma'
];
