import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_URI } from '../../../globals.js';
import { getLS, setLS } from '../../../functions/handleLocalStorage';
import setAuthHeader from '../../../functions/setAuthHeader';
import Error404 from '../../common/Error404';
import UsersList from './UsersList';
import LoginAsUser from './LoginAsUser';
import AddUser from './AddUser';
import Loader from '../../common/Loader';

class ManageUsersIndex extends Component {
  _isMounted = false;
  state = {
    data: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/users/get')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  loginAsUser = uid => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        let data = new FormData();
        data.append('id', uid);

        axios
          .post(API_URI + '/admin/lau', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            setLS('user', JSON.stringify(res.data.user));
            this.pushToHome();
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  pushToHome() {
    window.location.href = '/';
  }

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.can_admin &&
      this.state.data
    ) {
      return (
        <div>
          {this.state.data.length > 0 ? (
            <div className="content">
              <div className="djm-wrap">
                <h2>Manage Users</h2>
                <AddUser reload={this.loadData} />
                <LoginAsUser login={this.loginAsUser} users={this.state.data} />
                <UsersList users={this.state.data} reload={this.loadData} />
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      );
    }
    if (this.props.auth.isAuthenticated && !this.props.auth.user.can_admin) {
      return <Error404 />;
    }
    return null;
  }
}

ManageUsersIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(ManageUsersIndex);
