import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class FeaturedInPlaylists extends Component {
  render() {
    let playlisted = this.props.items.map(item => (
      <Link
        to={`/playlist/${item.id}`}
        key={`pid-${item.id}`}
        className="in-playlist"
      >
        <div className="title">{item.title}</div>
        <div className="ctd">created by</div>
        <div>{item.owner}</div>
      </Link>
    ));
    return (
      <div className="featuredPlaylists">
        <div className="feat-pl-header">
          FEATURES IN THE FOLLOWING USER CREATED PLAYLISTS
        </div>
        {playlisted}
      </div>
    );
  }
}

FeaturedInPlaylists.propTypes = {
  items: PropTypes.array.isRequired
};

export default FeaturedInPlaylists;
