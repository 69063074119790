import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

class AddUserModal extends Component {
  state = {
    username: '',
    display_name: '',
    email: '',
    password: '',
    ghost: 0,
    error: ''
  };

  onUpdate = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  save = () => {
    if (this.state.username === '') {
      this.setState({
        error: "Username can't be empty!"
      });
      return;
    }
    let regEx = /[^a-z0-9]/g;
    if (regEx.test(this.state.username)) {
      this.setState({
        error:
          'Username can only contain lowercase alphanumeric chars with no spaces!'
      });
      return;
    }
    if (this.state.display_name === '') {
      this.setState({
        error: 'You must provide a display name!'
      });
      return;
    }
    if (this.state.email === '') {
      this.setState({
        error: "Email can't be empty!"
      });
      return;
    }
    if (this.state.password.length < 6) {
      this.setState({
        error: 'Password must contain at least 6 characters!'
      });
      return;
    }

    let ghost = null;

    if (this.state.ghost) {
      ghost = 1;
    } else {
      ghost = 0;
    }
    let data = {
      username: this.state.username.toLowerCase().replace(/[^a-z0-9]/g, ''),
      display_name: this.state.display_name.trim(),
      email: this.state.email.trim(),
      password: this.state.password.trim(),
      ghost: ghost
    };
    this.props.save(data);
  };

  render() {
    return (
      <div className="modal">
        <div className="modal__inner">
          <h2>
            <i className="fas fa-user-plus" /> Add a User
          </h2>
          <div className="wrapper">
            <div className="row">
              <div className="lft">USERNAME</div>
              <div className="rht">
                <input
                  type="text"
                  name="username"
                  onChange={this.onUpdate}
                  value={this.state.username}
                />
              </div>
            </div>
            <div className="row">
              <div className="lft">DISPLAY NAME</div>
              <div className="rht">
                <input
                  type="text"
                  name="display_name"
                  onChange={this.onUpdate}
                  value={this.state.display_name}
                />
              </div>
            </div>
            <div className="row">
              <div className="lft">EMAIL</div>
              <div className="rht">
                <input
                  type="text"
                  name="email"
                  onChange={this.onUpdate}
                  value={this.state.email}
                />
              </div>
            </div>

            <div className="row">
              <div className="lft">PASSWORD</div>
              <div className="rht">
                <input
                  type="text"
                  name="password"
                  onChange={this.onUpdate}
                  value={this.state.password}
                />
              </div>
            </div>

            <div className="row">
              <div
                className="lft orange-tint"
                data-tip="Use this as a placeholder account for a multi DJ show."
              >
                GHOST USER
              </div>
              <div className="rht2 orange-box">
                <input
                  type="checkbox"
                  name="ghost"
                  checked={this.state.ghost}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <ReactTooltip />

            <div className="row-center">
              <button className="save-btn" onClick={this.save}>
                ADD USER
              </button>
              <button className="cancel-btn" onClick={this.props.close}>
                CANCEL
              </button>
            </div>
            {this.state.error ? (
              <div className="m-errors">
                <i className="fas fa-exclamation-triangle" /> {this.state.error}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

AddUserModal.propTypes = {
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired
};

export default AddUserModal;
