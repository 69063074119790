import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submitNewPassword } from '../../store/actions/authActions';

class ResetPassword extends Component {
  state = {
    isAuthenticated: false,
    password: '',
    passwordRepeat: '',
    error: '',
    code: '',
    codeSet: false,
    pwError: '',
    codeError: ''
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onChangeCode = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmitCode = e => {
    e.preventDefault();
    let reg = /^\d+$/;
    if (this.state.code.length === 5 && reg.test(this.state.code)) {
      this.setState({
        codeSet: true,
        codeError: ''
      });
    } else {
      this.setState({
        codeError: 'Code should be a 5 digit number!'
      });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.state.password && this.state.passwordRepeat && this.state.code) {
      if (this.state.password !== this.state.passwordRepeat) {
        this.setState({
          pwError: 'Passwords do not match!'
        });
        return;
      }
      if (
        this.state.password === this.state.passwordRepeat &&
        this.state.password.length < 7
      ) {
        this.setState({
          pwError: 'Passwords is too short, min of 8 chars!'
        });
        return;
      }
      if (
        this.state.password === this.state.passwordRepeat &&
        this.state.password.length >= 7
      ) {
        var data = new FormData();
        data.append('code', this.state.code);
        data.append('password', this.state.password);
        this.props.submitNewPassword(data);
        this.setState({
          codeError: '',
          pwError: ''
        });
      }
    } else {
      return;
    }
  };

  render() {
    if (!this.state.codeSet) {
      return (
        <div className="login-wrap">
          <form id="login" className="login-form">
            <div className="login-logo">
              <img src="/assets/images/amps_full.png" alt="logo" />
            </div>

            <p>
              We've just emailed you a 5 digit reset code!
              <br />
              Please enter it below to continue.
            </p>

            <input
              type="text"
              name="code"
              placeholder="code"
              value={this.state.code}
              onChange={this.onChangeCode}
            />

            <button onClick={this.onSubmitCode} className="btn blue">
              SUBMIT CODE
            </button>

            {this.state.codeError ? (
              <div className="login-error">
                <i className="fas fa-exclamation-triangle" />{' '}
                {this.state.codeError}
              </div>
            ) : null}
          </form>
        </div>
      );
    }
    return (
      <div className="login-wrap">
        <form id="login" className="login-form">
          <div className="login-logo">
            <img src="/assets/images/amps_full.png" alt="logo" />
          </div>

          <p>
            Enter your new password below twice!
            <br />
            Password must be at least 8 chars long.
          </p>

          <input
            type="password"
            name="password"
            placeholder="password"
            value={this.state.password}
            onChange={this.onChange}
          />
          <input
            type="password"
            name="passwordRepeat"
            placeholder="repeat password"
            value={this.state.passwordRepeat}
            onChange={this.onChange}
          />
          <button onClick={this.onSubmit} className="btn blue">
            SET PASSWORD
          </button>

          {this.state.pwError ? (
            <div className="login-error">
              <i className="fas fa-exclamation-triangle" /> {this.state.pwError}
            </div>
          ) : null}

          {this.props.error ? (
            <div className="login-error">
              <i className="fas fa-exclamation-triangle" /> {this.props.error}
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(
  mapStateToProps,
  { submitNewPassword }
)(ResetPassword);
