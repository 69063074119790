import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import ScheduleLayout from './ScheduleLayout';
import Loader from '../common/Loader';

class ScheduleIndex extends Component {
  _isMounted = false;
  nav = null;
  state = {
    data: {}
  };

  componentDidMount() {
    this._isMounted = true;
    this.nav = document.getElementById(
      'nav-' + this.props.location.pathname.slice(1)
    );
    this.nav.classList.add('nav-active');
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.nav.classList.remove('nav-active');
  }

  loadData = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);
        axios
          .post(API_URI + '/scheduling')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  render() {
    if (
      this.props.auth.isAuthenticated &&
      this.state.data.shift1 &&
      this.state.data.shift2 &&
      this.state.data.shift3 &&
      this.state.data.shift4
    ) {
      return (
        <div className="content">
          <div className="schedule-wrap">
            <div className="legend">
              <div className="l-info">LEGEND: </div>
              <div className="l-week">WEEKLY</div>
              <div className="l-biwk">BI-WEEKLY</div>
              <div className="l-mont">MONTHLY</div>
              <div className="l-live">LIVE BROADCAST</div>
              <div className="l-shft">CURRENT</div>
              <div className="l-plist">USE PLAYLIST</div>
            </div>

            <ScheduleLayout
              shift={1}
              shiftData={this.state.data.shift1}
              current={this.state.data.current_shift === 1 ? true : false}
            />
            <ScheduleLayout
              shift={2}
              shiftData={this.state.data.shift2}
              current={this.state.data.current_shift === 2 ? true : false}
            />
            <ScheduleLayout
              shift={3}
              shiftData={this.state.data.shift3}
              current={this.state.data.current_shift === 3 ? true : false}
            />
            <ScheduleLayout
              shift={4}
              shiftData={this.state.data.shift4}
              current={this.state.data.current_shift === 4 ? true : false}
            />
          </div>
        </div>
      );
    }
    return <Loader />;
  }
}

ScheduleIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default withRouter(connect(mapStateToProps)(ScheduleIndex));
