import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

class ChatBoxInput extends Component {
  _isMounted = false;
  state = {
    msg: '',
    timestamp: new Date().getTime(),
    warn: false,
    emojiPicker: false,
    clickedOutside: false,
    tag: this.props.tag,
    userId: false
  };

  componentDidMount() {
    this._isMounted = true;
    document.addEventListener('mousedown', this.handleClickOutside);
    if (getLS('user')) {
      this.setState({
        userId: JSON.parse(getLS('user')).id
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  myRef = React.createRef();

  handleClickOutside = e => {
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ clickedOutside: true, emojiPicker: false });
    }
  };

  handleClickInside = () => this.setState({ clickedOutside: false });

  addEmoji = emoji => {
    let index = document.getElementById('chat-message').selectionStart;
    this.setState({
      msg:
        this.state.msg.slice(0, index) +
        emoji.native +
        this.state.msg.slice(index + 1)
    });
  };

  emojiPicker = () => {
    this.setState({
      emojiPicker: !this.state.emojiPicker
    });
  };

  onChange = e => {
    // if (this.state.msg === '') {
    //   if (getLS('user')) {
    //     let obj = {
    //       action: 'typing',
    //       msg: JSON.parse(getLS('user')).display_name + ' is typing...'
    //     };
    //     this.props.conn.send(JSON.stringify(obj));
    //   }
    // }
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.addMessage();
    }
  };

  releaseInput = () => {
    this.setState({
      warn: false,
      msg: ''
    });
  };

  addMessage = () => {
    if (this._isMounted && this.state.msg !== '') {
      const now = new Date().getTime();
      if (this.state.timestamp + 2000 > now) {
        this.setState({
          warn: true,
          msg: '',
          emojiPicker: false
        });
        setTimeout(this.releaseInput, 2000);
        return;
      }

      this.setState({
        timestamp: now,
        warn: false,
        emojiPicker: false
      });

      // let obj = {
      //   action: 'send',
      //   msg: this.state.msg.substr(0, 300)
      // };
      // this.props.conn.send(JSON.stringify(obj));

      let token = getLS('token');

      if (token) {
        setAuthHeader(getLS('token'));

        let data = new FormData();
        data.append('msg', this.state.msg.substr(0, 300));

        axios
          .post(API_URI + '/chat/add', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              msg: ''
            });
            this.props.reload();
          })
          .catch(err => {
            this.setState({
              msg: '',
              emojiPicker: false
            });
          });
      }
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.tag !== state.tag) {
      nextProps.clearTag();
      return {
        msg: nextProps.taggedMsg + ' ',
        tag: false
      };
    }
    return null;
  }

  render() {
    if (this.state.userId) {
      return (
        <div className="chatbox-footer">
          <div
            className="emoji-btn-wrap"
            ref={this.myRef}
            onClick={this.handleClickInside}
          >
            <div className="pb-left">
              <button
                className="emoji-btn"
                name="emojiPicker"
                onClick={this.emojiPicker}
              >
                <i className="far fa-smile" />
              </button>
              {this.state.emojiPicker ? (
                <Picker
                  set="emojione"
                  onSelect={this.addEmoji}
                  sheetSize="32"
                  native={false}
                  title="Pick your emoji…"
                  emoji="point_up"
                  style={{
                    position: 'absolute',
                    zIndex: '101',
                    top: '-283px',
                    right: '0px',
                    width: '300px'
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className="chatbox-input">
            {this.state.warn ? (
              <textarea
                type="text"
                name="msg"
                id="chat-message"
                rows="3"
                placeholder="Easy tiger... Please slow down a bit!"
                onChange={this.onChange}
                onKeyPress={this.handleKeyPress}
                value=""
              />
            ) : (
              <textarea
                type="text"
                id="chat-message"
                name="msg"
                rows="3"
                maxLength="300"
                placeholder="Let the lyrics flow..."
                onChange={this.onChange}
                onKeyPress={this.handleKeyPress}
                value={this.state.msg}
              />
            )}
          </div>
        </div>
      );
    }
    return null;
  }
}

ChatBoxInput.propTypes = {
  reload: PropTypes.func.isRequired,
  tag: PropTypes.bool.isRequired,
  taggedMsg: PropTypes.string,
  clearTag: PropTypes.func.isRequired
  // conn: PropTypes.object.isRequired
};

export default ChatBoxInput;
