import React, { Component } from 'react';
import PropTypes from 'prop-types';

class UploadMeta extends Component {
  state = {
    artist: '',
    title: ''
  };
  render() {
    if (this.props.type && this.props.options.shows) {
      let showList = false;

      if (this.props.options.shows.length > 1) {
        showList = this.props.options.shows.map(show => {
          return (
            <option key={show.id} value={show.title}>
              {show.title}
            </option>
          );
        });
      }

      return (
        <div>
          {this.props.type === 'show' ? (
            <div id="form-inputs" className="showUploadBox">
              {showList ? (
                <p style={{ textAlign: 'center' }}>
                  As you are associated with multiple shows you can select which
                  one you are uploading for below.
                </p>
              ) : null}

              {showList ? (
                <select name="show-title" onChange={this.props.onSelectShow}>
                  {showList}
                </select>
              ) : (
                <div className="showTitle">
                  {this.props.options.shows[0].title}
                </div>
              )}

              <div className="showDJ">
                {this.props.options && this.props.options.name
                  ? this.props.options.name
                  : ''}
              </div>
            </div>
          ) : (
            <div id="form-inputs">
              <p>
                Optionally add your own title and artist. If none is supplied
                we'll try to get the info from the ID3 tags. <br /> If no ID3
                tags can be extracted, a generic title and name will be used
                instead.
              </p>
              <input
                type="text"
                name="title"
                placeholder="Title (optional)"
                onChange={this.props.onChange}
              />
              <input
                type="text"
                name="artist"
                placeholder="Artist (optional)"
                onChange={this.props.onChange}
              />
            </div>
          )}
        </div>
      );
    }
    return null;
  }
}

UploadMeta.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  onSelectShow: PropTypes.func.isRequired
};

export default UploadMeta;
