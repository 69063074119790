import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChatBoxOpen from './ChatBoxOpen';
import ChatBoxClosed from './ChatBoxClosed';
import axios from 'axios';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import { API_URI } from '../../globals.js';

class ChatBoxIndex extends Component {
  conn = false;
  _isMounted = false;
  state = {
    open: false,
    data: {},
    isTyping: '',
    newMsg: '',
    messages: []
  };

  componentDidMount() {
    this._isMounted = true;
    //this.openWebsocket();
    // this.loadMessages();
    setTimeout(this.chatStatus, 200);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openWebsocket = () => {
    if (this._isMounted) {
      this.conn = new WebSocket('wss://amps.d3ep.com/wss2');
      this.conn.onopen = () => {};

      this.conn.onmessage = e => {
        if (e.data) {
          console.log(e.data);
          let data = JSON.parse(e.data);
          if (data.action === 'typing') {
            this.setState({
              isTyping: data.msg
            });
            setTimeout(this.clearIsTyping, 3000);
          }
        }
      };
    }
  };

  clearIsTyping = () => {
    this.setState({
      isTyping: ''
    });
  };

  openClose = () => {
    this.setState({
      open: !this.state.open
    });
    setTimeout(this.chatStatus, 200);
  };

  chatStatus = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        let data = new FormData();
        data.append('status', this.state.open);

        axios
          .post(API_URI + '/chat/status', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  loadMessages = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        axios
          .post(API_URI + '/chat')
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              messages: res.data
            });
          })
          .catch(err => {
            this.setState({ error: err.response.data });
          });
      }
    }
  };

  render() {
    if (this.props.auth.isAuthenticated && this.props.sys.chat) {
      if (this.state.open) {
        return (
          <ChatBoxOpen
            control={this.openClose}
            messages={this.props.sys.chat}
            // messages={this.props.sys.chat.messages}
            conn={this.conn}
            isTyping={this.state.isTyping}
            newMsg={this.state.newMsg}
          />
        );
      }
      return <ChatBoxClosed control={this.openClose} />;
    }
    return null;
  }
}

ChatBoxIndex.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
  sys: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error,
  sys: state.sys
});

export default connect(mapStateToProps)(ChatBoxIndex);
