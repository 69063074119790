import React, { Component } from 'react';
import PropTypes from 'prop-types';

class StatusMedia extends Component {
  render() {
    if (this.props.media) {
      const stats = this.props.media;
      return (
        <div className="ice-settings">
          <div className="ice-heading">MEDIA</div>
          <div className="ice-flex">
            <div className="cell">
              <div className="title">MEDIA</div>
              <div className="readOnly">{stats.items_total}</div>
            </div>

            <div className="cell">
              <div className="title">ACTIVE</div>
              <div className="readOnly">{stats.items_active}</div>
            </div>

            <div className="cell">
              <div className="title">TRACKS</div>
              <div className="readOnly">{stats.track_count}</div>
            </div>

            <div className="cell">
              <div className="title">JINGLES</div>
              <div className="readOnly">{stats.jingle_count}</div>
            </div>

            <div className="cell">
              <div className="title">SHOWS</div>
              <div className="readOnly">{stats.show_count}</div>
            </div>

            <div className="cell">
              <div className="title">PROCESSING QUEUE</div>
              <div className="readOnly">{stats.process_queue}</div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

StatusMedia.propTypes = {
  media: PropTypes.object.isRequired
};

export default StatusMedia;
