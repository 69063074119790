import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { API_URI } from '../../globals.js';
import { getLS, setLS } from '../../functions/handleLocalStorage';
import setAuthHeader from '../../functions/setAuthHeader';
import Playlist from './Playlist';
import alertify from 'alertifyjs';

class AutoCreatePlaylist extends Component {
  _isMounted = false;
  state = {
    data: {},
    title: '',
    saved: false,
    items: '0',
    duration: '',
    injection: '',
    sources: 1,
    unique: 0
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createPlaylist() {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        let data = new FormData();
        data.append('items', this.state.items);
        data.append('duration', this.state.duration);
        data.append('injection', this.state.injection);
        data.append('sources', this.state.sources);
        data.append('unique', this.state.unique);

        axios
          .post(API_URI + '/playlist/create', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }

            this.setState({
              data: res.data
            });
          })
          .catch(err => {
            alertify
              .alert('Error: ' + err.response.data)
              .setHeader(
                '<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> '
              );
          });
      }
    }
  }

  savePlaylist = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token && this.state.data.pid) {
        setAuthHeader(token);

        let data = new FormData();
        data.append('pid', this.state.data.pid);
        data.append('title', this.state.title);

        axios
          .post(API_URI + '/playlist/save', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            this.setState({
              saved: true
            });
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i> Error: ' +
                err.response.data
            );
          });
      }
    }
  };

  clear = () => {
    let self = this;
    alertify
      .confirm(
        '<b>Are you sure you want to wipe this playlist?</b>',
        function() {
          self.clearPlaylist();
        }
      )
      .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
  };

  clearPlaylist = () => {
    this.setState({
      data: {},
      title: '',
      saved: false,
      items: '0',
      duration: '',
      injection: '',
      sources: 1,
      unique: 0
    });
    alertify.success('<i class="fas fa-info-circle"></i> Playlist dusted...');
  };

  restart = () => {
    let self = this;
    alertify
      .confirm(
        '<b>Are you sure you want to restart Ices now?</b><br><br>This will disconnect all clients. Click OK to continue!',
        function() {
          self.restartIces();
        }
      )
      .setHeader('<i class="fas fa-exclamation-triangle"></i> <b>AMPS</b> ');
  };

  restartIces = () => {
    if (this._isMounted) {
      let token = getLS('token');

      if (token) {
        setAuthHeader(token);

        let data = new FormData();
        data.append('pid', this.state.data.pid);

        axios
          .post(API_URI + '/restart', data)
          .then(res => {
            if (res.headers['set-authorization']) {
              setLS('token', res.headers['set-authorization']);
            }
            alertify.success(
              '<i class="fas fa-info-circle"></i> Ices restarting...'
            );
          })
          .catch(err => {
            alertify.error(
              '<i class="fas fa-exclamation-triangle"></i>' + err.response.data
            );
          });
      }
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    if (this.props.auth.isAuthenticated && this.state.data.pid) {
      return (
        <div className="content">
          {this.state.saved ? (
            <div className="generate">
              <h3>Playlist saved!</h3>
              <p>It is now available in your playlists.</p>
              {this.props.auth.user.can_admin ? (
                <button className="restart-btn" onClick={this.restart}>
                  RESTART ICES WITH THIS PLAYLIST
                </button>
              ) : null}
            </div>
          ) : (
            <div className="generate">
              <div className="box-icon">
                <i className="fas fa-compact-disc" />
              </div>
              <h2>Auto playlist successfully generated!</h2>
              <p>To save this playlist, name it and hit save below.</p>
              <input
                name="title"
                className="title"
                value={this.state.title}
                onChange={e => this.onChange(e)}
              />
              <button className="save-btn" onClick={this.savePlaylist}>
                SAVE PLAYLIST
              </button>
              <div className="bottom">
                <button className="clear-btn" onClick={this.clear}>
                  <i className="far fa-trash-alt" /> CLEAR PLAYLIST
                </button>
              </div>
            </div>
          )}

          <Playlist data={this.state.data} />
        </div>
      );
    }
    return (
      <div className="content">
        <div className="generate">
          <h2>Auto Create Playlist</h2>
          <div className="info">
            <p>
              When using intelligent automated playlist creation, media files
              are selected and sequenced at random.
            </p>
            <p>
              Hit the <span className="highlight">"Create Playlist"</span>{' '}
              button to automate everything, or set some options first to tailor
              the end result*.
            </p>
            <p>
              For example, maybe you need a playlist that only contains 10
              tracks or one that runs for approximately 5 hours.
            </p>
          </div>

          <h3>Options</h3>

          <p>
            <span className="dim">
              *All options are dependent on available media.
            </span>
          </p>

          <div className="options">
            <div>
              <span>Limit number of tracks? </span>
              <select
                name="items"
                value={this.state.items}
                onChange={e => this.onChange(e)}
              >
                <option value="0">No limit</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
            </div>

            <div>
              <span>Approximate duration? </span>
              <select
                name="duration"
                value={this.state.duration}
                onChange={e => this.onChange(e)}
              >
                <option value="0">No limit</option>
                <option value="5">5 mins</option>
                <option value="10">10 mins</option>
                <option value="15">15 mins</option>
                <option value="30">30 mins</option>
                <option value="45">45 mins</option>
                <option value="60">1 hr</option>
                <option value="120">2 hrs</option>
                <option value="180">3 hrs</option>
                <option value="240">4 hrs</option>
                <option value="300">5 hrs</option>
                <option value="360">6 hrs</option>
                <option value="420">7 hrs</option>
                <option value="480">8 hrs</option>
                <option value="540">9 hrs</option>
                <option value="600">10 hrs</option>
                <option value="660">11 hrs</option>
                <option value="720">12 hrs</option>
                <option value="1440">24 hrs</option>
              </select>
            </div>

            <div>
              <span>Inject Jingles? </span>
              <select
                name="injection"
                value={this.state.injection}
                onChange={e => this.onChange(e)}
              >
                <option value="0">No</option>
                <option value="1">Before every track</option>
                <option value="2">Before every second track</option>
                <option value="3">Before every third track</option>
                <option value="4">Before every fourth track</option>
                <option value="5">Before every fifth track</option>
              </select>
            </div>

            <div>
              <span>Use all available tracks? </span>
              <select
                name="sources"
                value={this.state.sources}
                onChange={e => this.onChange(e)}
              >
                <option value="1">Yes</option>
                <option value="0">No just use mine</option>
              </select>
            </div>

            <div>
              <span>Unique artists? </span>
              <select
                name="unique"
                value={this.state.unique}
                onChange={e => this.onChange(e)}
              >
                <option value="0">
                  Allow multiple tracks from the same artist
                </option>
                <option value="1">Every artist must be different</option>
              </select>
            </div>
          </div>

          <button className="save-btn" onClick={this.createPlaylist.bind(this)}>
            CREATE PLAYLIST
          </button>
        </div>
      </div>
    );
  }
}

AutoCreatePlaylist.propTypes = {
  auth: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  error: state.error
});

export default connect(mapStateToProps)(AutoCreatePlaylist);
