import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class SinglePlaylistItemList extends Component {
  render() {
    if (this.props.items) {
      let itemsList = this.props.items.map((item, index) => (
        <li key={index} className="pl-history-item">
          <div className="position">
            {index + 1 < 10 ? '0' + (index + 1) : index + 1}
          </div>
          <div className="data">
            <div className="meta">
              <div className="small-icon">
                {item.type === 'track' ? (
                  <i className="fas fa-music" />
                ) : (
                  <i className="fas fa-microphone-alt" />
                )}
              </div>
              <div className="title">
                <Link to={`/media/${item.id}`}>{item.title}</Link>
              </div>
              <div className="artist">{item.artist}</div>
            </div>
            <div className="info">
              <div className="duration">
                <i className="far fa-clock" /> {item.duration}
              </div>

              <div className="age">
                <i className="fas fa-upload" /> {item.time_ago}
              </div>
            </div>
          </div>
        </li>
      ));
      return (
        <div className="pl-list">
          <ul>{itemsList}</ul>
        </div>
      );
    }
    return null;
  }
}

SinglePlaylistItemList.propTypes = {
  items: PropTypes.array.isRequired
};

export default SinglePlaylistItemList;
